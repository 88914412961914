import "./Bbps.css";
import Navbar from "./Bbpsnav";
import { useNavigate, useParams } from "react-router-dom";
import header_Logo from "../../assets/bbps/bharat_pay.png";
import React, { useState, useEffect } from "react";
import Spinner from "../spinner";
import { useForm } from "react-hook-form";
import LoginServices from "../../services/LoginServices";

export default function Bbps() {
    const [spinner, setspinner] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const [billervalues, setbillervalues] = useState<any>();
    const userId = localStorage.getItem("userid");
    const Navigate = useNavigate();

    useEffect(() => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        let body = {
            billerId: sessionStorage.getItem("Biller"),
        };
        LoginServices.FetchBillerInfoPost(body, config)
            .then((res) => {
                if (res?.data?.Status == 1)
                    setbillervalues(res?.data?.BillerInfo);
                localStorage.setItem(
                    "billerAdhoc",
                    res?.data?.BillerInfo?.billerAdhoc
                );
                localStorage.setItem(
                    "billerName",
                    res?.data?.BillerInfo?.billerName
                );
            })
            .catch((err) => {
                alert("something went wrong please try again later");
            });
    }, []);
    const fetchbillHandler = (data: any) => {
        sessionStorage.setItem("usernumber", data?.MobileNumber);
        const input = Object.entries(data).map((item: any) => {
            return { paramName: item[0], paramValue: item[1] };
        });
        // input.splice(0, -2);
        const bodydata = input.filter(
            (item: any) => item.paramName !== "Amount"
        );
        const bodydatafilter = bodydata.filter(
            (item: any) => item.paramName !== "MobileNumber"
        );
        setspinner(true);
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        let id = {};
        const body = {
            billerAdhoc: "true",
            Amount: Number(data?.Amount) * 100,
            billerId: sessionStorage.getItem("Biller"),
            mobileNumber: data?.MobileNumber,
            Params: {
                inputParams: {
                    input: [...bodydatafilter],
                },
                paymentMethod: {
                    paymentMode: "Wallet",
                    quickPay: "Y",
                    splitPay: "N",
                },
                amountInfo: {
                    amount: Number(data?.Amount) * 100,
                    currency: "356",
                    custConvFee: "0",
                },
                paymentInfo: {
                    info: [
                        {
                            infoName: "WalletName",
                            infoValue: "Credit Mint Pvt Ltd",
                        },
                        {
                            infoName: "MobileNo",
                            infoValue: localStorage.getItem("mobilenumber"),
                        },
                    ],
                },
            },
        };
        LoginServices.quickpaybill(body, config)
            .then((response: any) => {
                setspinner(false);
                if (response.data?.Status == 1) {
                    sessionStorage.setItem(
                        "success",
                        JSON.stringify(response?.data?.QuickBillPayResponse)
                    );
                    Navigate(`/dashboard/bbps/quick-pay/QuickPaySuccess`);
                } else if (response?.data.status == 0) {
                    alert(response?.data?.Message);
                }
            })
            .catch((err) => {
                setspinner(false);
                alert(err?.response?.data?.Message);
            });
        //mobilenumber
    };
    return (
        <>
            {spinner && <Spinner />}
            <div className="row main-row">
                <div className="col-10 dashboard-right-back">
                    <div className="header-bbps">
                        <div className="BbpsWrapper">
                            <div className="Bbps-heading">
                                <p>BBPS</p>
                                <img src={header_Logo} alt="" />
                            </div>
                            {/* <div className="Bbps-Navbar">
                            <Navbar active="billpay" />
                        </div> */}
                            <div className="biller-details-wrapper">
                                <div>
                                    <form
                                        className="biller-details"
                                        onSubmit={handleSubmit(
                                            fetchbillHandler
                                        )}>
                                        <h1
                                            className="biller-name"
                                            style={{ textAlign: "center" }}>
                                            {billervalues?.billerName}
                                        </h1>
                                        {billervalues?.billerInputParams?.paramInfo?.map(
                                            (data: any) => {
                                                const name = data?.paramName;
                                                const boolValue =
                                                    data?.isOptional == "true";
                                                // console.log(name);
                                                return (
                                                    <div className="biller-detail">
                                                        <input
                                                            className={
                                                                errors[
                                                                    `${name}`
                                                                ] &&
                                                                "input-error"
                                                            }
                                                            data-regex={
                                                                data?.regEx
                                                            }
                                                            minLength={
                                                                data?.minLength
                                                            }
                                                            maxLength={
                                                                data?.maxLength
                                                            }
                                                            data-name={
                                                                data?.paramName
                                                            }
                                                            id="billerinput"
                                                            placeholder={name}
                                                            type={"text"}
                                                            pattern={
                                                                data?.regEx
                                                            }
                                                            {...register(
                                                                `${name}`,
                                                                {
                                                                    required:
                                                                        !boolValue,
                                                                    pattern: {
                                                                        value: data?.regEx,
                                                                        message: `invalid input`,
                                                                    },
                                                                }
                                                            )}
                                                        />
                                                        {errors[`${name}`] && (
                                                            <p
                                                                style={{
                                                                    color: "red",
                                                                }}>
                                                                Enter a Valid{" "}
                                                                {
                                                                    data?.paramName
                                                                }
                                                            </p>
                                                        )}
                                                    </div>
                                                );
                                            }
                                        )}
                                        <div className="biller-detail">
                                            <img
                                                src="/assets/img/ruppee.svg"
                                                className="call-icon"
                                                alt=""
                                            />
                                            <input
                                                className={
                                                    errors?.Amount &&
                                                    "input-error"
                                                }
                                                id="billerinput"
                                                placeholder="Enter Amount"
                                                type={"number"}
                                                {...register("Amount", {
                                                    required:
                                                        "Please Enter Amnout",
                                                })}
                                            />
                                            {errors?.Amount && (
                                                <p style={{ color: "red" }}>
                                                    {" "}
                                                    {errors?.Amount.message}
                                                </p>
                                            )}
                                        </div>
                                        <div className="biller-detail">
                                            <img
                                                src="/assets/img/call.svg"
                                                className="call-icon"
                                                alt=""
                                            />
                                            <input
                                                className={
                                                    errors?.MobileNumber &&
                                                    "input-error"
                                                }
                                                id="billerinput"
                                                placeholder="Enter Mobile Number"
                                                type={"number"}
                                                {...register("MobileNumber", {
                                                    required:
                                                        "Please Enter Mobile Number",
                                                })}
                                            />
                                            {errors?.MobileNumber && (
                                                <p style={{ color: "red" }}>
                                                    {" "}
                                                    {
                                                        errors?.MobileNumber
                                                            .message
                                                    }
                                                </p>
                                            )}
                                        </div>
                                        <button type="submit">Pay</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
