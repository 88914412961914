import "./Bbps.css";
import Navbar from "./Bbpsnav";
import { useNavigate, useParams } from "react-router-dom";
import header_Logo from "../../assets/bbps/bharat_pay.png";
import React, { useState, useEffect } from "react";
import LoginServices from "../../services/LoginServices";
import Submenu from "./Bbpssubmenu";
import Spinner from "../spinner";
import { useForm } from "react-hook-form";
export default function Bbps() {
    const [spinner, setspinner] = useState<any>(false);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const onSubmit = (data: any) => {
        setspinner(true);
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        // console.log(data);
        LoginServices.TrackComplaint(data, config)
            .then((res) => {
                // console.log(res, "as");
                setspinner(false);
                if (res?.data?.Status == 1) {
                    sessionStorage.setItem(
                        "complaintStatus",
                        JSON.stringify(res?.data?.TrackComplaintResponse)
                    );
                    navigate(
                        "/dashboard/bill-payments/billPaymentComplaintStatusFetch"
                    );
                } else if (res?.data?.Status == 0) {
                    setspinner(false);
                    alert(res?.data?.Message);
                }
                // console.log(res);
                ///
            })
            .catch((err) => {
                // console.log(err.response);
                setspinner(false);
                alert(err?.response?.data?.Message);
            });
    };
    return (
        <>
            {spinner && <Spinner />}

            <div className="row main-row">
                <div className="col-10 dashboard-right-back">
                    <div className="header-bbps">
                        <div className="BbpsWrapper">
                            <div className="Bbps-heading">
                                <p>Complaints</p>
                                <img src={header_Logo} alt="" />
                            </div>
                            <div className="Bbps-Navbar">
                                <Navbar active="complaints" />
                            </div>
                            <div className="Raise-request">
                                <div className="Complaint-formwrapper">
                                    <Submenu active="Check" />
                                    <h1>Check Complaint status</h1>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="Complaint-form-control">
                                            <label htmlFor="Complaint ID">
                                                Complaint ID
                                            </label>
                                            <div>
                                                <input
                                                    className={`input ${
                                                        errors?.complaintId &&
                                                        "inputerror"
                                                    }`}
                                                    type="text"
                                                    {...register(
                                                        "complaintId",
                                                        {
                                                            required:
                                                                "complaintId is required",
                                                        }
                                                    )}
                                                />
                                                {errors?.complaintId && (
                                                    <p className="error">
                                                        {
                                                            errors?.complaintId
                                                                .message
                                                        }
                                                    </p>
                                                )}
                                            </div>
                                        </div>

                                        <div className="Complaint-form-control">
                                            <label htmlFor="Select Reason">
                                                Type of Complaint
                                            </label>
                                            <div>
                                                <select
                                                    defaultValue=""
                                                    className={`select ${
                                                        errors?.complaintType &&
                                                        "inputerror"
                                                    }`}
                                                    {...register(
                                                        "complaintType",
                                                        {
                                                            required:
                                                                "complaintType is required",
                                                        }
                                                    )}>
                                                    <option value="" disabled>
                                                        TYPE
                                                    </option>
                                                    <option value="SERVICE">
                                                        SERVICE
                                                    </option>
                                                    <option value="TRANSACTION">
                                                        TRANSACTION
                                                    </option>
                                                </select>
                                                {errors?.complaintType && (
                                                    <p className="error">
                                                        {
                                                            errors
                                                                ?.complaintType
                                                                .message
                                                        }
                                                    </p>
                                                )}
                                            </div>
                                        </div>

                                        <div
                                            className="button"
                                            style={{ paddingTop: "10rem" }}>
                                            <button type="submit">
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
