import React, { useState, useEffect } from "react";
import { Nav, NavDropdown, Navbar, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import LoginServices from "../../services/LoginServices";
function TopNav() {
    const [userDetails, SetUserDetails] = useState<any>();
    const [paymentUrl, SetPaymentUrl] = useState<any>();
    const [enablePaymentLinks, SetEnablePaymentLinks] = useState<any>(false);
    const navigate = useNavigate();

    useEffect(() => {
        const userId = localStorage.getItem("userid");
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };

            LoginServices.getUserDetailsByID(parseInt(userId), config)
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        SetUserDetails(response?.data?.UserDetails);
                        SetEnablePaymentLinks(
                            response?.data?.UserDetails
                                ?.isPaymentLinksEnabled === 1
                                ? true
                                : false
                        );
                        localStorage.setItem(
                            "UserDetails",
                            JSON.stringify(response?.data?.UserDetails)
                        );
                        sessionStorage.setItem(
                            "paymentid",
                            response?.data?.UserDetails?.paymentPageId
                        );
                        SetPaymentUrl(
                            `https://www.payville.in/payment/${response?.data?.UserDetails?.paymentPageId}`
                        );
                    } else {
                    }

                    // console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, []);
    {
        /* {userDetails?.paymentPageId} */
    }

    return (
        <div style={{ position: "absolute", right: "230px", zIndex: 100 }}>
            <Navbar bg="light" style={{ paddingTop: "0px" }}>
                <NavDropdown
                    title="My Account"
                    id="basic-nav-dropdown"
                    style={{ maxWidth: "15rem" }}>
                    <div style={{ maxWidth: "15rem" }}>
                        <Link
                            to={{
                                pathname: "/dashboard/profile",
                                search: "1",
                            }}>
                            Profile
                        </Link>

                        <Link
                            to={{
                                pathname: "/dashboard/profile",
                                search: "2",
                            }}
                            style={{ paddingRight: "20px" }}>
                            Change Password
                        </Link>

                        <Link
                            to={{
                                pathname: "/dashboard/profile",
                                search: "3",
                            }}>
                            Change Pin
                        </Link>
                        {/* <a href={paymentUrl} target={"_blank"}>
                            Payment links
                        </a> */}
                        {enablePaymentLinks && (
                            <Link
                                to={{
                                    pathname: "/dashboard/profile",
                                    search: "5",
                                }}>
                                Payment Links
                            </Link>
                        )}
                        {userDetails?.userType != "USER" && (
                            <Link
                                to={{
                                    pathname: "/dashboard/profile",
                                    search: "4",
                                }}>
                                Busines Info
                            </Link>
                        )}
                        <NavDropdown.Divider />
                        <NavDropdown.Item
                            onClick={() => {
                                if (
                                    window.confirm(
                                        "Are you sure want to logout?"
                                    )
                                ) {
                                    localStorage.clear();
                                    navigate("/");
                                }
                            }}>
                            <span>Logout</span>
                        </NavDropdown.Item>
                    </div>
                </NavDropdown>
            </Navbar>
        </div>
    );
}
export default TopNav;
