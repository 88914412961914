import React, { useState, useEffect } from "react";
import LoginServices from "../../services/LoginServices";
import Fuse from "fuse.js";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import LastTenTransactions from "./LastTenTransactions";
import { Modal, Row, Col } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { BounceLoader } from "react-spinners";
import { css } from "@emotion/react";
function UpiContacts() {
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: "#6865F0";
    `;

    const [showSection, setShowSection] = useState<any>("");
    const [payoutList, setPayoutList] = useState<any>();
    const [resPayoutList, setResPayoutList] = useState<any>();
    const [pinValue, setpinValue] = useState("");
    const [payAmount, setPayAmount] = useState<any>("");
    const userId = localStorage.getItem("userid");
    const [showLastTransaction, setshowLastTransaction] = useState(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentPayee, setCurrentPayee] = useState<any>();
    const [refreshP, setRefreshP] = useState<any>("");
    const [payNotes, setPayNotes] = useState<any>();
    const [payoutPurpose, setPayoutPurpose] = useState<any>("");
    const [walletBlc, setWalletBlc] = useState<any>(0);
    const [userIpAddress, setUserIpAddress] = useState("");
    let [loading, setLoading] = useState(false);
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm();
    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            fetch("https://api.ipify.org/?format=json")
                .then((Response) => {
                    return Response.json();
                })
                .then((response) => {
                    setUserIpAddress(response.ip);
                })
                .catch((err) => {
                    console.log(err);
                });
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                    setShowSection("add-img");
                });
            LoginServices.getPayoutContacts(parseInt(userId), config, "UPI")
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        setPayoutList(response?.data?.Contacts);
                        setResPayoutList(response?.data?.Contacts);

                        if (response?.data?.Contacts?.length == 0) {
                            setShowSection("add-img");
                        } else {
                            setShowSection("biller-list");
                        }
                    } else {
                        alert(response?.data?.Message);
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    }, [refreshP == "yes"]);
    const localSearch = (searchItem: string) => {
        if (searchItem?.length == 0) {
            setRefreshP("yes");
            return;
        }
        const fuse = new Fuse(payoutList, {
            includeScore: true,
            keys: [
                "mobileNumber",
                "bankAccountName",
                "type",
                "upiId",
                // 'mobileNumber',
                // 'bankIFSCCode',
                // 'emailId',
                // 'name'
            ],
            threshold: 0.2,
        });
        const results = fuse.search(searchItem);
        // const characterResults = results.map(character => character.item);
        // console.log(characterResults);
        if (results.length == 0) {
            setResPayoutList([]);
        } else {
            setResPayoutList(results.map((character) => character.item));
        }

        // console.log(resPayoutList);
    };
    const sortArray = (e: any) => {
        localSearch(e.target.value);
    };
    const deletePayee = (payoutid: any) => {
        if (window.confirm("Are you sure want to delete?") && userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.deletePayoutContact(
                parseInt(userId),
                payoutid,
                config
            )
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        toast.success(response?.data?.Message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setRefreshP("yes");
                    } else {
                        alert(response?.data?.Message);
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    };
    const onNameNumberSubmit = (data: any) => {
        const payload = {
            userId: userId,
            ...data,
        };

        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        // setLoading(true);
        LoginServices.createPayoutUpi(payload, config)
            .then((response: any) => {
                if (response.data.Status == 1) {
                    alert(response.data.Message);
                    setShowSection("biller-list");
                    setRefreshP("yes");
                    reset();
                } else {
                    alert(response?.data?.Message);
                }
            })
            .catch((e: any) => {
                // setLoading(false);
                console.log(e);
                alert(e?.response.data?.Message);
            });
    };
    const onTransactionModelClose = () => {
        setshowLastTransaction(false);
    };
    const hideModel = () => {
        setShowModal(false);
        setpinValue("");
        setPayAmount("");
    };
    const onPinChange = (e: any) => {
        setpinValue(e);
    };
    const payouthandler = (e: any) => {
        setPayoutPurpose(e.target.value);
    };
    const sendPayment = (item: any) => {
        let confirmPayment = false;

        if (payAmount.length == 0) {
            alert("Amount is mandatory");
            return;
        }
        if (isNaN(payAmount)) {
            alert("Amount invalid");
            return;
        }
        if (payAmount > walletBlc) {
            alert("Pay amount is more than your wallet balance");
            return;
        }
        if (payAmount > 200000) {
            alert("Pay amount is more than 2,00,000Rs");
            return;
        }
        if (pinValue.length == 0) {
            alert("PIN is mandatory");
            return;
        }
        // if (isNaN(pinValue)) {
        //     alert("PIN invalid");
        //     return;
        // }
        if (payoutPurpose.length == 0) {
            alert("Bill payment Purpose is mandatory");
            return;
        }
        if (payAmount) {
            if (window.confirm("Are You Sure You Want To Proceed") == true) {
                confirmPayment = true;
            }
        }

        if (confirmPayment) {
            const payload = {
                payout_contactId: item?.payout_contactId,
                amount: parseInt(payAmount),
                notes: payNotes,
                PIN: pinValue,
                ipAddress: userIpAddress,
                payoutPurpose: payoutPurpose,
            };
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };

            setLoading(true);
            LoginServices.payToContact(payload, config)
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        setLoading(false);
                        // showSection('biller-list')
                        setRefreshP("yes");
                        setShowModal(false);
                        // alert(response?.data?.Message);
                        toast.success(response?.data?.Message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setpinValue("");
                        // notify();
                        // window.location.reload();
                    }
                    // else {
                    //     alert(response?.data?.Message);
                    //     setLoading(false);
                    // }
                    else if (response.data.Status == 0) {
                        setLoading(false);
                        setLoading(false);
                        toast.error(`${response.data.Message}`, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((e: any) => {
                    console.log(e);
                    if (e.message) {
                        alert(e.response.data.Message);
                    }
                    setLoading(false);
                });
        }
    };
    const getLastTransactionList = () => {
        hideModel();

        setshowLastTransaction(true);
    };
    return (
        <>
            {showLastTransaction && (
                <LastTenTransactions
                    show={showLastTransaction}
                    onHide={onTransactionModelClose}
                    userId={userId}
                    payout_contactId={currentPayee.payout_contactId}
                />
            )}
            {/*  empty upi contacts set showSection to add-img*/}
            {showSection === "add-img" && (
                <div>
                    <div className="pay-bills-grp1">
                        <p className="pay-bills-head">Pay Bills</p>
                        <div className="d-flex">
                            <button
                                onClick={() => setShowSection("biller-list")}
                                className="see-billers-btn">
                                See Billers
                            </button>
                        </div>
                    </div>
                    <div className="pay-bills-center">
                        <div>
                            <div>
                                <img
                                    className="pay-bills-main-img"
                                    src="assets/img/pay-bills-main.png"
                                    alt=""
                                />
                                <p className="add-your-p">
                                    Add Your Biller Together
                                </p>
                                <p className="add-more-p">
                                    Add More biller and make Bill Payment Easy
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className="add-btn-div">
                                <button
                                    onClick={() => setShowSection("add-form")}
                                    className="add-biller-btn">
                                    Add Biller
                                </button>
                                {/* onClick={loginFormSubmit} */}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showSection == "biller-list" && (
                <div>
                    <div className="pay-bills-grp1">
                        <p className="pay-bills-head">Biller List</p>
                        <div className="d-flex">
                            <button
                                onClick={() => setShowSection("add-form")}
                                className="see-billers-btn">
                                Add Biller
                            </button>
                        </div>
                    </div>

                    <div className="local-search-biller-list">
                        <input
                            onKeyPress={(event: any) => {
                                if (event.key === "Enter") {
                                    localSearch(event.target.value);
                                }
                            }}
                            onChange={sortArray}
                            className="search-payers"
                            placeholder="search with name or contact number or upiId"
                        />
                    </div>

                    <div className="grid1-main">
                        <div className="row">
                            {resPayoutList?.length != 0 &&
                                resPayoutList?.map((item: any) => {
                                    return (
                                        <div
                                            key={item?.bankAccountNumber}
                                            className="col-sm-2 col-md-2 payee-col">
                                            <div
                                                className="payee-col-sub"
                                                style={{
                                                    cursor: "pointer",
                                                }}>
                                                <button
                                                    onClick={() => {
                                                        deletePayee(
                                                            item?.payout_contactId
                                                        );
                                                    }}
                                                    className="btn btn-danger delete-payout-btn">
                                                    Delete
                                                </button>

                                                <h1
                                                    onClick={() => {
                                                        setCurrentPayee(item);
                                                        setShowModal(true);
                                                    }}
                                                    className="first-letter">
                                                    {item?.bankAccountName.charAt(
                                                        0
                                                    )}
                                                </h1>
                                                <div>
                                                    <p
                                                        onClick={() => {
                                                            setCurrentPayee(
                                                                item
                                                            );
                                                            setShowModal(true);
                                                        }}
                                                        className="ac-name">
                                                        {item?.name}
                                                    </p>
                                                    <p
                                                        onClick={() => {
                                                            setCurrentPayee(
                                                                item
                                                            );
                                                            setShowModal(true);
                                                        }}>
                                                        <span className="ac-number">
                                                            UPI
                                                        </span>
                                                        <br />
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    "0.8rem",
                                                            }}>
                                                            {item?.upiId}
                                                        </span>
                                                    </p>
                                                    <p
                                                        onClick={() => {
                                                            setCurrentPayee(
                                                                item
                                                            );
                                                            setShowModal(true);
                                                        }}
                                                        className="ac-number">
                                                        {" "}
                                                        {item?.mobileNumber}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            {resPayoutList?.length == 0 && (
                                <h3>No Results Found</h3>
                            )}
                            {/* {payoutList?.length == 0 &&
                                    <h2>No Contacts Found</h2>
                                    } */}
                            {payoutList?.length == 0 && (
                                <h2>No Billers, Please add</h2>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {showSection == "add-form" && (
                <div>
                    <div className="pay-bills-grp1">
                        <p className="pay-bills-head">Pay Biller</p>
                        {/* <button onClick={onBackClick} className="see-billers-btn" style={{width:'100px'}}>back</button> */}
                        <div className="d-flex">
                            <button
                                onClick={() => setShowSection("biller-list")}
                                className="see-billers-btn">
                                See Billers
                            </button>
                        </div>
                    </div>

                    <div className="form-main">
                        <form
                            className="form-sub"
                            onSubmit={handleSubmit(onNameNumberSubmit)}>
                            <input
                                className="pay-bill-ip"
                                type="text"
                                placeholder="Enter Name"
                                {...register("name", { required: true })}
                            />
                            <label className="error-text">
                                {errors?.name?.type == "required" &&
                                    "name is required "}{" "}
                            </label>
                            <input
                                maxLength={10}
                                {...register("mobileNumber", {
                                    required: true,
                                    pattern: /^([0-9]{10})+$/i,
                                })}
                                name="mobileNumber"
                                id="mobileNumber3"
                                className="pay-bill-ip"
                                type="text"
                                placeholder="Enter Contact Number"
                            />
                            <label className="error-text">
                                {errors?.mobileNumber?.type == "required" &&
                                    "Contact number is required "}{" "}
                                {errors?.mobileNumber?.type == "pattern" &&
                                    "Mobile Number Invalid"}{" "}
                            </label>
                            <input
                                className="pay-bill-ip"
                                type="text"
                                placeholder="Enter UPI ID"
                                {...register("UpiId", { required: true })}
                            />
                            <label className="error-text">
                                {errors?.UpiId?.type == "required" &&
                                    "UpiId is required "}{" "}
                            </label>
                            <div
                                className="d-flex"
                                style={{
                                    marginTop: "20px",
                                }}>
                                <button
                                    type="submit"
                                    className="add-biller-btn">
                                    Add
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            <Modal
                show={showModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="c-modal-pay-bills"
                onHide={hideModel}>
                {/* <Modal.Header closeButton>
        
      </Modal.Header> */}
                <Modal.Body>
                    <div className="m-name">
                        <p className="m-name-payee">
                            {currentPayee?.bankAccountName}
                        </p>
                        <p className="m-mobile-payee">
                            {currentPayee?.mobileNumber}
                        </p>
                        <p className="m-mobile-payee">
                            UPI {currentPayee?.upiId}
                        </p>

                        <div className="pay-ip-div">
                            <input
                                onChange={(e: any) =>
                                    setPayAmount(e.target.value)
                                }
                                name="amount"
                                className="pay-ip-div-e1"
                                type="text"
                                autoComplete="off"
                                placeholder="Enter Amount"
                            />
                            {/* <p>
                                        {convertRupeesIntoWords(
                                            parseInt(payAmount)
                                        )}
                                    </p> */}
                            {
                                <div className="pay-otp">
                                    <OtpInput
                                        inputStyle={{
                                            width: "30px",
                                            border: "2px solid grey",
                                            borderRadius: "4px",
                                        }}
                                        value={pinValue}
                                        onChange={onPinChange}
                                        numInputs={4}
                                        isInputSecure={true}
                                        separator={
                                            <span
                                                style={{
                                                    margin: "10px",
                                                }}></span>
                                        }
                                    />
                                </div>
                            }

                            <textarea
                                maxLength={450}
                                onChange={(e: any) =>
                                    setPayNotes(e.target.value)
                                }
                                name="notes"
                                className="pay-ip-div-e2"
                                placeholder="Add a Note"
                            />
                            <select
                                onChange={payouthandler}
                                defaultValue=""
                                style={{
                                    width: "100%",
                                    height: "2rem",
                                    marginTop: "1rem",
                                }}>
                                <option value="">Bill payment Purpose</option>
                                <option value="Education Fee">
                                    Education Fee
                                </option>
                                <option value="Vendor Payment">
                                    Vendor Payment
                                </option>
                                <option value={"Housing Society"}>
                                    Housing Society
                                </option>

                                <option value={"Rental Payment"}>
                                    Rental Payment
                                </option>
                                <option value={"Credit Card Bill Payment"}>
                                    Credit Card Bill Payment
                                </option>
                            </select>
                            {/* <div onClick={()=>sendPayment(currentPayee)}> */}

                            {loading == false && (
                                <img
                                    onClick={() => sendPayment(currentPayee)}
                                    className="arrow-btn-payee"
                                    src="assets/img/arrow-right.png"
                                    alt=""
                                />
                            )}

                            <BounceLoader
                                color={"#6865F0"}
                                loading={loading}
                                css={override}
                                size={150}
                            />

                            {/* </div> */}

                            <button onClick={getLastTransactionList}>
                                Last Transactions
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default UpiContacts;
