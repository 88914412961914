import React, { useState, useEffect } from "react";
import "./dashboard.css";
import Services from "../../services/LoginServices";
import { toast } from "react-toastify";
function Modaldrop(props: any) {
    const [input, setInput] = useState();
    const [error, seterror] = useState<any>(null);
    const userId = localStorage.getItem("userid");
    useEffect(() => {
        setInput(props.walletCommissionPercentage);
    }, []);
    const CancelHandler = (e: any) => {
        e.stopPropagation();
        props.setmodal(false);
    };
    const inputhandler = (e: any) => {
        setInput(e.target.value);
    };
    const openhandler = (e: any) => {
        e.stopPropagation();
    };
    const updatehandler = (e: any) => {
        if (Number(input) < 1.15) {
            seterror("commission should be greater than 1.15");
            return;
        }
        seterror(null);

        props.setmodal(false);
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        let data = {
            userId: props.id,
            walletCommissionPercentage: input,
        };
        Services.updateReferralUserCommission(userId, data, config)
            .then((response) => {
                if (response.data.Status == 1) {
                    props.inputchangeHandler();

                    props.setmodal(false);
                    toast.success(`${response.data.Message}`, {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (response.data.Status == 0) {
                    toast.warning(`${response.data.Message}`, {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                props.setmodal(false);
                toast.error(`some thing went wrong try again`, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };
    return (
        <div className="referral-modal" onClick={CancelHandler}>
            <div className="referral-modal-content" onClick={openhandler}>
                <div className="referral-modal-content">
                    <label htmlFor="commision">Commission Percentage</label>
                    <input
                        type={"number"}
                        onChange={inputhandler}
                        value={input}
                    />
                    {error && <span style={{ color: "red" }}>{error}</span>}
                    <button onClick={updatehandler}>update</button>
                </div>
            </div>
        </div>
    );
}

export default Modaldrop;
