import "./Bbps.css";
import { useForm } from "react-hook-form";
import Navbar from "./Bbpsnav";
import { useNavigate, useParams } from "react-router-dom";
import header_Logo from "../../assets/bbps/bharat_pay.png";
import React, { useState, useEffect } from "react";
import Submenu from "./Bbpssubmenu";
import Spinner from "../spinner";

import LoginServices from "../../services/LoginServices";
export default function Bbps() {
    const [success, setsuccess] = useState<any>();
    const registersuccess = sessionStorage.getItem("registersuccess");
    useEffect(() => {
        if (registersuccess) {
            setsuccess(JSON.parse(registersuccess));
        }
    }, []);
    const navigate = useNavigate();
    const [spinner, setspinner] = useState<any>(false);
    const userId = localStorage.getItem("userid");

    return (
        <>
            {spinner && <Spinner />}
            <div className="row main-row">
                <div className="col-10 dashboard-right-back">
                    <div className="header-bbps">
                        <div className="BbpsWrapper">
                            <div className="Bbps-heading">
                                <p>Complaints</p>
                                <img src={header_Logo} alt="" />
                            </div>
                            <div className="Bbps-Navbar">
                                <Navbar active="complaints" />
                            </div>
                            {/* <div className="Complaint-formwrapper"> */}
                            <Submenu active="Raise Complaint" />
                            <div className="Complaint-formwrapper complaint-register">
                                <h1 style={{ textAlign: "center" }}>
                                    Your Complaint is Successfully Registered..!
                                </h1>
                                <div className="Complaint-status-items-wrapper">
                                    <div className="Complaint-register-item">
                                        <h3>Complaint ID</h3>
                                        <p>:</p>
                                        <h5>{success?.complaintId}</h5>
                                    </div>
                                    <div className="Complaint-register-item">
                                        <h3>Complaint Status</h3>
                                        <p>:</p>
                                        <h5>{success?.responseReason}</h5>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
