import React from "react";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";

let WalletBlcHeader = (props: any) => {
    const blc = props.wBlc != undefined ? Number(props.wBlc).toFixed(2) : 0;

    return (
        <>
            <div className="header-row">
                <div className="header-sub-row">
                    <img
                        className="header-icon"
                        src="assets/dashboard-icons/filled/deposit.png"
                        alt=""
                    />
                    <p className="header-blc">₹ {blc}</p>
                </div>
            </div>
        </>
    );
};

export default React.memo(WalletBlcHeader);
