import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import Razor from "./Razor";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Row, Col } from "react-bootstrap";
let abortController = new AbortController();
function Statements() {
    const navigate = useNavigate();
    const [walletBlc, setWalletBlc] = useState<any>(0);
    const [addBlc, setAddBlc] = useState<number>();
    const [walletTransactions, setWalletTransactions] = useState<any>();
    const userId = localStorage.getItem("userid");
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [currentRow, setCurrentRow] = useState<any>();
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
            LoginServices.getWalletTransactions(
                parseInt(userId),
                config,
                "statement"
            )
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        setWalletTransactions(
                            response?.data?.WalletTransactions
                        );
                        // setWalletBlc(response?.data?.walletBalance)
                    } else {
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
        return () => {
            abortController.abort();
        };
    }, [""]);
    const filterByDate = () => {
        if (new Date(startDate) > new Date(endDate)) {
            alert("Start date is not more than end date");
            return;
        }
        const payload = {
            fromDate: startDate,
            toDate: endDate,
        };
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        if (userId) {
            LoginServices.getWalletTransactionsByDate(
                parseInt(userId),
                payload,
                config,
                "statement"
            )
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        setWalletTransactions(
                            response?.data?.WalletTransactions
                        );
                    } else {
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    };
    const getTDetails = (id: any) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        setCurrentRow({});
        if (userId) {
            LoginServices.getTransactionsDetailsByID(
                parseInt(userId),
                id,
                config
            )
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        setCurrentRow(response?.data?.walletTransaction);
                    } else {
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    };
    const exportHandler = () => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        let data;
        if (startDate.length > 0 || endDate.length > 0) {
            data = { fromDate: startDate, toDate: endDate };
        }
        if (userId) {
            LoginServices.exportWalletTransactionByDate(
                parseInt(userId),
                data,
                config,
                "statement"
            )
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        const url = response.data.filename;
                        const a = document.createElement("a");
                        a.href = url;
                        a.download = "transactions";
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    } else {
                        toast(`${response.data.Message}`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    };
    return (
        <>
            <div className="row main-row">
                <div className="col-10 right-back">
                    <WalletBlcHeader wBlc={walletBlc}></WalletBlcHeader>
                    <p className="transaction-head">Statements</p>
                    <div className="d-flex date-export-divs">
                        <div className="dates-ips">
                            <input
                                onChange={(e) => setStartDate(e.target.value)}
                                className="date-ips"
                                type="Date"
                            />
                            <input
                                onChange={(e) => setEndDate(e.target.value)}
                                className="date-ips"
                                type="Date"
                            />
                            <button onClick={filterByDate} className="go-btn">
                                Go
                            </button>
                        </div>
                        <div className="export-div" onClick={exportHandler}>
                            Export
                        </div>
                    </div>
                    <div className="total-transaction-box-main table-responsive">
                        {walletTransactions?.length != 0 && (
                            <table className="table">
                                <thead>
                                    <tr className="trans-row">
                                        <th>Order ID</th>
                                        <th>Type</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                        <th>Opening Bal</th>
                                        <th>Debit</th>
                                        <th>Credit</th>
                                        <th>Closing Bal</th>
                                    </tr>
                                </thead>
                                <tbody style={{ borderTopWidth: 0 }}>
                                    {walletTransactions?.length != 0 &&
                                        walletTransactions?.map(
                                            (item: any, index: any) => {
                                                return (
                                                    <tr
                                                        onClick={() => {
                                                            setCurrentRow(item);
                                                            getTDetails(
                                                                item.wallet_transactionId
                                                            );
                                                            setShowModal(true);
                                                        }}
                                                        className="trans-row1"
                                                        key={index}>
                                                        <td>
                                                            {" "}
                                                            {
                                                                item?.wallet_transactionId
                                                            }{" "}
                                                        </td>
                                                        <td>
                                                            {item?.transactionType ==
                                                                1 && (
                                                                <span>
                                                                    Recharge
                                                                </span>
                                                            )}
                                                            {item?.transactionType ==
                                                                2 && (
                                                                <span>
                                                                    Bill pay
                                                                </span>
                                                            )}
                                                            {item?.transactionType ==
                                                                3 && (
                                                                <span>
                                                                    Wallet Debit
                                                                </span>
                                                            )}
                                                            {item?.transactionType ==
                                                                4 && (
                                                                <span>
                                                                    Wallet
                                                                    Credit
                                                                </span>
                                                            )}
                                                            {item?.transactionType ==
                                                                5 && (
                                                                <span>
                                                                    Reversal
                                                                </span>
                                                            )}
                                                            {item?.transactionType ==
                                                                6 && (
                                                                <span>
                                                                    PayOut Add
                                                                    Charge
                                                                </span>
                                                            )}
                                                            {item?.transactionType ==
                                                                7 && (
                                                                <span>
                                                                    Referral
                                                                    Commission{" "}
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <Moment
                                                                format="DD MMM YYYY hh:mm A"
                                                                subtract={{
                                                                    hours: 5,
                                                                    minutes: 30,
                                                                }}>
                                                                {
                                                                    item?.transactionDateTime
                                                                }
                                                            </Moment>
                                                        </td>
                                                        <td>
                                                            {item?.transactionStatus ==
                                                                2 && (
                                                                <span>
                                                                    {" "}
                                                                    Pending
                                                                </span>
                                                            )}
                                                            {item?.transactionStatus ==
                                                                1 && (
                                                                <span>
                                                                    {" "}
                                                                    Processed
                                                                </span>
                                                            )}
                                                            {item?.transactionStatus ==
                                                                3 && (
                                                                <span>
                                                                    {" "}
                                                                    Failed
                                                                </span>
                                                            )}
                                                            {item?.transactionStatus ==
                                                                4 && (
                                                                <span>
                                                                    {" "}
                                                                    Reversed
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {
                                                                item?.before_balance
                                                            }
                                                        </td>
                                                        <td>
                                                            {(item?.transactionType ==
                                                                2 ||
                                                                item?.transactionType ==
                                                                    3 ||
                                                                item?.transactionType ==
                                                                    6) && (
                                                                <span
                                                                    style={{
                                                                        color: "#F0532D",
                                                                        fontWeight: 500,
                                                                    }}>
                                                                    {
                                                                        item?.amount
                                                                    }
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {(item?.transactionType ==
                                                                4 ||
                                                                item?.transactionType ==
                                                                    7 ||
                                                                item?.transactionType ==
                                                                    5) && (
                                                                <span
                                                                    style={{
                                                                        color: "#0DB14B",
                                                                        fontWeight: 500,
                                                                    }}>
                                                                    {
                                                                        item?.amount
                                                                    }
                                                                </span>
                                                            )}
                                                            {item?.transactionType ==
                                                                1 && (
                                                                <span
                                                                    style={{
                                                                        color: "#0DB14B",
                                                                        fontWeight: 500,
                                                                    }}>
                                                                    {
                                                                        item?.subtotalAmount
                                                                    }
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {
                                                                item?.after_balance
                                                            }
                                                        </td>
                                                        {/* <td> {item?.razorpay_TransactionId} </td> */}
                                                    </tr>
                                                );
                                            }
                                        )}
                                </tbody>
                            </table>
                        )}
                        {walletTransactions?.length == 0 && (
                            <h3>No Records Found</h3>
                        )}
                    </div>

                    <Modal
                        show={showModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        // dialogClassName="c-modal-pay-bills"
                        onHide={() => setShowModal(false)}>
                        {/* <Modal.Header closeButton>
        
      </Modal.Header> */}
                        <Modal.Body>
                            <div className="t-details-div">
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td> Name </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {
                                                    currentRow?.bankAccountName
                                                }{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Mobile Number </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {currentRow?.mobileNumber}{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Bank Account Number </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {
                                                    currentRow?.bankAccountNumber
                                                }{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Bank IFSC Code </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {currentRow?.bankIFSCCode}{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Order ID </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {
                                                    currentRow?.wallet_transactionId
                                                }{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Sub total </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                ₹{
                                                    currentRow?.subtotalAmount
                                                }{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Charges </td>
                                            <td>:</td>
                                            <td> ₹{currentRow?.charges} </td>
                                        </tr>
                                        <tr>
                                            <td> GST Amount </td>
                                            <td>:</td>
                                            <td> ₹{currentRow?.GSTAmount} </td>
                                        </tr>

                                        <tr>
                                            <td> Amount </td>
                                            <td>:</td>
                                            <td> ₹{currentRow?.amount} </td>
                                        </tr>
                                        <tr>
                                            <td> Transaction Type </td>
                                            <td>:</td>
                                            <td>
                                                {currentRow?.transactionType ==
                                                    1 && <span>Recharge</span>}
                                                {currentRow?.transactionType ==
                                                    2 && <span>Bill pay</span>}
                                                {currentRow?.transactionType ==
                                                    3 && (
                                                    <span>Wallet Debit</span>
                                                )}
                                                {currentRow?.transactionType ==
                                                    4 && (
                                                    <span>Wallet Credit</span>
                                                )}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td> Date </td>
                                            <td>:</td>
                                            <td>
                                                <Moment format="DD MMM YYYY hh:mm A">
                                                    {
                                                        currentRow?.transactionDateTime
                                                    }
                                                </Moment>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Transactions Status </td>
                                            <td>:</td>
                                            <td>
                                                {currentRow?.transactionStatus ==
                                                    2 && (
                                                    <span className="pending-span">
                                                        {" "}
                                                        Pending
                                                    </span>
                                                )}
                                                {currentRow?.transactionStatus ==
                                                    1 && (
                                                    <span className="completed-span">
                                                        {" "}
                                                        Processed
                                                    </span>
                                                )}
                                                {currentRow?.transactionStatus ==
                                                    3 && (
                                                    <span className="failed-span">
                                                        {" "}
                                                        Failed
                                                    </span>
                                                )}
                                                {currentRow?.transactionStatus ==
                                                    4 && (
                                                    <span className="reversed-span">
                                                        {" "}
                                                        Reversed
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Description </td>
                                            <td>:</td>
                                            <td> {currentRow?.description} </td>
                                        </tr>
                                        <tr>
                                            <td> Notes </td>
                                            <td>:</td>
                                            <td> {currentRow?.notes} </td>
                                        </tr>
                                        <tr>
                                            <td> Payout ContactId </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {
                                                    currentRow?.payout_contactId
                                                }{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Razorpay TransactionId </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {
                                                    currentRow?.razorpay_TransactionId
                                                }{" "}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </>
    );
}

export default Statements;
