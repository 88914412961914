import React from "react";

function Values() {
    return (
        <>
            {/* <!-- ======= Values Section ======= --> */}
            <section id="features" className="features">
                <div className="container" data-aos="fade-up">
                    {/*  <header className="section-header">
    <h2>Features</h2>
    <p>Laboriosam et omnis fuga quis dolor direda fara</p>
  </header> */}

                    <div className="row">
                        <div className="col-lg-6">
                            <img
                                src="assets/img/home/boy_payville.svg"
                                className="img-fluid"
                                alt=""
                            />
                        </div>

                        <div className="col-lg-6 right-values">
                            <h1 className="heading-1">What Is Pay Ville?</h1>
                            <p>
                                We help you make any payments easy, simple and
                                affordable and you can pay all your expenses
                                through us with nominal charges
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Values;
