import React, { useState, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import LoginServices from "../services/LoginServices";
import "./Signup.css";
import { useNavigate } from "react-router-dom";

const Login = (props: any) => {
    const navigate = useNavigate();
    useEffect(() => {
        fetch("https://api.ipify.org/?format=json")
            .then((Response) => {
                return Response.json();
            })
            .then((response) => {
                console.log(response);
                setUserIpAddress(response.ip);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    const [userIpAddress, setUserIpAddress] = useState("");
    const reload = () => window.location.reload();
    const [getOTP, setgetOTP] = useState(false);
    const [getOTPForgotPassword, setGetOTPForgotPassword] = useState("no-otp");
    const [resendenabler, setResendEnabler] = useState(true);
    const [newPassword, setNewPassword] = useState("");
    const [cPassword, setCPassword] = useState("");
    const [counter, setcounter] = useState(60);
    const [showCSection, setShowCSection] = useState("login");
    // login fpassword

    let [userForgot, setUserForgot] = useState({
        mobileNumber: "",
        OTP: "",
    });

    const [errorMsg, setErrorMsg] = useState<String>("");
    let [user, setUser] = useState({
        mobileNumber: "",
        password: "",
    });

    const [loginPage, setloginPage] = useState(true);
    const [forgotPassWord, setForgotPassWord] = useState(false);
    const [forgotPasswordStep1, setForgotPasswordStep1] = useState(true);
    const [forgotPasswordStep2, setForgotPasswordStep2] = useState(false);
    const [forgotPasswordStep3, setForgotPasswordStep3] = useState(false);
    const [firstTimeLoginOtpinput, setfirstTimeLoginOtpinput] = useState(false);

    const [number, setNumber] = useState<number>();
    const [otp, setOtp] = useState<number>();
    const [reenterNumber, setReenterNumber] = useState<number>();
    const [password, setPassword] = useState<number>();
    const [confirmpassword, setConfirmpassword] = useState<number>();
    const [firstTimeLoginOtp, setfirstTimeLoginOtp] = useState<number>();

    let signIn = (e: any) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value,
        });
    };

    let forgotPassword = (e: any) => {
        setUserForgot({
            ...userForgot,
            [e.target.name]: e.target.value,
        });
    };
    const firstOtpHandler = (e: any) => {
        console.log(e.target.value);
        setfirstTimeLoginOtp(e.target.value);
    };
    const loginFormSubmit = () => {
        // console.log(user);

        LoginServices.loginWithPhonePassword({
            mobileNumber: user.mobileNumber,
            password: user.password,
            ipAddress: userIpAddress,
        })
            .then((response: any) => {
                console.log(response);

                if (
                    response.data.Status == 1 &&
                    response.data.Message == "Login successful"
                ) {
                    localStorage.setItem(
                        "userid",
                        response?.data?.userDetails?.userId
                    );
                    localStorage.setItem("token", response?.data?.Token);
                    localStorage.setItem(
                        "firstName",
                        response?.data?.userDetails?.firstName
                    );
                    localStorage.setItem(
                        "city",
                        response?.data?.userDetails?.city
                    );
                    console.log("inside");
                    navigate("/dashboard");
                } else if (response.data.Message == "OTP sent successfully") {
                    //
                    setfirstTimeLoginOtpinput(true);
                    const mytimer = setInterval(() => {
                        setcounter((prev) => prev - 1);
                    }, 1000);
                    setTimeout(() => {
                        setcounter(60);
                        setResendEnabler(() => {
                            clearInterval(mytimer);
                            return false;
                        });
                    }, 60000);
                    // const fd=async()=>{}
                } else {
                    setErrorMsg("");
                    alert(response.data.Message);
                }

                console.log(response.data);
            })
            .catch((e: any) => {
                console.log(e.response);
                setErrorMsg(e?.response?.data?.Message);
            });
    };
    const loginVerifyOtp = () => {
        console.log(firstTimeLoginOtp);
        LoginServices.loginVerfiyWithMobileNumber({
            mobileNumber: user.mobileNumber,
            password: user.password,
            OTP: Number(firstTimeLoginOtp),
            ipAddress: userIpAddress,
        })
            .then((response: any) => {
                console.log(response);

                if (response.data.Status == 1) {
                    localStorage.setItem(
                        "userid",
                        response?.data?.userDetails?.userId
                    );
                    localStorage.setItem("token", response?.data?.Token);
                    localStorage.setItem(
                        "firstName",
                        response?.data?.userDetails?.firstName
                    );
                    localStorage.setItem(
                        "city",
                        response?.data?.userDetails?.city
                    );

                    navigate("/dashboard");
                } else {
                    setErrorMsg("");
                    alert(response.data.Message);
                }
            })
            .catch((e: any) => {
                console.log(e.response);
                setErrorMsg(e?.response?.data?.Message);
            });
    };
    const sendOtp = () => {
        console.log(userForgot);
        LoginServices.getOtpForForgotPassword(userForgot)
            .then((response: any) => {
                console.log(response);

                if (response.data.Status == 1) {
                    setGetOTPForgotPassword("yes-otp");
                } else {
                    setErrorMsg("");
                    alert(response.data.Message);
                }

                console.log(response.data);
            })
            .catch((e: any) => {
                console.log(e.response);
                setErrorMsg(e?.response?.data?.Message);
            });
    };

    const verifyOtp = () => {
        console.log(userForgot);
        LoginServices.verifyOtpForgot(userForgot)
            .then((response: any) => {
                console.log(response);

                if (response.data.Status == 1) {
                    setGetOTPForgotPassword("change-password");
                } else {
                    setErrorMsg("");
                    alert(response.data.Message);
                }

                console.log(response.data);
            })
            .catch((e: any) => {
                console.log(e.response);
                setErrorMsg(e?.response?.data?.Message);
            });
    };

    const updatePassword = () => {
        if (cPassword != newPassword) {
            alert("New Password and Confirm Passwords not matching.");
            return;
        }
        if (cPassword.length < 8) {
            alert("Password length should be Minimum 8");
            return;
        }
        const payload = {
            mobileNumber: userForgot.mobileNumber,
            password: cPassword,
        };
        LoginServices.updatePasswordForgot(payload)
            .then((response: any) => {
                console.log(response);

                if (response.data.Status == 1) {
                    alert(response.data.Message);
                    setShowCSection("login");
                } else {
                    setErrorMsg("");
                    alert(response.data.Message);
                }

                console.log(response.data);
            })
            .catch((e: any) => {
                console.log(e.response);
                setErrorMsg(e?.response?.data?.Message);
            });
    };
    const resendOtp = () => {
        LoginServices.loginWithPhonePassword({
            mobileNumber: user.mobileNumber,
            password: user.password,
            ipAddress: userIpAddress,
        })
            .then((response: any) => {
                console.log(response);

                if (
                    response.data.Status == 1 &&
                    response.data.Message == "Login successful"
                ) {
                    localStorage.setItem(
                        "userid",
                        response?.data?.userDetails?.userId
                    );
                    localStorage.setItem("token", response?.data?.Token);
                    localStorage.setItem(
                        "firstName",
                        response?.data?.userDetails?.firstName
                    );
                    localStorage.setItem(
                        "city",
                        response?.data?.userDetails?.city
                    );
                    console.log("inside");
                    navigate("/dashboard");
                } else if (response.data.Message == "OTP sent successfully") {
                    //
                    const mytimer = setInterval(() => {
                        setcounter((prev) => prev - 1);
                    }, 1000);
                    setResendEnabler(true);
                    setTimeout(() => {
                        setcounter(60);
                        setResendEnabler(() => {
                            clearInterval(mytimer);
                            return false;
                        });
                    }, 60000);
                } else {
                    setErrorMsg("");
                    alert(response.data.Message);
                }

                console.log(response.data);
            })
            .catch((e: any) => {
                console.log(e.response);
                setErrorMsg(e?.response?.data?.Message);
            });

        // setcounter(60);
    };
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="login-modal1"
            centered
            backdrop={false}
            // closeButton={true}
        >
            {/* <Modal.Header closeButton>
        
      </Modal.Header> */}
            <Modal.Body>
                <div className="closeBtnDiv">
                    <button
                        onClick={() => {
                            console.log("r");

                            props.onHide();
                            window.location.reload();
                        }}
                        className="btn btn-danger">
                        Close
                    </button>
                </div>
                <Row className="signup_container">
                    <Col xs={12} md={5} className="signup_left">
                        <h1 className="signup_title">Signup Now</h1>
                        <p>Create an account and start using Payville. </p>
                        <button
                            onClick={props.onSignupRedirect}
                            className="loginButton">
                            Sign Up
                        </button>
                    </Col>
                    <Col xs={12} md={7} className="signup_right">
                        {forgotPassWord && (
                            <div>
                                <h1>Forgot Password</h1>
                                <span className="text-danger">{errorMsg}</span>

                                {forgotPasswordStep1 && (
                                    <div className="step1">
                                        <div className="inputWrapper">
                                            <img
                                                src="assets/img/signup/mobile.png"
                                                alt="mobile"
                                            />
                                            <input
                                                required={true}
                                                value={number}
                                                onChange={(e: any) =>
                                                    setNumber(e.target.value)
                                                }
                                                name="mobileNumber"
                                                className="form-control"
                                                type="text"
                                                placeholder="Enter Mobile Number"
                                            />
                                        </div>
                                        <button
                                            style={{ marginLeft: "70px" }}
                                            className="getOTP">
                                            Get OTP
                                        </button>
                                    </div>
                                )}

                                {forgotPasswordStep2 && (
                                    <div className="step2">
                                        <div className="inputWrapper">
                                            <img
                                                src="assets/img/signup/mobile.png"
                                                alt="mobile"
                                            />
                                            <input
                                                required={true}
                                                value={reenterNumber}
                                                onChange={(e: any) =>
                                                    setReenterNumber(
                                                        e.target.value
                                                    )
                                                }
                                                name="mobileNumber"
                                                className="form-control"
                                                type="text"
                                                placeholder="Enter Mobile Number"
                                            />
                                        </div>

                                        <div className="inputWrapper">
                                            <img
                                                src="assets/img/signup/otp.png"
                                                alt="OTP"
                                            />
                                            <input
                                                className="form-control"
                                                value={otp}
                                                onChange={(e: any) =>
                                                    setOtp(e.target.value)
                                                }
                                                type="text"
                                                placeholder="Enter OTP"
                                            />
                                        </div>
                                        <button
                                            style={{ marginLeft: "70px" }}
                                            className="getOTP">
                                            Verify
                                        </button>
                                    </div>
                                )}

                                {forgotPasswordStep3 && (
                                    <div className="step3">
                                        <div className="inputWrapper">
                                            <img
                                                src="assets/img/signup/lock.png"
                                                alt="password"
                                            />
                                            <input
                                                required={true}
                                                value={password}
                                                onChange={(e: any) =>
                                                    setPassword(e.target.value)
                                                }
                                                name="password"
                                                className="form-control"
                                                type="password"
                                                placeholder="Enter Password"
                                            />
                                        </div>

                                        <div className="inputWrapper">
                                            <img
                                                src="assets/img/signup/lock.png"
                                                alt="password"
                                            />
                                            <input
                                                required={true}
                                                value={confirmpassword}
                                                onChange={(e: any) =>
                                                    setConfirmpassword(
                                                        e.target.value
                                                    )
                                                }
                                                name="password"
                                                className="form-control"
                                                type="password"
                                                placeholder="Confirm Password"
                                            />
                                        </div>
                                        <button
                                            style={{ marginLeft: "70px" }}
                                            className="getOTP">
                                            Submit
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}

                        {loginPage && (
                            <>
                                <span className="text-danger">{errorMsg}</span>
                                {showCSection == "login" && (
                                    <div>
                                        <h1>Log In</h1>

                                        <div className="inputWrapper">
                                            <img
                                                src="assets/img/signup/mobile.png"
                                                alt="mobile"
                                            />
                                            <input
                                                required={true}
                                                onChange={signIn}
                                                name="mobileNumber"
                                                className="form-control"
                                                type="text"
                                                placeholder="Enter Mobile Number"
                                                disabled={
                                                    firstTimeLoginOtpinput
                                                }
                                            />
                                        </div>

                                        <div className="inputWrapper">
                                            <img
                                                src="assets/img/signup/lock.png"
                                                alt="password"
                                            />
                                            <input
                                                required={true}
                                                onChange={signIn}
                                                name="password"
                                                className="form-control"
                                                type="password"
                                                placeholder="Enter Password"
                                                disabled={
                                                    firstTimeLoginOtpinput
                                                }
                                            />
                                        </div>
                                        {firstTimeLoginOtpinput && (
                                            <div className="inputWrapper">
                                                <img
                                                    src="assets/img/signup/lock.png"
                                                    alt="OTP"
                                                />
                                                <input
                                                    className="form-control"
                                                    type="password"
                                                    placeholder="Enter OTP"
                                                    name="password"
                                                    onChange={firstOtpHandler}
                                                />
                                            </div>
                                        )}
                                        {getOTP == true && (
                                            <div className="inputWrapper">
                                                <img
                                                    src="assets/img/signup/otp.png"
                                                    alt="OTP"
                                                />
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Enter OTP"
                                                />
                                            </div>
                                        )}

                                        <div className="forgetpassword">
                                            <span
                                                onClick={() => {
                                                    setShowCSection(
                                                        "fpassword"
                                                    );
                                                }}>
                                                Forgot Password?
                                            </span>
                                            {firstTimeLoginOtpinput == true && (
                                                <button
                                                    disabled={resendenabler}
                                                    onClick={resendOtp}>
                                                    Resend Otp{" "}
                                                    {resendenabler == true
                                                        ? `(${counter})`
                                                        : ""}
                                                </button>
                                            )}
                                        </div>

                                        {getOTP == false &&
                                            firstTimeLoginOtpinput == false && (
                                                <button
                                                    className="getOTP"
                                                    onClick={() =>
                                                        loginFormSubmit()
                                                    }>
                                                    Login
                                                </button>
                                            )}
                                        {firstTimeLoginOtpinput == true && (
                                            <button
                                                className="getOTP"
                                                onClick={() =>
                                                    loginVerifyOtp()
                                                }>
                                                Verify
                                            </button>
                                        )}
                                        {getOTP == true && (
                                            <button className="getOTP">
                                                Login
                                            </button>
                                        )}
                                    </div>
                                )}

                                {showCSection == "fpassword" && (
                                    <div>
                                        <h1>Forgot Password</h1>

                                        {getOTPForgotPassword == "no-otp" && (
                                            <div className="inputWrapper">
                                                <img
                                                    src="assets/img/signup/mobile.png"
                                                    alt="mobile"
                                                />
                                                <input
                                                    required={true}
                                                    onChange={forgotPassword}
                                                    name="mobileNumber"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Enter Mobile Number"
                                                />
                                            </div>
                                        )}

                                        {getOTPForgotPassword == "no-otp" && (
                                            <button
                                                className="getOTP"
                                                onClick={() => sendOtp()}>
                                                Send OTP
                                            </button>
                                        )}

                                        {getOTPForgotPassword == "yes-otp" && (
                                            <>
                                                <div className="inputWrapper">
                                                    <img
                                                        src="assets/img/signup/mobile.png"
                                                        alt="mobile"
                                                    />
                                                    <input
                                                        value={
                                                            userForgot?.mobileNumber
                                                        }
                                                        className="form-control"
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>

                                                <div className="inputWrapper">
                                                    <img
                                                        src="assets/img/signup/otp.png"
                                                        alt="OTP"
                                                    />
                                                    <input
                                                        className="form-control"
                                                        name="OTP"
                                                        onChange={
                                                            forgotPassword
                                                        }
                                                        type="text"
                                                        placeholder="Enter OTP"
                                                    />
                                                </div>
                                            </>
                                        )}

                                        {getOTPForgotPassword == "yes-otp" && (
                                            <button
                                                className="getOTP"
                                                onClick={verifyOtp}>
                                                Submit OTP
                                            </button>
                                        )}

                                        {getOTPForgotPassword ==
                                            "change-password" && (
                                            <>
                                                <div className="inputWrapper">
                                                    <img
                                                        src="assets/img/signup/mobile.png"
                                                        alt="mobile"
                                                    />
                                                    <input
                                                        value={
                                                            userForgot?.mobileNumber
                                                        }
                                                        className="form-control"
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                                <div className="inputWrapper">
                                                    <img
                                                        src="assets/img/signup/lock.png"
                                                        alt="password"
                                                    />
                                                    <input
                                                        className="form-control"
                                                        onChange={(e: any) => {
                                                            setNewPassword(
                                                                e.target.value
                                                            );
                                                        }}
                                                        type="password"
                                                        placeholder="Enter New Password"
                                                    />
                                                </div>
                                                <div className="inputWrapper">
                                                    <img
                                                        src="assets/img/signup/lock.png"
                                                        alt="password"
                                                    />
                                                    <input
                                                        className="form-control"
                                                        onChange={(e: any) => {
                                                            setCPassword(
                                                                e.target.value
                                                            );
                                                        }}
                                                        type="password"
                                                        placeholder="Enter Confirm Password"
                                                    />
                                                </div>
                                                <button
                                                    className="update-password"
                                                    onClick={updatePassword}>
                                                    Update Password
                                                </button>
                                            </>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </Col>
                </Row>
            </Modal.Body>
            {/*  <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
        </Modal>
    );
};

export default Login;
