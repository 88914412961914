import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import LoginServices from "../../services/LoginServices";
import header_Logo from "../../assets/bbps/bharat_pay.png";
export default function Success() {
    const navigate = useNavigate();
    const billerid = sessionStorage.getItem("Biller");
    const billerCat = localStorage.getItem("billerName");
    const [successdetails, setsuccessdetails] = useState<any>();
    const usernumber = sessionStorage.getItem("usernumber");
    const success = sessionStorage.getItem("success");
    const userId = localStorage.getItem("userid");

    useEffect(() => {
        if (success) {
            setsuccessdetails(JSON.parse(success));
            console.log(JSON.parse(success));
        }
    }, []);
    return (
        <>
            <div className="row main-row">
                <div className="col-10 dashboard-right-back">
                    <div className="header-bbps">
                        <div className="BbpsWrapper">
                            <div className="Bbps-heading">
                                <p>BBPS</p>
                                <img src={header_Logo} alt="" />
                            </div>
                            {/* <div className="Bbps-Navbar">
                            <Navbar active="billpay" />
                        </div> */}
                            <div className="successWrappe">
                                <div className="successWrapper">
                                    <img src="assets/img/assured.svg" alt="" />
                                    <p>Your Transaction is Successfull..!</p>
                                    <div className="success-details">
                                        <div className="success-detail">
                                            <p>Transaction ID</p>
                                            <p>:</p>
                                            <p>{successdetails?.txnRefId}</p>
                                        </div>
                                        <div className="success-detail">
                                            <p>Biller Id</p>
                                            <p>:</p>
                                            <p>{billerid}</p>
                                        </div>
                                        <div className="success-detail">
                                            <p>Biller Name</p>
                                            <p>:</p>
                                            <p>{billerCat}</p>
                                        </div>
                                        <div className="success-detail">
                                            <p>Customer Name</p>
                                            <p>:</p>
                                            <p>
                                                {
                                                    successdetails?.RespCustomerName
                                                }
                                            </p>
                                        </div>
                                        <div className="success-detail">
                                            <p>Mobile Number</p>
                                            <p>:</p>
                                            <p>{usernumber}</p>
                                        </div>
                                        <div className="success-detail">
                                            <p>Bill amount</p>
                                            <p>:</p>
                                            <p>
                                                ₹{" "}
                                                {Number(
                                                    successdetails?.RespAmount
                                                ) / 100}
                                            </p>
                                        </div>
                                        <div className="success-detail">
                                            <p>Customer Convenience fee</p>
                                            <p>:</p>
                                            <p>
                                                ₹ {successdetails?.CustConvFee}
                                            </p>
                                        </div>
                                        <div className="success-detail">
                                            <p>Total Amount</p>
                                            <p>:</p>
                                            <p>
                                                ₹{" "}
                                                {Number(
                                                    successdetails?.RespAmount
                                                ) /
                                                    100 +
                                                    Number(
                                                        successdetails?.CustConvFee
                                                    )}{" "}
                                            </p>
                                        </div>
                                        <div className="success-detail">
                                            <p>Transaction Date & time</p>
                                            <p>:</p>
                                            <p>
                                                {
                                                    successdetails?.TransactionDateTime
                                                }
                                            </p>
                                        </div>
                                        <div className="success-detail">
                                            <p>Initiating Channel </p>
                                            <p>:</p>
                                            <p>AGT</p>
                                        </div>
                                        <div className="success-detail">
                                            <p>Payment Mode</p>
                                            <p>:</p>
                                            <p>Cash</p>
                                        </div>
                                        <div className="success-detail">
                                            <p>Transaction Status</p>
                                            <p>:</p>
                                            <p>
                                                {successdetails?.responseReason}
                                            </p>
                                        </div>
                                        <div className="success-detail">
                                            <p>Approval Number</p>
                                            <p>:</p>
                                            <p>
                                                {
                                                    successdetails?.approvalRefNumber
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
