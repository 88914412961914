import React from 'react';

function Testimonial() {
  return (
  <>
{/*     <!-- ======= Testimonial Section ======= --> */}
    <section id="testimonial" className="testimonial">
      
<div className="container" data-aos="fade-up">

 

  <div className="row">

    <div className="col-lg-8 services" style={{paddingBottom:40}}>
    <div className="service-box" style={{paddingTop:40}} data-aos="zoom-in" data-aos-delay="1000">
      <img src="assets/img/home/quotes.png" className="img-fluid quotes" alt="" />
        <p style={{textAlign:"left"}}> I truly like this application because of its great features and basic interface. It is extremely straightforward and simple to use for utility bill payments.</p>
        <div className="author">
        <img src="assets/img/home/author1.png" className="img-fluid quotes" alt="" />
        <p>Sravan Reddy <br/><small>Pune, Maharashtra</small></p>
        </div>        
      </div>
      <div className="box"></div>
    </div>

    <div className="col-lg-4  testimonials" data-aos="zoom-out" data-aos-delay="1000">
    <h3>Testimonials</h3>
    <p style={{textAlign:"center", fontSize:19}}> Our praise us for our great results</p>
    </div>

  </div> 


</div>
</section>
  </>
  );
}

export default Testimonial;