import React from "react";
import { Container } from "react-bootstrap";

export default function TermsPage() {
    return (
        <div>
            <Container>
                <h1 style={{ marginLeft: "30vw" }}>Terms and Conditions</h1>
                <p>
                    <strong>
                        OVERVIEW This document prescribes the “Terms and
                        Conditions”
                    </strong>
                    , (hereinafter referred to as “TOS” or “Terms of Service”)
                    which shall be applicable to all transactions initiated by
                    the User through the PayVille application( hereinafter
                    referred to as “PayVille App” or “THE APP”) developed by
                    PayVille (hereinafter referred to as “PayVille” – which term
                    shall encompass PayVille, its parent, subsidiaries,
                    predecessors, successors, affiliates, partners, officers,
                    directors, agents, contractors, licensors, service
                    providers, subcontractors, suppliers, interns and
                    employees). User shall mean anyone who downloads or installs
                    or otherwise uses the App. The User is advised to carefully
                    read and understand these Terms and Conditions before using
                    the PayVille App. By downloading the App, the User shall be
                    deemed to have read, understood and accepted the TOS,
                    mentioned herein below and will be bound by the TOS. The TOS
                    may amended from time to time, the latest TOS will be
                    displayed on PayVille’s website. The continued use of The
                    App constitutes acceptance by the User of any such amendment
                    to the TOS. The TOS shall apply to all users of the App,
                    including without limitation users who have installed The
                    App, downloaded The App, vendors, customers, merchants, and/
                    or contributors of content. Please read the TOS carefully
                    before accessing or using our App. By accessing or using any
                    part of the App, you agree to be bound by the TOS. If you do
                    not agree to all the conditions of this agreement, then you
                    may not use the App or use any of its services. If these
                    Terms of Service are considered an offer, acceptance is
                    expressly limited to these Terms of Service. Any new
                    features or tools which are added to the current store shall
                    also be subject to the Terms of Service. You can review the
                    most current version of the Terms of Service at any time on
                    this app. We reserve the right to update, change or replace
                    any part of these Terms of Service by posting updates and/or
                    changes to our app. It is your responsibility to check this
                    page periodically for changes. Your continued use of or
                    access to the App following the posting of any changes
                    constitutes acceptance of those changes.
                </p>

                <h5>SECTION I - ONLINE SERVICES TERMS</h5>
                <p>
                    By agreeing to the TOS, you represent that you are at least
                    the age of majority in your state or province of residence,
                    or that you are the age of majority in your state or
                    province of residence and you have given us your consent to
                    allow any of your minor dependents to use this app. You may
                    not use our products for any illegal or unauthorized
                    purpose, nor may you, in the use of the Service, violate any
                    laws in your jurisdiction (including but not limited to
                    copyright laws). You must not transmit any worms or viruses
                    or any code of a destructive nature. A breach or violation
                    of any of the Terms will result in an immediate termination
                    of your Services.
                </p>

                <h5>SECTION II - GENERAL CONDITIONS</h5>
                <p>
                    We reserve the right to refuse service to anyone for any
                    reason at any time. You understand that your content (NOT
                    including credit card information), may be transferred
                    unencrypted and involve (a) transmissions over various
                    networks; and (b) changes to confirm and adapt to technical
                    requirements of connecting networks or devices. Credit card
                    information is always encrypted during transfer over
                    networks. You agree not to reproduce, duplicate, copy, sell,
                    resell or exploit any portion of the Service, use of the
                    Service, or access to the Service or any contact on the App
                    through which the service is provided, without express
                    written permission by us. The headings used in this
                    agreement are included for convenience only and will not
                    limit or otherwise affect these Terms.
                </p>

                <h5>
                    SECTION III - ACCURACY, COMPLETENESS AND TIMELINESS OF
                    INFORMATION
                </h5>
                <p>
                    We are not responsible if information made available on this
                    App is not accurate, complete or current. The material on
                    App is provided for general information only and should not
                    be relied upon or used as the sole basis for making
                    decisions without consulting primary, more accurate, more
                    complete or more timely sources of information. Any reliance
                    on the material on this App is at your own risk. This App
                    may contain certain historical information. Historical
                    information, necessarily, is not current and is provided for
                    your reference only. We reserve the right to modify the
                    contents of this App at any time, but we have no obligation
                    to update any information on our App. You agree that it is
                    your responsibility to monitor changes to our App.
                </p>

                <h5>SECTION IV - MODIFICATIONS TO THE SERVICE AND PRICES</h5>
                <p>
                    Prices / fees for our products & services are subject to
                    change without notice. We reserve the right at any time to
                    modify or discontinue the Service (or any part or content
                    thereof) without notice at any time. We shall not be liable
                    to you or to any third-party for any modification, price
                    change, suspension or discontinuance of the Service.
                </p>

                <h5>SECTION V - PROHIBITED USES</h5>
                <p>
                    In addition to other prohibitions as set forth in the Terms
                    of Service, you are prohibited from using the app or its
                    content: (a) for any unlawful purpose; (b) to solicit others
                    to perform or participate in any unlawful acts; (c) to
                    violate any international, federal, provincial or state
                    regulations, rules, laws, or local ordinances; (d) to
                    infringe upon or violate our intellectual property rights or
                    the intellectual property rights of others; (e) to harass,
                    abuse, insult, harm, defame, slander, disparage, intimidate,
                    or discriminate based on gender, sexual orientation,
                    religion, ethnicity, race, age, national origin, or
                    disability; (f) to submit false or misleading information;
                    (g) to upload or transmit viruses or any other type of
                    malicious code that will or may be used in any way that will
                    affect the functionality or operation of the Service or of
                    any related website, other websites, or the Internet; (h) to
                    collect or track the personal information of others; (i) to
                    spam, phis, pharm, pretext, spider, crawl, or scrape; (j)
                    for any obscene or immoral purpose; or (k) to interfere with
                    or circumvent the security features of the Service or any
                    related website, other websites, or the Internet. We reserve
                    the right to terminate your use of the Service or any
                    related website for violating any of the prohibited uses.
                </p>

                <h5>
                    SECTION VI - DISCLAIMER OF WARRANTIES, LIMITATION OF
                    LIABILITY
                </h5>
                <p>
                    We do not guarantee, represent or warrant that your use of
                    our service will be uninterrupted, timely, secure or
                    error-free. We do not warrant that the results that may be
                    obtained from the use of the service will be accurate or
                    reliable. You agree that from time to time we may remove the
                    service for indefinite periods of time or cancel the service
                    at any time, without notice to you. You expressly agree that
                    your use of, or inability to use, the service is at your
                    sole risk. The service and all products and services
                    delivered to you through the service are (except as
                    expressly stated by us) provided 'as is' and 'as available'
                    for your use, without any representation, warranties or
                    conditions of any kind, either express or implied, including
                    all implied warranties or conditions of merchantability,
                    merchantable quality, fitness for a particular purpose,
                    durability, title, and non-infringement. In no case shall
                    PayVille our partners, officers, employees, affiliates,
                    agents, contractors, interns, suppliers, service providers
                    or licensors be liable for any injury, loss, claim, or any
                    direct, indirect, incidental, punitive, special, or
                    consequential damages of any kind, including, without
                    limitation lost profits, lost revenue, lost savings, loss of
                    data, replacement costs, or any similar damages, whether
                    based in contract, tort (including negligence), strict
                    liability or otherwise, arising from your use of any of the
                    service or any products procured using the service, or for
                    any other claim related in any way to your use of the
                    service or any product, including, but not limited to, any
                    errors or omissions in any content, or any loss or damage of
                    any kind incurred as a result of the use of the service or
                    any content (or product) posted, transmitted, or otherwise
                    made available via the service, even if advised of their
                    possibility. Because some states or jurisdictions do not
                    allow the exclusion or the limitation of liability for
                    consequential or incidental damages, in such states or
                    jurisdictions, our liability shall be limited to the maximum
                    extent permitted by law.
                </p>

                <h5>SECTION VII - CHANGES TO TERMS OF SERVICE</h5>
                <p>
                    You can review the most current version of the Terms of
                    Service at any time at this page. We reserve the right, at
                    our sole discretion, to update, change or replace any part
                    of this TOS by posting updates and changes to our App. It is
                    your responsibility to check our website periodically for
                    changes. Your continued use of or access to our App or the
                    Service following the posting of any changes to these Terms
                    of Service constitutes acceptance of those changes.
                </p>

                <h5>SECTION VIII - CONTACT INFORMATION</h5>
                <p>
                    Questions about the Terms of Service should be sent to us at
                    support@payville.in
                </p>
            </Container>
        </div>
    );
}
