import "./Bbps.css";
import Navbar from "./Bbpsnav";
import { useForm } from "react-hook-form";
import LoginServices from "../../services/LoginServices";
import { useNavigate, useParams } from "react-router-dom";
import header_Logo from "../../assets/bbps/bharat_pay.png";
import React, { useState, useEffect } from "react";
import Submenu from "./Bbpssubmenu";
import Spinner from "../spinner";
export default function Bbps() {
    const navigate = useNavigate();
    const [spinner, setSpinner] = useState(false);
    const Complaints = {
        "font-family": "Rubik",
        "font-style": "normal",
        "font-weight": "normal",
        "font-size": " 30px",
        "line-height": "22px",
        "letter-spacing": "0.02em",
        color: "#5B5B5A",
    };
    const userId = localStorage.getItem("userid");
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const {
        register: register2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
    } = useForm();
    const getstatus = (body: any) => {
        setSpinner(true);
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        LoginServices.transactionStatus(body, config)
            .then((res) => {
                setSpinner(false);
                if (res.data.Status == 1) {
                    sessionStorage.setItem(
                        "status",
                        JSON.stringify(res.data.TransactionStatus)
                    );
                    navigate("/dashboard/bbps/searchresult");
                } else {
                    alert("something went wrong try again later");
                }
                // console.log(res);
            })
            .catch((err) => {
                setSpinner(false);
                console.log(err);
                alert("something went wrong try again later");
            });
    };
    const onSubmit = (data: any) => {
        getstatus({ ...data, trackType: "TRANSACTION_ID" });
    };
    const formtwo = (data: any) => {
        let body = { ...data, trackType: "MOBILE_NO" };
        getstatus(body);
    };
    return (
        <>
            {spinner && <Spinner />}

            <div className="row main-row">
                <div className="col-10 dashboard-right-back">
                    <div className="header-bbps">
                        <div className="BbpsWrapper">
                            <div className="Bbps-heading">
                                <p>Complaints</p>
                                <img src={header_Logo} alt="" />
                            </div>
                            <div className="Bbps-Navbar">
                                <Navbar active="complaints" />
                            </div>
                            <div className="Raise-request">
                                <div className="Complaint-formwrapper">
                                    <Submenu active="search" />
                                    <div
                                        className="Complaint-formwrapper search-transaction-wrapper"
                                        style={{ paddingBottom: "5rem" }}>
                                        <h1>Search Transaction </h1>
                                        <form
                                            className="search-transaction"
                                            onSubmit={handleSubmit(onSubmit)}>
                                            <div className="Complaint-form-control ">
                                                <label htmlFor=" Transaction Ref Id">
                                                    Transaction Ref Id
                                                </label>
                                                <div>
                                                    <input
                                                        className={`input ${
                                                            errors?.transactionReferenceId &&
                                                            "inputerror"
                                                        }`}
                                                        type="text"
                                                        {...register(
                                                            "transactionReferenceId",
                                                            {
                                                                required:
                                                                    " Transaction Ref Id is required",
                                                            }
                                                        )}
                                                    />
                                                    {errors?.transactionReferenceId && (
                                                        <p className="error">
                                                            {
                                                                errors
                                                                    ?.transactionReferenceId
                                                                    .message
                                                            }
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    paddingTop: "0rem",
                                                    alignSelf: "center",
                                                }}>
                                                <button type="submit">
                                                    Search
                                                </button>
                                            </div>
                                        </form>
                                        <h3 className="or">OR</h3>
                                        <form
                                            className="search-transaction-form-two"
                                            onSubmit={handleSubmit2(formtwo)}>
                                            <div className="Complaint-form-control ">
                                                <label htmlFor=" FromDate">
                                                    From Date
                                                </label>
                                                <div
                                                    style={{
                                                        height: "100%",
                                                        minHeight: "5rem",
                                                    }}>
                                                    <input
                                                        className={`input ${
                                                            errors2?.fromDate &&
                                                            "inputerror"
                                                        }`}
                                                        type="date"
                                                        {...register2(
                                                            "fromDate",
                                                            {
                                                                required:
                                                                    " From Date is required",
                                                            }
                                                        )}
                                                    />

                                                    <p className="error">
                                                        {
                                                            errors2?.fromDate
                                                                ?.message
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="Complaint-form-control ">
                                                <label htmlFor=" ToDate">
                                                    To Date
                                                </label>
                                                <div
                                                    style={{
                                                        height: "100%",
                                                        minHeight: "5rem",
                                                    }}>
                                                    <input
                                                        className={`input ${
                                                            errors2?.toDate &&
                                                            "inputerror"
                                                        }`}
                                                        type="date"
                                                        {...register2(
                                                            "toDate",
                                                            {
                                                                required:
                                                                    " To Date is required",
                                                            }
                                                        )}
                                                    />
                                                    <p className="error">
                                                        {
                                                            errors2?.toDate
                                                                ?.message
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="Complaint-form-control ">
                                                <label htmlFor=" MobileNumber">
                                                    Mobile Number
                                                </label>
                                                <div
                                                    style={{
                                                        height: "100%",
                                                        minHeight: "5rem",
                                                    }}>
                                                    <input
                                                        className={`input ${
                                                            errors2?.mobileNumber &&
                                                            "inputerror"
                                                        }`}
                                                        type="number"
                                                        {...register2(
                                                            "mobileNumber",
                                                            {
                                                                required:
                                                                    " Mobile number is required",
                                                            }
                                                        )}
                                                    />

                                                    <p className="error">
                                                        {
                                                            errors2
                                                                ?.mobileNumber
                                                                ?.message
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    paddingTop: "0rem",
                                                    alignSelf: "center",
                                                    marginTop: "1.4rem",
                                                    height: "100%",
                                                    minHeight: "5rem",
                                                }}>
                                                <button type="submit">
                                                    Search
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
