import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import '../../App.css';
import "./dashboard.css";
import Script from "./script.jsx";
import LoginServices from "../../services/LoginServices";
import moment from "moment";
import ChartsCustom from "./Charts";
import { Row, Col, Container, Button } from "react-bootstrap";
import flag from "../../assets/india (1) 1.png";

import backGround1 from "../../assets/Ellipse 116.svg";
import backGround2 from "../../assets/Ellipse 117.svg";
let abortController = new AbortController();
const WalletTransactions = (props: any) => {
    const transactions = props.walletTransactions.map(
        (item: any, index: number) => (
            <div style={{ marginTop: "25px" }} key={index}>
                <Row style={{ borderBottom: "1px solid gray" }}>
                    {/* <h4>Today | 19th Jan 2022</h4> */}

                    {/* moment().format('MMMM Do YYYY, h:mm:ss a'); */}

                    <h5>
                        {moment(item.transactionDateTime).calendar(new Date(), {
                            lastDay: "[Yesterday] |",
                            sameDay: "[Today] | ",
                            lastWeek: "[Last] dddd | ",
                            sameElse: " ",
                        })}
                        {moment(item.transactionDateTime).format("Do MMM YYYY")}
                    </h5>
                </Row>

                <Container style={{ marginTop: "10px" }}>
                    <Row className="align-items-center">
                        <Col lg={2}>
                            <div
                                className="icon"
                                style={{
                                    backgroundColor: "white",
                                    borderRadius: "20px",
                                    width: "55px",
                                    height: "55px",
                                }}>
                                {/* <i className="material-icons" style={{color:"green",paddingTop:"14px",marginLeft:"15px"}}>add</i> */}
                                <h6
                                    style={{
                                        fontSize: "44px",
                                        fontWeight: "lighter",
                                        textAlign: "center",
                                        color: `${
                                            item.transactionType == 1 ||
                                            item.transactionType == 4 ||
                                            item.transactionType == 5
                                                ? "green"
                                                : "red"
                                        }`,
                                    }}>
                                    {item.transactionType == 1 ||
                                    item.transactionType == 4 ||
                                    item.transactionType == 5
                                        ? "+"
                                        : "-"}
                                </h6>
                            </div>
                        </Col>
                        <Col xs={7} className="align-self-center">
                            <div>
                                {item?.transactionType == 1 && (
                                    <h6>Recharge</h6>
                                )}
                                {item?.transactionType == 2 && (
                                    <h6> Bill payment</h6>
                                )}
                                {item?.transactionType == 3 && (
                                    <h6>Wallet Debit</h6>
                                )}
                                {item?.transactionType == 4 && (
                                    <h6>Wallet Credit</h6>
                                )}
                                {item?.transactionType == 5 && (
                                    <h6>Reversal</h6>
                                )}
                                {item?.transactionType == 6 && (
                                    <h6>PayOut Add Charge </h6>
                                )}
                                {item?.transactionType == 7 && (
                                    <h6>Referral Commission </h6>
                                )}
                                {item?.transactionType == 8 && (
                                    <h6>BBPS Transaction </h6>
                                )}
                                {item?.transactionType == 9 && (
                                    <h6>Admin Debit</h6>
                                )}
                            </div>
                            {/* <div>{item.description}</div> */}
                        </Col>
                        <Col>
                            <h6 style={{ marginTop: "15px" }}>
                                ₹ {item.amount}
                            </h6>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    );

    return <div className="transactions">{transactions}</div>;
};

function Dashboard() {
    const navigate = useNavigate();
    const [walletBlc, setWalletBlc] = useState<any>(0);
    const [userDetails, setUserDetails] = useState<any>();
    const userId = localStorage.getItem("userid");
    const [charts, setcharts] = useState({
        chartsDataAvailabe: false,
        chartsData: {},
    });
    const [walletTransactions, setwalletTransactions] = useState([]);
    const [appSetttings, setAppSettings] = useState("");

    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getAppSettings(parseInt(userId), config).then(
                (Response: any) => {
                    // console.log(Response.data.Settings);
                    setAppSettings(Response?.data?.Settings?.news);
                    if (Response.data.Status == 1) {
                    }
                }
            );
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    // console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
            LoginServices.getUserDetailsByID(parseInt(userId), config)
                .then((response: any) => {
                    // console.log(response);
                    if (response.data.Status == 1) {
                        // setWalletBlc(response?.data?.walletBalance)
                        setUserDetails(response?.data?.UserDetails);
                    } else {
                    }

                    // console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });

            LoginServices.getDashboardDetails(parseInt(userId), config)
                .then((response: any) => {
                    // console.log(response);

                    if (response.data.Status == 1) {
                        setcharts({
                            chartsDataAvailabe: true,
                            chartsData:
                                response.data.Dashboard.MonthlyTransactions,
                        });
                        setwalletTransactions(
                            response.data.Dashboard.walletTransactions
                        );
                    } else {
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
        return () => {
            abortController.abort();
        };
    }, []);

    return (
        <>
            <React.Fragment>
                <Script />
                <div className="row main-row">
                    <div className="col-10 dashboard-right-back">
                        <div style={{ padding: "30px 45px" }}>
                            <img
                                src={backGround1}
                                style={{
                                    position: "absolute",
                                    bottom: "0",
                                    left: "-500px",
                                    zIndex: 1,
                                }}></img>
                            <img
                                src={backGround2}
                                style={{
                                    position: "absolute",
                                    top: "0",
                                    right: "0px",
                                }}></img>
                            <div>
                                <div className="marquee">
                                    <p
                                        className="head-hi"
                                        style={{
                                            color: "#6865f0",
                                            marginBottom: "2rem",
                                        }}>
                                        {appSetttings}
                                    </p>
                                </div>
                                <h1 className="head-dashboard">Dashboard</h1>
                                <Row>
                                    <Col>
                                        <p className="head-hi">
                                            Hi,
                                            {userDetails?.userType ==
                                            "BUSINESS" ? (
                                                <span>
                                                    {userDetails?.companyName},
                                                    welcome back!
                                                </span>
                                            ) : (
                                                <span>
                                                    {userDetails?.firstName},
                                                    welcome back!
                                                </span>
                                            )}
                                        </p>
                                    </Col>
                                    {/* <Col style={{ zIndex: 5 }}>
                                        <p className="pay-url">
                                            www.payville.in/payment/
                                            {userDetails?.paymentPageId}
                                        </p>
                                    </Col> */}
                                </Row>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <div className="blc-section">
                                        <p className="your-blc">Your Balance</p>
                                        <p className="blc-rs">₹ {walletBlc}</p>
                                    </div>
                                    {/* <i className="fa fa-search"></i> */}

                                    <div className="Recent-transaction">
                                        <h5 className="h5">
                                            <img src="assets/img/Swap.svg" />
                                            Recent Transactions
                                        </h5>
                                        <div className="pointer"></div>
                                    </div>
                                </div>

                                {/* <div
                                    className="col-5 blc-section"
                                    style={{ marginLeft: "40px" }}>
                                    <Container>
                                        <h6
                                            style={{
                                                color: "gray",
                                                marginBottom: "20px",
                                            }}>
                                            Quick Transfer
                                        </h6>
                                    </Container>

                                    <Row style={{ marginLeft: "20px" }}>
                                        <Col>
                                            <Button
                                                style={{
                                                    borderRadius: "15px",
                                                    border: "2px solid #6865F0",
                                                    backgroundColor: "white",
                                                    color: "black",
                                                }}>
                                                Via Mobile Number
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                style={{
                                                    borderRadius: "15px",
                                                    border: "2px solid #6865F0",
                                                    backgroundColor: "white",
                                                    color: "black",
                                                }}>
                                                Via Account Number
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={12}>
                                            <Container>
                                                <Row>
                                                    <Col
                                                        style={{
                                                            borderBottom:
                                                                "1px solid gray",
                                                        }}>
                                                        <div className="form__group field">
                                                            <img
                                                                src={flag}
                                                                alt="flag"
                                                                style={{
                                                                    marginRight:
                                                                        "10px",
                                                                }}
                                                            />
                                                            <span>+91 | </span>
                                                            <input
                                                                type="input"
                                                                className="form__field"
                                                                placeholder="Number"
                                                                name="number"
                                                                id="number"
                                                                required
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col lg={1}>
                                                        <div className="circle"></div>
                                                    </Col>
                                                </Row>

                                                <div
                                                    className="form__group field"
                                                    style={{
                                                        width: "95%",
                                                        borderBottom:
                                                            "1px solid gray",
                                                    }}>
                                                    <input
                                                        type="input"
                                                        className="form__field"
                                                        placeholder="Amount"
                                                        name="amount"
                                                        id="amount"
                                                        required
                                                    />
                                                </div>

                                                <div
                                                    className="send-button"
                                                    style={{
                                                        marginTop: "20px",
                                                        marginLeft: "80px",
                                                    }}>
                                                    <Button
                                                        style={{
                                                            width: "250px",
                                                            borderRadius:
                                                                "10px",
                                                            backgroundColor:
                                                                "#6865F0",
                                                        }}>
                                                        Send
                                                    </Button>
                                                </div>
                                            </Container>
                                        </Col>
                                    </Row>
                                </div> */}
                                {/* <div
                                    className="col-5 blc-section"
                                    style={{ marginLeft: "40px" }}>
                                    {charts.chartsDataAvailabe && (
                                        <ChartsCustom
                                            charts={charts.chartsData}
                                        />
                                    )}
                                </div> */}
                            </div>

                            <div className="row">
                                <div
                                    className="col-7 blc-sectionn  scrollVertical"
                                    style={{ zIndex: 5 }}>
                                    <WalletTransactions
                                        walletTransactions={walletTransactions}
                                    />
                                </div>
                                {/* <div
                                    className="col-5 blc-section"
                                    style={{ marginLeft: "40px" }}>
                                    <Container>
                                        <h6
                                            style={{
                                                color: "gray",
                                                marginBottom: "20px",
                                            }}>
                                            Quick Transfer
                                        </h6>
                                    </Container>

                                    <Row style={{ marginLeft: "20px" }}>
                                        <Col>
                                            <Button
                                                style={{
                                                    borderRadius: "15px",
                                                    border: "2px solid #6865F0",
                                                    backgroundColor: "white",
                                                    color: "black",
                                                }}>
                                                Via Mobile Number
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                style={{
                                                    borderRadius: "15px",
                                                    border: "2px solid #6865F0",
                                                    backgroundColor: "white",
                                                    color: "black",
                                                }}>
                                                Via Account Number
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={12}>
                                            <Container>
                                                <Row>
                                                    <Col
                                                        style={{
                                                            borderBottom:
                                                                "1px solid gray",
                                                        }}>
                                                        <div className="form__group field">
                                                            <img
                                                                src={flag}
                                                                alt="flag"
                                                                style={{
                                                                    marginRight:
                                                                        "10px",
                                                                }}
                                                            />
                                                            <span>+91 | </span>
                                                            <input
                                                                type="input"
                                                                className="form__field"
                                                                placeholder="Number"
                                                                name="number"
                                                                id="number"
                                                                required
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col lg={1}>
                                                        <div className="circle"></div>
                                                    </Col>
                                                </Row>

                                                <div
                                                    className="form__group field"
                                                    style={{
                                                        width: "95%",
                                                        borderBottom:
                                                            "1px solid gray",
                                                    }}>
                                                    <input
                                                        type="input"
                                                        className="form__field"
                                                        placeholder="Amount"
                                                        name="amount"
                                                        id="amount"
                                                        required
                                                    />
                                                </div>

                                                <div
                                                    className="send-button"
                                                    style={{
                                                        marginTop: "20px",
                                                        marginLeft: "80px",
                                                    }}>
                                                    <Button
                                                        style={{
                                                            width: "250px",
                                                            borderRadius:
                                                                "10px",
                                                            backgroundColor:
                                                                "#6865F0",
                                                        }}>
                                                        Send
                                                    </Button>
                                                </div>
                                            </Container>
                                        </Col>
                                    </Row>
                                </div> */}
                                {/* <div
                                    className="col-5 blc-section"
                                    style={{ marginLeft: "40px" }}>
                                    {charts.chartsDataAvailabe && (
                                        <ChartsCustom
                                            charts={charts.chartsData}
                                        />
                                    )}
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </>
    );
}

export default Dashboard;
