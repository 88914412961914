import React, { useState } from "react";
import Signup from "../components/Signup";
import Login from "../components/Login";
import { MdEmail } from "react-icons/md";
import { IoIosCall } from "react-icons/io";
function Navigation() {
    const [navbar, setNavbar] = useState(false);

    const [modalShow, setModalShow] = React.useState(false);
    const [modalShowLogin, setModalShowLogin] = React.useState(false);
    const reload = () => window.location.reload();

    const changeNavBgColor = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };
    window.addEventListener("scroll", changeNavBgColor);
    return (
        <>
            <header
                id="header"
                className={
                    navbar
                        ? "header fixed-top header-scrolled"
                        : "header fixed-top white-back"
                }>
                <div className="container-fluid d-flex align-items-center justify-content-between ">
                    <a href="#" className="logo logo-main-page  ">
                        <img src="assets/img/logo.png" alt="" />
                        {/* <span>Payville</span> */}
                    </a>

                    <nav id="navbar" className="navbar">
                        <ul>
                            <li className="d-flex align-items-center justify-content-between contact">
                                <MdEmail
                                    size={20}
                                    className="icon"
                                    color="goldenrod"
                                />
                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: "1rem ",
                                        marginLeft: "0.2rem",
                                        color: "goldenrod",
                                    }}>
                                    support@payville.in
                                </p>
                            </li>
                            <li className="d-flex align-items-center justify-content-between contact">
                                <IoIosCall
                                    size={20}
                                    className="icon"
                                    color="goldenrod"
                                />
                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: "1rem ",
                                        marginLeft: "0.2rem",
                                        color: "goldenrod",
                                    }}>
                                    9704406409
                                </p>
                            </li>
                            <li>
                                <a
                                    className="nav-link scrollto active"
                                    href="#">
                                    Home
                                </a>
                            </li>
                            <li>
                                <a
                                    className="nav-link scrollto"
                                    href="/services">
                                    Services
                                </a>
                            </li>
                            <li>
                                <a
                                    className="nav-link scrollto"
                                    href="/aboutus">
                                    About Us
                                </a>
                            </li>
                            <li>
                                <a
                                    className="nav-link scrollto"
                                    href="/contact">
                                    Contact Us
                                </a>
                            </li>
                            <li>
                                <a
                                    className="getstarted login scrollto"
                                    onClick={() => setModalShowLogin(true)}
                                    style={{ cursor: "pointer" }}>
                                    Login
                                </a>
                            </li>
                            <li>
                                <a
                                    className="getstarted register scrollto"
                                    onClick={() => setModalShow(true)}
                                    style={{ cursor: "pointer" }}>
                                    Register
                                </a>
                            </li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle"></i>
                    </nav>
                </div>
            </header>

            <Signup
                onLoginRedirect={() => {
                    console.log("re login");
                    setModalShow(false);
                    setModalShowLogin(true);
                }}
                show={modalShow}
                onHide={() => {
                    console.log("hi");
                    setModalShow(false);
                }}
                // onExiting={reload}
            />

            <Login
                onSignupRedirect={() => {
                    console.log("re signup");
                    setModalShowLogin(false);
                    setModalShow(true);
                }}
                show={modalShowLogin}
                onHide={() => setModalShowLogin(false)}
                // onExiting={reload}
            />
        </>
    );
}

export default Navigation;
