import React, { useState, useEffect, useRef } from "react";
import Moment from "react-moment";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import Razor from "./Razor";

import ReactS3Client from "react-aws-s3-typescript";
import { s3Config } from "./s3Config";

function Profile(props: any) {
    const navigate = useNavigate();
    const [paymentUrl, SetPaymentUrl] = useState<any>();

    const [walletBlc, setWalletBlc] = useState<any>(0);
    const emailref = useRef<HTMLInputElement | null>(null);
    const businessAddressref = useRef<HTMLTextAreaElement | null>(null);

    const inputFileA1: any = {};
    const inputFileA2: any = {};
    const inputFileA3: any = {};
    const inputFileA5: any = {};
    const [businessInsidePhotoURL, setBusinessInsidePhotoURL] =
        useState<string>("");
    const [businessOutsidePhotoURL, setBusinessOutsidePhotoURL] =
        useState<string>("");
    const [natureofBusiness, setNatureofBusiness] = useState<string>("");
    const [businessProofURL, setBusinessProofURL] = useState<string>("");
    const [authorizedPANCardURL, setAuthorizedPANCardURL] =
        useState<string>("");
    // const [monthlyBusinessCommitment, setMonthlyBusinessCommitment] =
    //     useState<string>("");

    const [businessInsidePhotoName, setBusinessInsidePhotoName] =
        useState<string>("");
    const [businessOutsidePhotoName, setBusinessOutsidePhotoName] =
        useState<string>("");
    const [businessProofName, setBusinessProofName] = useState<string>("");
    const [authorizedPANCardName, setAuthorizedPANCardName] =
        useState<string>("");

    const [oldPassword, setOldPassword] = useState<string>("");
    const [cPassword, setCPassword] = useState<string>("");
    const [ccPin, setCCPin] = useState<string>("");
    const [cPin, setCPin] = useState<string>("");
    const [otp, setOtp] = useState<string>("");
    const [sendOtp, setSendOtp] = useState<boolean>(false);

    const [ccPassword, setCCPassword] = useState<string>("");
    // const [walletTransactions, setWalletTransactions] = useState<any>();
    const userId: any = localStorage.getItem("userid");
    const [isProfile, setIsProfile] = useState<any>(1);
    const [account, setAccount] = useState("");

    const [userDetails, setUserDetails] = useState<any>();

    const location = useLocation();

    const value1 = location.search;

    useEffect(() => {
        const value = parseInt(value1.slice(1, 2));

        switch (value) {
            case 1:
                setIsProfile(1);
                setAccount("PROFILE");
                break;
            case 2:
                setIsProfile(2);
                setAccount("CHANGE PASSWORD");
                break;
            case 3:
                setIsProfile(3);
                setAccount("CHANGE PIN");
                break;
            case 4:
                setIsProfile(4);
                setAccount("BUSINESS INFORMATION");
                break;
            case 5:
                setIsProfile(5);
                setAccount("Payment Links");
                break;
            default:
                setIsProfile(1);
                setAccount("");
                break;
        }
    }, [value1]);
    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getUserDetailsByID(parseInt(userId), config)
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        console.log(response);
                        setUserDetails(response?.data?.UserDetails);
                        SetPaymentUrl(
                            `https://www.payville.in/payment/${response?.data?.UserDetails?.paymentPageId}`
                        );

                        setBusinessInsidePhotoURL(
                            response?.data?.UserDetails?.businessInsidePhotoURL
                        );
                        setBusinessOutsidePhotoURL(
                            response?.data?.UserDetails?.businessOutsidePhotoURL
                        );
                        setNatureofBusiness(
                            response?.data?.UserDetails?.natureofBusiness
                        );
                        setBusinessProofURL(
                            response?.data?.UserDetails?.businessProofURL
                        );
                        setAuthorizedPANCardURL(
                            response?.data?.UserDetails?.authorizedPANCardURL
                        );
                    } else {
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
            // LoginServices.getWalletTransactions(parseInt(userId), config)
            //     .then((response: any) => {
            //         if (response.data.Status == 1) {
            //             setWalletTransactions(
            //                 response?.data?.WalletTransactions
            //             );
            //             // setWalletBlc(response?.data?.walletBalance)
            //         } else {
            //         }
            //     })
            //     .catch((e: Error) => {
            //         console.log(e);
            //     });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [""]);
    let changePassword = () => {
        if (cPassword.length < 8) {
            alert("Password should be minimum 8 chars");
            return;
        }
        if (cPassword !== ccPassword) {
            alert("Password not matching");
            return;
        }
        const payload = {
            oldpassword: oldPassword,
            password: cPassword,
        };
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        LoginServices.updatePassword(parseInt(userId), payload, config)
            .then((response: any) => {
                if (response.data.Status == 1) {
                    window.location.reload();
                } else {
                    alert(response.data.Message);
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };
    let changePin = () => {
        if (cPin.length < 4) {
            alert("Password should be minimum 4 chars");
            return;
        }
        if (cPin !== ccPin) {
            alert("Password not matching");
            return;
        } else if (cPin == ccPin) {
            const payload = {
                mobileNumber: "",
            };

            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };

            LoginServices.sendOtpForPinChange(parseInt(userId), payload, config)
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        setSendOtp(true);
                    } else {
                        alert(response.data.Message);
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }

        if (sendOtp && otp == "") {
            alert("Please enter the otp");
            return;
        } else if (sendOtp && otp != "") {
            const payload = {
                PIN: cPin,
                OTP: otp,
            };
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.updatePin(parseInt(userId), payload, config)
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        alert(response.data.Message);
                        window.location.reload();
                    } else {
                        alert(response.data.Message);
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    };

    let updateUser = () => {
        const payload = {
            emailId: emailref?.current?.value,
        };
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        LoginServices.updateUserDetails(parseInt(userId), payload, config)
            .then((response: any) => {
                if (response.data.Status == 1) {
                    // console.log(response);
                    window.location.reload();
                } else {
                    alert(response.data.Message);
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    let updateBusinessUser = () => {
        const payload: any = {
            businessAddress: businessAddressref?.current?.value,
            natureofBusiness: natureofBusiness,
            monthlyBusinessCommitment: userDetails?.monthlyBusinessCommitment,
        };
        if (businessInsidePhotoName.length) {
            payload.businessInsidePhotoURL = businessInsidePhotoName;
        }
        if (businessOutsidePhotoName.length) {
            payload.businessOutsidePhotoURL = businessOutsidePhotoName;
        }
        if (businessProofName.length) {
            payload.businessProofURL = businessProofName;
        }
        if (authorizedPANCardName.length) {
            payload.authorizedPANCardURL = authorizedPANCardName;
        }
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        LoginServices.updateUserDetails(parseInt(userId), payload, config)
            .then((response: any) => {
                if (response.data.Status == 1) {
                    alert(response.data.Message);
                    window.location.reload();
                } else {
                    alert(response.data.Message);
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    let handlleUploads = async (e: any, rCode: string) => {
        if (!userId) {
            return;
        }
        const file = e.target.files[0];

        const { name } = e.target;
        e.persist();
        // const tempFileName = file.name.replace(/\s/g, "");
        const fileFormat = file.name.split(".").pop();

        const fileNameS3 = (userId + "_" + new Date().getTime() / 1000)
            .split(".")
            .join("");
        const fileName =
            (userId + "_" + new Date().getTime() / 1000).split(".").join("") +
            "." +
            fileFormat;

        const myNewFile = new File([file], fileNameS3, { type: file.type });
        console.log(myNewFile);

        const s3 = new ReactS3Client(s3Config);
        await s3
            .uploadFile(myNewFile, fileNameS3)
            .then((data) => {
                let url = data.location;
                if (rCode == "a1") {
                    setBusinessInsidePhotoURL(url);
                    setBusinessInsidePhotoName(fileName);
                }
                if (rCode == "a2") {
                    setBusinessOutsidePhotoURL(url);
                    setBusinessOutsidePhotoName(fileName);
                }
                if (rCode == "a3") {
                    setAuthorizedPANCardURL(url);
                    setAuthorizedPANCardName(fileName);
                }
                if (rCode == "a5") {
                    setBusinessProofURL(url);
                    setBusinessProofName(fileName);
                }

                // setChallanImage(url);
                // let imgName = data.location.split("/");

                // setDetails({
                //     ...details,
                //     ...{ [name]: imgName[imgName.length - 1] }
                // })
            })
            .catch((err) => console.error(err));
    };
    const newtab = (url: any) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };
    return (
        <>
            <div className="row main-row">
                <div className="col-10 right-back">
                    <WalletBlcHeader wBlc={walletBlc}></WalletBlcHeader>
                    <p className="account-head">{account}</p>

                    <div className="account-box-main">
                        {isProfile == 1 && (
                            <div className="account-box-main2">
                                <div className="profile-details">
                                    <div className="user-avatar-div">
                                        <div>
                                            <img
                                                src="assets/img/user-avatar1.png"
                                                alt=""
                                            />
                                            <p className="user-name-account">
                                                {userDetails?.firstName}
                                            </p>
                                            <p className="user-ref-account">
                                                Referral Code :{" "}
                                                <span>
                                                    {
                                                        userDetails?.myReferralCode
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="user-details-ips">
                                        <div className="ip-inr-main-profile">
                                            <img
                                                className="ip-email-p"
                                                src="assets/img/email.png"
                                                alt=""
                                            />

                                            <input
                                                ref={emailref}
                                                defaultValue={
                                                    userDetails?.emailId
                                                }
                                                className="email-ip-p"
                                                type="mail"
                                                placeholder="Enter Email"
                                            />
                                        </div>
                                        <div
                                            className="ip-inr-main-profile"
                                            style={{ marginTop: "-10px" }}>
                                            <img
                                                className="ip-mobile-p"
                                                src="assets/img/mobile.png"
                                                alt=""
                                            />
                                            {/* onChange={(e: any) => setAddBlc(e.target.value)} */}
                                            <input
                                                value={
                                                    userDetails?.mobileNumber
                                                }
                                                disabled
                                                className="email-ip-p"
                                                type="text"
                                                placeholder="Enter Mobile Number"
                                            />
                                        </div>
                                        <div
                                            className="d-flex"
                                            style={{ marginTop: "20px" }}>
                                            <button
                                                onClick={updateUser}
                                                className="add-biller-btn">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isProfile == 2 && (
                            <div className="account-box-main3">
                                <div className="profile-details">
                                    <div className="user-details-ips">
                                        <div className="ip-inr-main-profile">
                                            <img
                                                className="ip-password-img"
                                                src="assets/img/lock.png"
                                                alt=""
                                            />
                                            <input
                                                onChange={(e: any) =>
                                                    setOldPassword(
                                                        e.target.value
                                                    )
                                                }
                                                className="password-ip-p"
                                                type="password"
                                                placeholder="Enter Old Password"
                                            />
                                        </div>

                                        <div
                                            className="ip-inr-main-profile"
                                            style={{ marginTop: "-10px" }}>
                                            <img
                                                className="ip-password-img"
                                                src="assets/img/lock.png"
                                                alt=""
                                            />
                                            <input
                                                onChange={(e: any) =>
                                                    setCPassword(e.target.value)
                                                }
                                                className="password-ip-p"
                                                type="password"
                                                placeholder="Enter New Password"
                                            />
                                        </div>
                                        <div
                                            className="ip-inr-main-profile"
                                            style={{ marginTop: "-10px" }}>
                                            <img
                                                className="ip-password-img"
                                                src="assets/img/lock.png"
                                                alt=""
                                            />
                                            <input
                                                onChange={(e: any) =>
                                                    setCCPassword(
                                                        e.target.value
                                                    )
                                                }
                                                className="password-ip-p"
                                                type="password"
                                                placeholder="Confirm Password"
                                            />
                                        </div>
                                        <div
                                            className="d-flex"
                                            style={{ marginTop: "20px" }}>
                                            <button
                                                onClick={changePassword}
                                                className="add-biller-btn">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {isProfile == 3 && (
                            <div className="account-box-main3">
                                <div className="profile-details">
                                    <div className="user-details-ips">
                                        {
                                            <>
                                                <div className="ip-inr-main-profile">
                                                    <img
                                                        className="ip-password-img"
                                                        src="assets/img/lock.png"
                                                        alt=""
                                                    />
                                                    <input
                                                        onChange={(e: any) =>
                                                            setCPin(
                                                                e.target.value
                                                            )
                                                        }
                                                        disabled={
                                                            sendOtp
                                                                ? true
                                                                : false
                                                        }
                                                        maxLength={4}
                                                        className="password-ip-p"
                                                        type="password"
                                                        placeholder="Enter New Pin"
                                                    />
                                                </div>
                                                <div
                                                    className="ip-inr-main-profile"
                                                    style={{
                                                        marginTop: "-10px",
                                                    }}>
                                                    <img
                                                        className="ip-password-img"
                                                        src="assets/img/lock.png"
                                                        alt=""
                                                    />
                                                    <input
                                                        onChange={(e: any) =>
                                                            setCCPin(
                                                                e.target.value
                                                            )
                                                        }
                                                        disabled={
                                                            sendOtp
                                                                ? true
                                                                : false
                                                        }
                                                        maxLength={4}
                                                        className="password-ip-p"
                                                        type="password"
                                                        placeholder="Confirm New Pin"
                                                    />
                                                </div>
                                            </>
                                        }
                                        {sendOtp && (
                                            <div
                                                className="ip-inr-main-profile"
                                                style={{ marginTop: "-10px" }}>
                                                <img
                                                    className="ip-password-img"
                                                    src="assets/img/lock.png"
                                                    alt=""
                                                />
                                                <input
                                                    onChange={(e: any) =>
                                                        setOtp(e.target.value)
                                                    }
                                                    className="password-ip-p"
                                                    type="password"
                                                    placeholder="Enter OTP"
                                                />
                                            </div>
                                        )}

                                        <div
                                            className="d-flex"
                                            style={{ marginTop: "20px" }}>
                                            <button
                                                onClick={changePin}
                                                className="add-biller-btn">
                                                {sendOtp ? "Save" : "Send OTP"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isProfile == 4 && (
                            <div className="account-box-main2">
                                <div className="profile-details">
                                    {userDetails?.isBusinessProfileApproved ==
                                        1 && (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                            }}>
                                            <p
                                                style={{
                                                    fontSize: "1rem",
                                                    color: "white",
                                                    backgroundColor: "green",
                                                    padding: "0.6rem 1rem",
                                                    width: "fit-content",
                                                    borderRadius: "10px",
                                                }}>
                                                Approved
                                            </p>
                                        </div>
                                    )}
                                    <div className="user-details-ips1">
                                        <div className="ip-inr-main-profile1">
                                            <label>Address:</label>
                                            <textarea
                                                ref={businessAddressref}
                                                defaultValue={
                                                    userDetails?.businessAddress
                                                }
                                                className="email-ta-p1"
                                                placeholder="Enter Address"
                                            />
                                        </div>
                                        <div className="ip-inr-main-profile1">
                                            <label>Nature of Business:</label>
                                            <input
                                                onChange={(e: any) =>
                                                    setNatureofBusiness(
                                                        e.target.value
                                                    )
                                                }
                                                value={natureofBusiness}
                                                className="email-ip-p1"
                                                type="text"
                                                placeholder="Enter Nature of Business"
                                            />
                                        </div>
                                        <div className="uploaded-doc-4">
                                            {userDetails?.businessProofURL
                                                ?.length != 0 &&
                                                userDetails?.businessProofURL !=
                                                    "NA" && (
                                                    <div>
                                                        <button
                                                            onClick={() => {
                                                                newtab(
                                                                    userDetails?.businessProofURL
                                                                );
                                                            }}>
                                                            business Proof
                                                        </button>
                                                    </div>
                                                )}
                                            {userDetails?.businessInsidePhotoURL
                                                ?.length != 0 &&
                                                userDetails?.businessInsidePhotoURL !=
                                                    "NA" && (
                                                    <div>
                                                        <button
                                                            onClick={() => {
                                                                newtab(
                                                                    userDetails?.businessInsidePhotoURL
                                                                );
                                                            }}>
                                                            business inside
                                                            Photo
                                                        </button>
                                                        {/* <p>business inside Photo</p> */}
                                                    </div>
                                                )}
                                            {userDetails
                                                ?.businessOutsidePhotoURL
                                                ?.length != 0 &&
                                                userDetails?.businessOutsidePhotoURL !=
                                                    "NA" && (
                                                    <div>
                                                        <button
                                                            onClick={() => {
                                                                newtab(
                                                                    userDetails?.businessOutsidePhotoURL
                                                                );
                                                            }}>
                                                            {" "}
                                                            business Outside
                                                            Photo
                                                        </button>
                                                    </div>
                                                )}
                                            {userDetails?.authorizedPANCardURL
                                                ?.length != 0 &&
                                                userDetails?.authorizedPANCardURL !=
                                                    "NA" && (
                                                    <div>
                                                        <button
                                                            onClick={() => {
                                                                newtab(
                                                                    userDetails?.authorizedPANCardURL
                                                                );
                                                            }}>
                                                            authorized pancard
                                                        </button>
                                                        {/* <p>authorized PANCard</p> */}
                                                    </div>
                                                )}
                                        </div>
                                        {/* <div className="ip-inr-main-profile1">
                                            <label>Monthly Business Commitment:</label>
                                            <input onChange={(e: any) => setMonthlyBusinessCommitment(e.target.value)}
                                                value={monthlyBusinessCommitment} className="email-ip-p1"
                                                type="text" placeholder="Enter Nature of Business" />
                                        </div> */}
                                        {userDetails?.isBusinessProfileApproved ==
                                            0 && (
                                            <div>
                                                <label className="upload-docs-label">
                                                    Upload Documents
                                                </label>
                                                <input
                                                    onChange={(e) => {
                                                        handlleUploads(e, "a5");
                                                    }}
                                                    type="file"
                                                    id="file"
                                                    ref={inputFileA5}
                                                    style={{ display: "none" }}
                                                />
                                                <div
                                                    style={{
                                                        paddingLeft: "5px",
                                                    }}>
                                                    <button
                                                        onClick={() => {
                                                            inputFileA5.current.click();
                                                        }}
                                                        className="btn btn-primary btn-sm m-1">
                                                        Business Proof
                                                    </button>
                                                    {businessProofURL?.length !=
                                                        0 &&
                                                        businessProofURL !=
                                                            "NA" && (
                                                            <div>
                                                                <a
                                                                    className="doc-link-blue"
                                                                    href={
                                                                        businessProofURL
                                                                    }
                                                                    target="_blank">
                                                                    {
                                                                        businessProofURL
                                                                    }
                                                                </a>
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        )}
                                        {userDetails?.isBusinessProfileApproved ==
                                            0 && (
                                            <div>
                                                <label className="upload-docs-label">
                                                    Upload Photos
                                                </label>
                                                <input
                                                    onChange={(e) => {
                                                        handlleUploads(e, "a1");
                                                    }}
                                                    accept="image/png, image/gif, image/jpeg"
                                                    type="file"
                                                    id="file"
                                                    ref={inputFileA1}
                                                    style={{ display: "none" }}
                                                />
                                                <input
                                                    onChange={(e) => {
                                                        handlleUploads(e, "a2");
                                                    }}
                                                    accept="image/png, image/gif, image/jpeg"
                                                    type="file"
                                                    id="file"
                                                    ref={inputFileA2}
                                                    style={{ display: "none" }}
                                                />
                                                <input
                                                    onChange={(e) => {
                                                        handlleUploads(e, "a3");
                                                    }}
                                                    accept="image/png, image/gif, image/jpeg"
                                                    type="file"
                                                    id="file"
                                                    ref={inputFileA3}
                                                    style={{ display: "none" }}
                                                />
                                                <div
                                                    style={{
                                                        paddingLeft: "5px",
                                                    }}>
                                                    <button
                                                        onClick={() => {
                                                            inputFileA1.current.click();
                                                        }}
                                                        className="btn btn-primary btn-sm m-1">
                                                        Business Inside
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            inputFileA2.current.click();
                                                        }}
                                                        className="btn btn-primary btn-sm m-1">
                                                        Business Outside
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            inputFileA3.current.click();
                                                        }}
                                                        className="btn btn-primary btn-sm m-1">
                                                        Pancard
                                                    </button>
                                                </div>
                                                <div
                                                    style={{
                                                        paddingLeft: "5px",
                                                        display: "flex",
                                                    }}>
                                                    {businessInsidePhotoURL?.length !=
                                                        0 &&
                                                        businessInsidePhotoURL !=
                                                            "NA" && (
                                                            <div>
                                                                <img
                                                                    className="upload-img-png"
                                                                    src={
                                                                        businessInsidePhotoURL
                                                                    }
                                                                />
                                                                <p>
                                                                    Inside Photo
                                                                </p>
                                                            </div>
                                                        )}
                                                    {businessOutsidePhotoURL?.length !=
                                                        0 &&
                                                        businessOutsidePhotoURL !=
                                                            "NA" && (
                                                            <div>
                                                                <img
                                                                    className="upload-img-png"
                                                                    src={
                                                                        businessOutsidePhotoURL
                                                                    }
                                                                />
                                                                <p>
                                                                    Outside
                                                                    Photo
                                                                </p>
                                                            </div>
                                                        )}
                                                    {authorizedPANCardURL?.length !=
                                                        0 &&
                                                        authorizedPANCardURL !=
                                                            "NA" && (
                                                            <div>
                                                                <img
                                                                    className="upload-img-png"
                                                                    src={
                                                                        authorizedPANCardURL
                                                                    }
                                                                />
                                                                <p>Pancard</p>
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        )}
                                        {userDetails?.isBusinessProfileApproved ==
                                            0 && (
                                            <div
                                                className="d-flex"
                                                style={{ marginTop: "20px" }}>
                                                <button
                                                    onClick={updateBusinessUser}
                                                    className="add-biller-btn">
                                                    Save
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {isProfile == 5 && (
                            <div className="account-box-main3">
                                <div
                                    className="profile-details "
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "1rem",
                                    }}>
                                    <p>{paymentUrl}</p>
                                    <button
                                        style={{
                                            margin: "0 0 1rem 0",
                                            padding: " 0.5rem 1rem",
                                            border: "none",
                                            background: "#6865f0",
                                            fontSize: "16px",
                                            fontFamily: "Rubik",
                                            color: "white",
                                            borderRadius: "6px",
                                        }}
                                        onClick={() =>
                                            navigator.clipboard.writeText(
                                                `${paymentUrl}`
                                            )
                                        }>
                                        Copy
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Profile;
