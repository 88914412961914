import React from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import image1 from "../../assets/bbps/wifi-signal 1.png";
import image2 from "../../assets/bbps/smart-tv 1.png";
import image3 from "../../assets/bbps/leadership 1.png";
import image4 from "../../assets/bbps/credit-card (2) 1.png";
import image5 from "../../assets/bbps/satellite-dish 1.png";
import image6 from "../../assets/bbps/fees 1.png";
import image7 from "../../assets/bbps/electricity 2.png";
import image8 from "../../assets/bbps/fasttag.png";
import image9 from "../../assets/bbps/gas-tank 2.png";
import image10 from "../../assets/bbps/healthcare (2) 1.png";
import image11 from "../../assets/bbps/clinic 1.png";
import image12 from "../../assets/bbps/bulding 1.png";
import image13 from "../../assets/bbps/landline 1.png";
import image14 from "../../assets/bbps/salary 1.png";
import image15 from "../../assets/bbps/liquefied-petroleum-gas 1.png";
import image16 from "../../assets/bbps/subscription 1.png";
import image17 from "../../assets/bbps/Vector (1).png";
import image18 from "../../assets/bbps/booking (1) 1.png";
import image19 from "../../assets/bbps/city-hall 1.png";
import image20 from "../../assets/bbps/bag 1.png";
import image21 from "../../assets/bbps/bharat_pay.png";
import "./Bbps.css";
import Navbar from "./Bbpsnav";
import { useNavigate } from "react-router-dom";

export default function Bbps() {
    const Navigate = useNavigate();
    const BillpayHandler = (e: any) => {
        if (e.currentTarget.id == "Insurance") {
            Navigate("/dashboard/bbps/insurance");
            return;
        }
        Navigate(`/dashboard/bbps/Biller/${e.currentTarget.id}`);
    };
    return (
        <div className="row main-row">
            <div className="col-10 dashboard-right-back">
                <div className="header-bbps">
                    <div className="BbpsWrapper">
                        <div className="Bbps-heading">
                            <p>BBPS</p>
                            <img src={image21} alt="" />
                        </div>
                        <div className="Bbps-Navbar">
                            <Navbar active="billpay" />
                        </div>
                        <div className="paymentType-wrapper">
                            {/*  <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="Broadband Postpaid">
                                <img
                                    className="paymentType_icon"
                                    src={image1}
                                    alt=""
                                />
                                <h2>
                                    Broadband <br /> Postpaid{" "}
                                </h2>
                            </button> */}
                            {/* <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="Cable Tv">
                                <img
                                    className="paymentType_icon"
                                    src={image2}
                                    alt=""
                                />
                                <h2>Cable TV </h2>
                            </button>
                            <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id=" Clubs and Associations">
                                <img
                                    className="paymentType_icon"
                                    src={image3}
                                    alt=""
                                />
                                <h2>
                                    Clubs and <br /> Associations
                                </h2>
                            </button>
                            <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="Credit Card">
                                <img
                                    className="paymentType_icon"
                                    src={image4}
                                    alt=""
                                />
                                <h2>Credit Card</h2>
                            </button>
                            <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="DTH">
                                <img
                                    className="paymentType_icon"
                                    src={image5}
                                    alt=""
                                />
                                <h2>DTH</h2>
                            </button>

                            <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="Education Fees">
                                <img
                                    className="paymentType_icon"
                                    src={image6}
                                    alt=""
                                />
                                <h2>Education Fees</h2>
                            </button> */}
                            <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="Electricity">
                                <img
                                    className="paymentType_icon"
                                    src={image7}
                                    alt=""
                                />
                                <h2>Electricity</h2>
                            </button>
                            {/* <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="Fastag">
                                <img
                                    className="paymentType_icon"
                                    src={image8}
                                    alt=""
                                />
                                <h2>Fastag</h2>
                            </button> */}
                            {/*   <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="Gas">
                                <img
                                    className="paymentType_icon"
                                    src={image9}
                                    alt=""
                                />
                                <h2>Gas</h2>
                            </button> */}
                            {/* <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="Insurance">
                                <img
                                    className="paymentType_icon"
                                    src={image10}
                                    alt=""
                                />
                                <h2>Insurance</h2>
                            </button>
                            <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id=" Hospital and Pathology">
                                <img
                                    className="paymentType_icon"
                                    src={image11}
                                    alt=""
                                />
                                <h2>
                                    {" "}
                                    Hospital and <br />
                                    Pathology
                                </h2>
                            </button>
                            <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="Housing Society">
                                <img
                                    className="paymentType_icon"
                                    src={image12}
                                    alt=""
                                />
                                <h2>Housing Society</h2>
                            </button> */}
                            {/*    <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="Landline Postpaid">
                                <img
                                    className="paymentType_icon"
                                    src={image13}
                                    alt=""
                                />
                                <h2>Landline Postpaid</h2>
                            </button> */}
                            {/* <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="Loan Repayment">
                                <img
                                    className="paymentType_icon"
                                    src={image14}
                                    alt=""
                                />
                                <h2>Loan Repayment</h2>
                            </button> */}
                            {/* <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="LPG Gas">
                                <img
                                    className="paymentType_icon"
                                    src={image15}
                                    alt=""
                                />
                                <h2>LPG Gas</h2>
                            </button> */}
                            {/* <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="Subscription">
                                <img
                                    className="paymentType_icon"
                                    src={image16}
                                    alt=""
                                />
                                <h2>Subscription</h2>
                            </button> */}
                            <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="Water">
                                <img
                                    className="paymentType_icon"
                                    src={image17}
                                    alt=""
                                />
                                <h2>Water</h2>
                            </button>
                            {/* <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="Mobile Postpaid">
                                <img
                                    className="paymentType_icon"
                                    src={image18}
                                    alt=""
                                />
                                <h2>Mobile Postpaid</h2>
                            </button> */}
                            {/* <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="Municipal Services">
                                <img
                                    className="paymentType_icon"
                                    src={image19}
                                    alt=""
                                />
                                <h2>Municipal Services</h2>
                            </button>
                            <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="Municipal Taxes">
                                <img
                                    className="paymentType_icon"
                                    src={image20}
                                    alt=""
                                />
                                <h2>Municipal Taxes</h2>
                            </button> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
