import React from "react";
import Navigation from "./Navigation";
import { Row, Col, Container, Button, Card } from "react-bootstrap";
import "./css/About.css";
import image1 from "../assets/aboutus/Rectangle 1682.png";
import image2 from "../assets/aboutus/Ellipse 138.svg";
import image3 from "../assets/aboutus/Group 190.svg";
import image4 from "../assets/aboutus/clock (2) 1.png";
import image5 from "../assets/aboutus/Group.png";
import image6 from "../assets/aboutus/click (1) 1.png";
import Footer from "./Footer";
import bgpink from "../assets/aboutus/Ellipse 40.png";

const Items = (props) => {
    return (
        <Card className="aboutus-items">
            <Card.Body>
                <Container>
                    <div className="card-content text-center">
                        <img
                            src={props.image}
                            style={{ height: "50px" }}
                            className="aboutus-item-icons"
                            alt="image3"
                        />

                        <h4 className="card-title">{props.title}</h4>
                        <p className="card-des">{props.content}</p>
                    </div>
                </Container>
            </Card.Body>
        </Card>
    );
};

function About() {
    const items = [
        {
            image: image4,
            title: "Money in minute",
            content: "We provide instant payments to most of our services",
        },
        {
            image: image5,
            title: "Variety of services",
            content:
                "We provide various services like bill payments, housing society fees etc.",
        },
        {
            image: image6,
            title: "More convenience",
            content: "Make payments using our Mobile app or Website",
        },
    ];

    return (
        <div className="about-us">
            <Navigation />

            <section className="section1">
                <Container>
                    <Row>
                        <Col>
                            <div className="aboutus-heading">
                                <h1 className="heading">About us</h1>
                                <p className="description">
                                    At Credit Mint Private Limited , We help you
                                    make any payments easy, simple and
                                    affordable. Our digital platform is built
                                    for any business and every customer journey.
                                    You can pay all your expenses with nominal
                                    charges!
                                </p>
                            </div>
                        </Col>
                        <img src={bgpink} className="bg-image" alt="bg-image" />
                        <Col>
                            <div className="ourservice-image">
                                <img
                                    src={image1}
                                    className="main-image"
                                    alt="main-image"
                                />
                                <img
                                    src={image2}
                                    className="circle-infront"
                                    alt="circle"
                                />
                                <img
                                    src={image2}
                                    className="circle-behind"
                                    alt="circle"
                                />
                                <img
                                    src={image3}
                                    className="pattern-behind"
                                    alt="pattern"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section
                className="section2-aboutus"
                style={{ zIndex: "10", backgroundColor: "white" }}>
                <div
                    style={{
                        textAlign: "center",
                        marginLeft: "25vw",
                        width: "800px",
                        marginTop: "50px",
                    }}>
                    <h1 className="heading">Why Choose Payville</h1>
                    <p className="description">
                        We help you make any payments easy, simple and
                        affordable. Our digital platform is built for any
                        business and every customer journey. You can pay all
                        your expenses with nominal charges!
                    </p>
                </div>
                <Container>
                    <Row
                        style={{
                            marginTop: "70px",
                            marginBottom: "150px",
                            // marginLeft: "50px",
                        }}>
                        <Col style={{ marginTop: "130px" }}>
                            <Items
                                image={items[0].image}
                                title={items[0].title}
                                content={items[0].content}
                            />
                        </Col>
                        <Col>
                            <Items
                                image={items[1].image}
                                title={items[1].title}
                                content={items[1].content}
                            />
                        </Col>
                        <Col style={{ marginTop: "130px" }}>
                            <Items
                                image={items[2].image}
                                title={items[2].title}
                                content={items[2].content}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>

            <Footer curve={true} />
        </div>
    );
}

export default About;
