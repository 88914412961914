import React from "react";
import { Link } from "react-router-dom";

function Footer(props) {
    return (
        <>
            {/* 
    <!-- ======= Footer ======= --> */}
            <footer id="footer" className="footer">
                <div className="footer-top">
                    {props.curve ? "" : <div className="footer-bg"></div>}

                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-4 col-md-12 footer-info logo-payville">
                                <a
                                    href="index.html"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "0.3rem",
                                    }}>
                                    <img
                                        src="assets/img/payville.svg"
                                        alt=""
                                        style={{ margin: 0 }}
                                    />
                                    <span
                                        style={{
                                            color: "white",
                                            fontSize: "1.4rem",
                                        }}>
                                        PayVille
                                    </span>
                                </a>
                                <p
                                    style={{
                                        // paddingLeft: 20,
                                        marginTop: "1rem",
                                        paddingRight: 80,
                                    }}>
                                    PayVille is a fintech platform by Credit
                                    Mint Pvt Ltd. Easy to make Bill Payments
                                </p>
                                <div className="social-links mt-3">
                                    {/* <a href="#" className="twitter">
                                        <img
                                            src="assets/img/home/twitter.png"
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </a> */}
                                    <a
                                        href="https://www.facebook.com/payvilleservice"
                                        className="facebook"
                                        target={"_blank"}>
                                        <img
                                            src="assets/img/home/fb.png"
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </a>
                                    {/* <a href="#" className="instagram">
                                        <img
                                            src="assets/img/home/instagram.png"
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </a>
                                    <a href="#" className="linkedin">
                                        <img
                                            src="assets/img/home/youtube.png"
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </a> */}
                                </div>
                            </div>

                            <div className="col-lg-2 col-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li>
                                        <i className="bi bi-chevron-right"></i>{" "}
                                        <Link to="/privacypolicy">
                                            Privacy Policy
                                        </Link>
                                    </li>
                                    <li>
                                        <i className="bi bi-chevron-right"></i>{" "}
                                        <Link to="/termsandconditions">
                                            Terms &amp; Conditions
                                        </Link>
                                    </li>
                                    <li>
                                        {" "}
                                        <Link to="/refundPolicy">
                                            Cancellation &amp; Refund Policy
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            {/* <div className="col-lg-4 col-md-12 footer-contact text-center text-md-start">
                                <h4 style={{ marginLeft: "1rem" }}>Download</h4>
                                <a
                                    href="#about"
                                    className="btn-gpay scrollto d-inline-flex align-items-center justify-content-center align-self-center "
                                    style={{ marginLeft: "1rem" }}>
                                    <span>
                                        {" "}
                                        <a
                                            href="https://play.google.com/store/apps/details?id=com.app.payville"
                                            target="_blank"
                                            rel="noreferrer">
                                            <img src="assets/img/home/gpay.png" />
                                        </a>
                                    </span>
                                    <i className="bi bi-arrow-right"></i>
                                </a>
                                <a
                                    href="#about"
                                    className="btn-gpay scrollto d-inline-flex align-items-center justify-content-center align-self-center "
                                    style={{ marginLeft: "1rem" }}>
                                    <span>
                                        {" "}
                                        <a
                                            href="https://apps.apple.com/in/app/payville-by-credit-mint/id1608770809"
                                            target="_blank"
                                            rel="noreferrer">
                                            <img src="assets/img/footer-appstore.svg" />
                                        </a>
                                    </span>
                                    <i className="bi bi-arrow-right"></i>
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="container" style={{ backgroundColor: "black" }}>
                    <div className="copyright">
                        Copyright © 2021 Credit Mint Pvt Ltd All Rights Reserved
                    </div>
                </div>
            </footer>

            <a
                href="#"
                className="back-to-top d-flex align-items-center justify-content-center">
                <i className="bi bi-arrow-up-short"></i>
            </a>
        </>
    );
}

export default Footer;
