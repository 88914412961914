import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import Razor from "./Razor";
import { Modal, Row, Col } from "react-bootstrap";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { BounceLoader } from "react-spinners";
import OtpInput from "react-otp-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Fuse from "fuse.js";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#6865F0";
`;

function WalletTransfer() {
    let [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [refreshP, setRefreshP] = useState<any>("");
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm();
    const [pinValue, setpinValue] = useState("");
    const [walletBlc, setWalletBlc] = useState<any>(0);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentPayee, setCurrentPayee] = useState<any>();
    const [userDetails, setUserDetails] = useState<String>();
    const [payAmount, setPayAmount] = useState<any>([]);
    const [payNotes, setPayNotes] = useState<any>();

    const [addBlc, setAddBlc] = useState<number>();
    const userId = localStorage.getItem("userid");
    const [payoutList, setPayoutList] = useState<any>();
    const [resPayoutList, setResPayoutList] = useState<any>();
    const [filterPayoutlist, setFilterPayoutlist] = useState<any>();
    const [showSection, setShowSection] = useState<string>("");
    const [searchItem, setSearchItem] = useState<string>("");
    const [usersList, setUsersList] = useState<any>();
    const componentMounted = useRef(true);
    const localSearch = (searchItem: string) => {
        if (searchItem?.length == 0) {
            setRefreshP("yes");
            return;
        }
        const fuse = new Fuse(payoutList, {
            keys: ["firstName", "mobileNumber"],
        });
        const results = fuse.search(searchItem);
        const characterResults = results.map((character) => character.item);
        // console.log(characterResults);
        setResPayoutList(characterResults);
    };
    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    // console.log(response);

                    if (response.data.Status == 1 && componentMounted.current) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                    }

                    // console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
            LoginServices.getWalletPayoutContacts(parseInt(userId), config)
                .then((response: any) => {
                    // console.log(response);
                    if (response.data.Status == 1 && componentMounted.current) {
                        setPayoutList(response?.data?.WalletContacts);
                        setFilterPayoutlist(response?.data?.WalletContacts);
                        setResPayoutList(response?.data?.WalletContacts);

                        if (response?.data?.WalletContacts?.length == 0) {
                            setShowSection("add-img");
                        } else {
                            setShowSection("biller-list");
                        }
                    } else {
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                    setShowSection("add-img");
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
        return () => {
            // This code runs when component is unmounted
            componentMounted.current = false; // (4) set it to false when we leave the page
        };
    }, [refreshP == "yes"]);
    // showSection == 'biller-list'
    function isNumber(n: any) {
        return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    }
    const searchUser = () => {
        // console.log(searchItem);
        if (userId) {
            const payload: any = {};
            if (isNumber(searchItem)) {
                payload.mobileNumber = searchItem;
            } else {
                payload.name = searchItem;
            }
            // console.log(payload);
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };

            LoginServices.searchUserByNameOrMobile(payload, config)
                .then((response: any) => {
                    // console.log(response);
                    if (response.data.Status == 1) {
                        // window.location.reload();
                        setUsersList(response?.data?.WalletContacts);
                    } else {
                        alert(response.data.Message);
                    }

                    // console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    };

    const addWalletContact = () => {
        // console.log(userDetails);
        // if(userDetails)
        if (!userDetails?.length) {
            alert("Payee User id not found");
            return;
        }
        if (userDetails == userId) {
            alert("You cann't add your self as payer");
            return;
        }
        const payload = {
            userId: userId,
            contact_userId: userDetails,
        };
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };

        LoginServices.createWalletPayContact(payload, config)
            .then((response: any) => {
                // console.log(response);
                if (response.data.Status == 1) {
                    alert(response.data.Message);
                    setShowSection("biller-list");
                    setRefreshP("yes");
                    reset();
                } else {
                    alert(response.data.Message);
                }
                // console.log(response.data);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const sendPayment = (item: any) => {
        // console.log(item);
        let confirmPayment = false;
        if (payAmount.length == 0) {
            alert("Amount is mandatory");
            return;
        }
        if (!isNumber(payAmount)) {
            alert("amount is invalid");
            return;
        }
        if (payAmount > walletBlc) {
            alert("Pay amount is more than your wallet balance");
            return;
        }
        if (payAmount > 200000) {
            alert("Pay amount is more than 2,00,000Rs");
            return;
        }

        if (pinValue.length == 0) {
            alert("PIN is mandatory");
            return;
        }
        if (!isNumber(pinValue)) {
            alert("PIN invalid");
            return;
        }
        if (payAmount) {
            if (window.confirm("Are You Sure You Want To Proceed") == true) {
                confirmPayment = true;
            }
        }
        if (confirmPayment) {
            const payload = {
                contact_userId: item?.contact_userId,
                amount: parseInt(payAmount),
                notes: payNotes,
                PIN: pinValue,
            };
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            setLoading(true);
            LoginServices.payToWallet(payload, config)
                .then((response: any) => {
                    // console.log(response);
                    if (response.data.Status == 1) {
                        // alert(response?.data?.Message);
                        // setLoading(false);
                        // window.location.reload();
                        setLoading(false);
                        // showSection('biller-list')
                        setRefreshP("yes");
                        setShowModal(false);
                        // alert(response?.data?.Message);
                        toast.success("Transaction completed!", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setpinValue("");
                    }
                    //  else {
                    //     // alert(response?.data?.Message);
                    //     toast.success("Transaction completed!", {
                    //         position: "top-center",
                    //         autoClose: 5000,
                    //         hideProgressBar: true,
                    //         closeOnClick: true,
                    //         pauseOnHover: true,
                    //         draggable: true,
                    //         progress: undefined,
                    //     });
                    // }
                    else if (response.data.Status == 0) {
                        setLoading(false);
                        toast.error(`${response.data.Message}`, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    // console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                    setLoading(false);
                });
        }
    };
    const deleteWalletContactHandler = (e: any) => {
        e.stopPropagation();
        const userid_api = e.target.name; /// user id fetched from api
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        if (window.confirm("Are you sure want to delete?")) {
            LoginServices.deleteWalletContact(
                e.target.name,
                e.target.id,
                config
            )
                .then((response) => {
                    if (response.data.Status == 1) {
                        LoginServices.getWalletPayoutContacts(
                            parseInt(userid_api),
                            config
                        ).then((response: any) => {
                            if (response.data.Status == 1) {
                                toast.success("Contact has been removed", {
                                    position: "top-center",
                                    autoClose: 2000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });

                                setPayoutList(response?.data?.WalletContacts);
                                setFilterPayoutlist(
                                    response?.data?.WalletContacts
                                );
                                setResPayoutList(
                                    response?.data?.WalletContacts
                                );

                                if (
                                    response?.data?.WalletContacts?.length == 0
                                ) {
                                    setShowSection("add-img");
                                } else {
                                    setShowSection("biller-list");
                                }
                            } else {
                            }
                        });
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                    setShowSection("add-img");
                });
        }
    };
    const onPinChange = (e: any) => {
        setpinValue(e);
        // setPayoutPin(e)
    };

    return (
        <>
            <div className="row main-row">
                <div className="col-10 right-back">
                    <WalletBlcHeader wBlc={walletBlc}></WalletBlcHeader>
                    {showSection == "add-img" && (
                        <div>
                            <div className="pay-bills-grp1 mar-top-50">
                                <p className="pay-bills-head"></p>
                                <div className="d-flex">
                                    <button
                                        onClick={() =>
                                            setShowSection("biller-list")
                                        }
                                        className="see-billers-btn">
                                        See List
                                    </button>
                                </div>
                            </div>
                            <div className="pay-w-bills-center">
                                <div>
                                    <div>
                                        <img
                                            className="pay-bills-main-img"
                                            src="assets/img/wallet-pay.png"
                                            alt=""
                                        />
                                        <p className="add-your-p">Add Payer</p>
                                        <p className="add-more-p">
                                            Add More Payer to your Payville List
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div className="add-btn-div">
                                        <button
                                            onClick={() =>
                                                setShowSection("add-form")
                                            }
                                            className="add-biller-btn">
                                            Add Payer
                                        </button>
                                        {/* onClick={loginFormSubmit} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showSection == "biller-list" && (
                        <div>
                            <div className="pay-bills-grp1 mar-top-50">
                                <input
                                    type="number"
                                    onChange={(e: any) => {
                                        if (e.target.value.length == 10) {
                                            let filter =
                                                filterPayoutlist.filter(
                                                    (element: any) =>
                                                        element.mobileNumber.includes(
                                                            e.target.value
                                                        )
                                                );

                                            setFilterPayoutlist(filter);
                                        } else if (e.target.value.length == 0) {
                                            setFilterPayoutlist(payoutList);
                                        }
                                    }}
                                    // onKeyPress={(event: any) => {
                                    //     if (event.key === "Enter") {
                                    //         localSearch(event.target.value);
                                    //     }
                                    // }}
                                    className="search-payers"
                                    placeholder="search  with mobile number"
                                />
                                {/* <p className="pay-bills-head">Biller List</p> */}
                                <div className="d-flex">
                                    <button
                                        onClick={() =>
                                            setShowSection("add-form")
                                        }
                                        className="see-billers-btn">
                                        Add Payer
                                    </button>
                                </div>
                            </div>

                            <div className="grid1-main">
                                <div className="row">
                                    {filterPayoutlist?.length != 0 &&
                                        filterPayoutlist?.map((item: any) => {
                                            return (
                                                <div
                                                    key={
                                                        item?.bankAccountNumber
                                                    }
                                                    className="col-sm-2 col-md-2 payee-col">
                                                    <div
                                                        onClick={() => {
                                                            setCurrentPayee(
                                                                item
                                                            );
                                                            setShowModal(true);
                                                        }}
                                                        className="payee-col-sub custom-sub"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}>
                                                        <button
                                                            className="del-btn"
                                                            id={item?.id}
                                                            name={
                                                                item?.added_userId
                                                            }
                                                            onClick={
                                                                deleteWalletContactHandler
                                                            }>
                                                            Delete
                                                        </button>
                                                        {item?.profilePic ==
                                                        "NA" ? (
                                                            <div
                                                                style={{
                                                                    height: "100px",
                                                                    width: "100px",
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                    justifyContent:
                                                                        "center",
                                                                }}>
                                                                <h1 className="first-letter">
                                                                    {item?.firstName
                                                                        .charAt(
                                                                            0
                                                                        )
                                                                        .toUpperCase()}
                                                                </h1>
                                                            </div>
                                                        ) : (
                                                            <img
                                                                className="propic-user"
                                                                src={
                                                                    item?.profilePic
                                                                }
                                                                alt="profilePic"
                                                            />
                                                        )}

                                                        <div className="ac-details">
                                                            <p className="ac-name">
                                                                {
                                                                    item?.firstName
                                                                }
                                                            </p>
                                                            <p className="ac-number">
                                                                {" "}
                                                                {
                                                                    item?.mobileNumber
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                    {filterPayoutlist?.length == 0 && (
                                        <h2>No List Found, Please add</h2>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    {showSection == "add-form" && (
                        <div>
                            <div className="pay-bills-grp1">
                                <p className="pay-bills-head">Add Payer</p>
                                <div className="d-flex">
                                    <button
                                        onClick={() =>
                                            setShowSection("biller-list")
                                        }
                                        className="see-billers-btn">
                                        See Billers
                                    </button>
                                </div>
                            </div>

                            <div className="form-main">
                                <div
                                    className="form-sub"
                                    style={{ marginBottom: "10px" }}>
                                    <div className="d-flex">
                                        <input
                                            onKeyDown={(e) => {
                                                if (e.key == "Enter") {
                                                    searchUser();
                                                }
                                            }}
                                            onChange={(e: any) =>
                                                setSearchItem(e.target.value)
                                            }
                                            name="name"
                                            id="name"
                                            className="pay-bill-ip1"
                                            type="text"
                                            placeholder="Search with Name or Mobile Number"
                                        />
                                        <button
                                            onClick={searchUser}
                                            className="search-user-btn">
                                            {" "}
                                            Search
                                        </button>
                                    </div>

                                    <select
                                        name="UserID"
                                        className="add-user-dropdown"
                                        onChange={(e: any) => {
                                            setUserDetails(e.target.value);
                                        }}
                                        style={{
                                            height: "35px",
                                            width: "100%",
                                        }}>
                                        <option
                                            className="bg-white text-dark"
                                            selected>
                                            Select User
                                        </option>
                                        {usersList !== undefined &&
                                            usersList.map(
                                                (item: any, index: any) => {
                                                    return (
                                                        <option
                                                            key={index}
                                                            className="bg-white text-dark"
                                                            value={item.userId}>
                                                            {item?.firstName} (
                                                            {item?.mobileNumber}
                                                            ){" "}
                                                        </option>
                                                    );
                                                }
                                            )}
                                    </select>
                                </div>
                                <div
                                    className="d-flex"
                                    style={{ marginTop: "20px" }}>
                                    <button
                                        disabled={userDetails?.length == 0}
                                        onClick={addWalletContact}
                                        className="add-biller-btn">
                                        Add
                                    </button>
                                </div>
                                <form className="form-sub">
                                    {/* onChange={(e:any)=>setAddBlc(e.target.value)} */}
                                    {/* <input {...register("name", { required: true })} name="name" id="name" className="pay-bill-ip" type="text" placeholder="Enter Name" />
                                    <label className="error-text"> {errors?.name && "Name is required"} </label>

                                    <input maxLength={10} {...register("mobileNumber", { required: true, pattern: /^([0-9]{10})+$/i })}
                                        name="mobileNumber" id="mobileNumber" className="pay-bill-ip" type="text" placeholder="Enter Contact Number" />
                                    <label className="error-text">{errors?.mobileNumber?.type == 'required' && "Contact number is required "} {errors?.mobileNumber?.type == 'pattern' && "Mobile Number Invalid"} </label> */}

                                    {/* <div className="d-flex" style={{ marginTop: '20px' }}>
                                        <button type='submit' className="add-biller-btn">Add</button>
                                    </div> */}
                                </form>
                            </div>
                        </div>
                    )}

                    <Modal
                        show={showModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        dialogClassName="c-modal-pay-bills"
                        onHide={() => setShowModal(false)}>
                        {/* <Modal.Header closeButton>
        
      </Modal.Header> */}
                        <Modal.Body>
                            <div className="m-name">
                                <p className="m-name-payee">
                                    {currentPayee?.firstName}
                                </p>
                                <p className="m-mobile-payee">
                                    {currentPayee?.mobileNumber}
                                </p>
                                <div className="pay-ip-div">
                                    <input
                                        onChange={(e: any) =>
                                            setPayAmount(e.target.value)
                                        }
                                        name="amount"
                                        className="pay-ip-div-e1"
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Enter Amount"
                                    />
                                    {
                                        <div className="pay-otp">
                                            <OtpInput
                                                inputStyle={{
                                                    width: "30px",
                                                    border: "2px solid grey",
                                                    borderRadius: "4px",
                                                }}
                                                value={pinValue}
                                                onChange={onPinChange}
                                                numInputs={4}
                                                isInputSecure={true}
                                                separator={
                                                    <span
                                                        style={{
                                                            margin: "10px",
                                                        }}></span>
                                                }
                                            />
                                        </div>
                                    }
                                    <textarea
                                        maxLength={450}
                                        onChange={(e: any) =>
                                            setPayNotes(e.target.value)
                                        }
                                        name="notes"
                                        className="pay-ip-div-e2"
                                        placeholder="Add a Note"
                                    />
                                    {/* <div onClick={()=>sendPayment(currentPayee)}> */}
                                    {loading == false && (
                                        <img
                                            onClick={() =>
                                                sendPayment(currentPayee)
                                            }
                                            className="arrow-btn-payee"
                                            src="assets/img/arrow-right.png"
                                            alt=""
                                        />
                                    )}
                                    <BounceLoader
                                        color={"#6865F0"}
                                        loading={loading}
                                        css={override}
                                        size={150}
                                    />

                                    {/* </div> */}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
}

export default WalletTransfer;
