import "./Bbps.css";
import Navbar from "./Bbpsnav";
import { useNavigate, useParams } from "react-router-dom";
import header_Logo from "../../assets/bbps/bharat_pay.png";
import React, { useState, useEffect } from "react";
import Spinner from "../spinner";
import { useForm } from "react-hook-form";
import LoginServices from "../../services/LoginServices";
export default function Bbps() {
    const [spinner, setspinner] = useState(false);
    const { type } = useParams();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const formdata = watch();
    const Navigate = useNavigate();
    const [submitEnabler, setSubmitEnabler] = useState<any>(false);
    const [dropdown, setDropDown] = useState([{ blr_name: "Select Biller" }]);
    const [billervalues, setbillervalues] = useState<any>();
    const [billerid, setbillerid] = useState<any>();
    const userId = localStorage.getItem("userid");

    useEffect(() => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        LoginServices.FetchBillerListByCategoryName(config, type, 0).then(
            (response) => {
                // console.log("res", response);
                setDropDown(response?.data?.BillersList);
            }
        );
    }, []);
    const selectBillerHandler = (e: any) => {
        // console.log(e.target.value);
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        let body = { billerId: e.target.value };
        setbillerid(e.target.value);
        LoginServices.FetchBillerInfoPost(body, config)
            .then((response) => {
                // console.log(response);
                if (response?.data.Status == 1) {
                    setbillervalues(response?.data?.BillerInfo);
                    localStorage.setItem(
                        "billerAdhoc",
                        response?.data?.BillerInfo?.billerAdhoc
                    );
                    sessionStorage.setItem(
                        "billerName",
                        response?.data?.BillerInfo?.billerName
                    );
                } else if (response.data.Status == 0) {
                    alert("something went wrong try again later");
                }
            })
            .catch((err) => {
                console.log(err);
                alert("something went wrong try again later");
            });
        setSubmitEnabler(true);
    };
    const fetchbillHandler = (data: any) => {
        sessionStorage.setItem("usernumber", formdata.MobileNumber);
        setspinner(true);
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        //mobilenumber
        const [first, second, ...result] = Object.entries(data).map((e) => ({
            [e[0]]: e[1],
        }));
        const inputresult = result?.map((item) => {
            return {
                paramName: Object?.keys(item)[0],
                paramValue: Object?.values(item)[0],
            };
        });
        const body = {
            billerId: data?.Biller,
            mobileNumber: data?.MobileNumber,
            Params: { inputParams: { input: [...inputresult] } },
        };
        LoginServices.FetchBillDetails(body, config)
            .then((response: any) => {
                setspinner(false);
                if (response?.data?.Status == 1) {
                    // console.log("detail", response);
                    sessionStorage.setItem(
                        "billdetails",
                        JSON.stringify(response.data)
                    );
                    sessionStorage.setItem(
                        "amount",
                        response.data.BillDetails?.billerResponse?.billAmount
                    );
                    sessionStorage.setItem("billerid", data?.Biller);
                    Navigate(`/dashboard/bbps/Biller/pay/${type}`);
                } else if (response?.data?.Status == 0) {
                    alert(response?.data?.Message);
                }
            })
            .catch((err) => {
                setspinner(false);
                alert("some thing went wrong please try later ");
            });
    };
    return (
        <>
            {spinner && <Spinner />}
            <div className="row main-row">
                <div className="col-10 dashboard-right-back">
                    <div className="header-bbps">
                        <div className="BbpsWrapper">
                            <div className="Bbps-heading">
                                <p>BBPS</p>
                                <img src={header_Logo} alt="" />
                            </div>
                            {/* <div className="Bbps-Navbar">
                            <Navbar active="billpay" />
                        </div> */}
                            <div className="biller-details-wrapper">
                                <form
                                    className="biller-details"
                                    onSubmit={handleSubmit(fetchbillHandler)}>
                                    <div className="biller-detail">
                                        <select
                                            className="select"
                                            defaultValue={"Select-Biller"}
                                            {...register("Biller", {
                                                required:
                                                    "Please select Biller",
                                                onChange: (e) => {
                                                    selectBillerHandler(e);
                                                },
                                            })}>
                                            <option
                                                value="Select-Biller"
                                                disabled>
                                                Select Biller
                                            </option>
                                            {dropdown?.map((item: any) => (
                                                <option value={item?.blr_id}>
                                                    {" "}
                                                    {item?.blr_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {submitEnabler && (
                                        <>
                                            <div className="biller-detail">
                                                <img
                                                    src="/assets/img/call.svg"
                                                    className="call-icon"
                                                    alt=""
                                                />
                                                <input
                                                    className={
                                                        errors?.MobileNumber &&
                                                        "input-error"
                                                    }
                                                    id="billerinput"
                                                    placeholder="Enter Mobile Number"
                                                    type={"number"}
                                                    {...register(
                                                        "MobileNumber",
                                                        {
                                                            required:
                                                                "Please Enter Mobile Number",
                                                        }
                                                    )}
                                                />
                                                {errors?.MobileNumber && (
                                                    <p style={{ color: "red" }}>
                                                        {" "}
                                                        {
                                                            errors?.MobileNumber
                                                                .message
                                                        }
                                                    </p>
                                                )}
                                            </div>
                                            {billervalues?.billerInputParams?.paramInfo?.map(
                                                (data: any) => {
                                                    const name =
                                                        data?.paramName;
                                                    const boolValue =
                                                        data?.isOptional ==
                                                        "true";
                                                    // console.log(name);
                                                    return (
                                                        <div className="biller-detail">
                                                            <input
                                                                className={
                                                                    errors[
                                                                        `${name}`
                                                                    ] &&
                                                                    "input-error"
                                                                }
                                                                data-regex={
                                                                    data?.regEx
                                                                }
                                                                minLength={
                                                                    data?.minLength
                                                                }
                                                                maxLength={
                                                                    data?.maxLength
                                                                }
                                                                data-name={
                                                                    data?.paramName
                                                                }
                                                                id="billerinput"
                                                                placeholder={
                                                                    name
                                                                }
                                                                type={"text"}
                                                                pattern={
                                                                    data?.regEx
                                                                }
                                                                {...register(
                                                                    `${name}`,
                                                                    {
                                                                        required:
                                                                            !boolValue,
                                                                        pattern:
                                                                            {
                                                                                value: data?.regEx,
                                                                                message: `invalid input`,
                                                                            },
                                                                    }
                                                                )}
                                                            />
                                                            {errors[
                                                                `${name}`
                                                            ] && (
                                                                <p
                                                                    style={{
                                                                        color: "red",
                                                                    }}>
                                                                    Enter a
                                                                    Valid{" "}
                                                                    {
                                                                        data?.paramName
                                                                    }
                                                                </p>
                                                            )}
                                                        </div>
                                                    );
                                                }
                                            )}
                                            <button type="submit">
                                                Fetch Bill
                                            </button>
                                        </>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
