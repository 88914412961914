import React from "react";
import Navigation from "./Navigation";
import "./css/ServicesPage.css";
import { Row, Col, Container, Button, Card } from "react-bootstrap";
import image1 from "../assets/handsome-man-with-laptop-removebg-preview 1.svg";
import image2 from "../assets/Ellipse 197.svg";
import image3 from "../assets/Vector.svg";
import Footer from "./Footer";
import image4 from "../assets/services/invoice 1.png";
import image5 from "../assets/services/electricity 1.png";
import image6 from "../assets/services/gas-tank 1.png";
import image7 from "../assets/services/drop 1.png";
import image8 from "../assets/services/bulding 1.png";
import image9 from "../assets/services/hotel (3) 1.png";
import image10 from "../assets/services/billing 2.png";
import image11 from "../assets/services/home (1) 1.png";
import image12 from "../assets/services/cards (1) 1.png";

const Items = (props) => {
    return (
        <Card className="service-items">
            <Card.Body>
                <Container>
                    <div className="card-content">
                        <img
                            src={props.image}
                            style={{ height: "50px" }}
                            className="services-item-icons"
                            alt="image3"
                        />
                        <h4>{props.title}</h4>
                        <p>{props.content}</p>
                    </div>
                </Container>
            </Card.Body>
        </Card>
    );
};

const ServicesPage = () => {
    const items = [
        {
            image: image4,
            title: "Bill Payments",
            content:
                "Make bill payments very quickly and we provide all major bill payments which includes electricity, gas, housing society, Educations fee etc.",
        },
        {
            image: image5,
            title: "Electricity",
            content:
                "Pay electricity bills with more convenient. Supports all major power distribution companies including governement agencies and private companies",
        },
        {
            image: image6,
            title: "Gas",
            content:
                "Make Gas connection payments using PayVille. All major service providers are available",
        },
        {
            image: image7,
            title: "Water Bill",
            content:
                "All major metros collect water charges and we provide easy way to make water bill payments directly to the Water bill account numbers.",
        },
        /*  {
            image: image8,
            title: "Housing Society",
            content:
                "Quickly pay your hosuing society maintenance charges or annual membership fees using PayVille. Supports alll major card types.",
        },
        {
            image: image9,
            title: "Society Maintenance",
            content:
                "Pay society maintenance using netbanking, credit card or debit card. All major socities are registered.",
        },
        {
            image: image10,
            title: "Credit Card Payment",
            content:
                "Want to make partial or full credi card dues. We support all major card types. Payments will be credited directly to the card issuer bank",
        }, */
    ];

    /* 
{
      image:image11,
      title:'House Rent',
      content:'Pay your house rents by adding landlord account details. Directly pay rents from Wallet Balance. Rent amount instantly credited to the Landlord’s account.'
    },
    {
      image:image12,
      title:'House Rent',
      content:'Wanted to request payments from your vendors. Just create payment link and share with them. One can make quick payments using the payment links'
    } */

    return (
        <div>
            <Navigation />

            <section className="section1">
                <Container>
                    <Row>
                        <Col>
                            <div className="ourservices">
                                <h1 className="heading">Our Services</h1>
                                <p className="description">
                                    Pay Ville is a fintech company where we
                                    provide varitey of services rnaging from
                                    bill payments, educational fees etc. Please
                                    check below our list of services we offer
                                </p>
                            </div>
                        </Col>
                        <Col>
                            <div className="ourservice-image">
                                <img
                                    src={image1}
                                    className="man-image"
                                    alt="man-image"
                                />
                                <img
                                    src={image2}
                                    className="circle-behind-man"
                                    alt="circle"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="section2-services">
                <div style={{ marginBottom: "100px", marginTop: "70px" }}>
                    <h1 className="heading" style={{ textAlign: "center" }}>
                        Services we provide
                    </h1>
                </div>

                <Container
                    style={{ marginRight: "60px", marginBottom: "40px" }}>
                    <Row>
                        {items.map((Content) => (
                            <Col className="set">
                                <Items
                                    image={Content.image}
                                    title={Content.title}
                                    content={Content.content}
                                />
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>

            <Footer curve={true} />
        </div>
    );
};

export default ServicesPage;
