import "./Bbps.css";
import Navbar from "./Bbpsnav";
import { useNavigate, useParams } from "react-router-dom";
import header_Logo from "../../assets/bbps/bharat_pay.png";
import React, { useState, useEffect } from "react";
import LoginServices from "../../services/LoginServices";
import Spinner from "../spinner";
export default function Bbps() {
    const [spinner, setSpinner] = useState(false);
    const { type } = useParams();
    const [billdetails, setbilldetails] = useState<any>();
    const [billername, setbillername] = useState<any>(
        sessionStorage.getItem("billerName")
    );
    let bill = sessionStorage.getItem("billdetails");
    const [amount, setamount] = useState<any>();
    const navigate = useNavigate();
    const userId = localStorage.getItem("userid");

    useEffect(() => {
        setamount(Number(sessionStorage.getItem("amount")) / 100);
        if (bill) {
            setbilldetails(JSON.parse(bill));
            // console.log(JSON.parse(bill));
        }
    }, []);
    const amountChange = (e: any) => {
        setamount(e.target.value);
    };
    const payHandler = () => {
        setSpinner(true);
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        const billerAdhoc = localStorage.getItem("billerAdhoc");
        let id = {};
        if (billerAdhoc) {
            id = {
                RequestID: billdetails?.RequestID,
            };
        }
        console.log(billdetails);
        let additionalInfo: any = [];
        if (billdetails?.BillDetails?.additionalInfo) {
            additionalInfo = [
                ...billdetails?.BillDetails?.additionalInfo?.info,
            ];
        }

        let data: any = {
            billerId: sessionStorage.getItem("billerid"),
            billerAdhoc: localStorage.getItem("billerAdhoc"),
            ...id,
            Amount: Number(amount) * 100,
            mobileNumber: sessionStorage.getItem("usernumber"),
            Params: {
                inputParams: {
                    input: [...billdetails?.BillDetails?.inputParams.input],
                },
                billerResponse: { ...billdetails?.BillDetails?.billerResponse },

                paymentMethod: {
                    paymentMode: "Wallet",
                    quickPay: "N",
                    splitPay: "N",
                },
                amountInfo: {
                    amount: Number(amount) * 100,
                    currency: "356",
                    custConvFee: "0",
                },
                paymentInfo: {
                    info: [
                        {
                            infoName: "WalletName",
                            infoValue: "Credit Mint Pvt Ltd",
                        },
                        {
                            infoName: "MobileNo",
                            infoValue: localStorage.getItem("mobilenumber"),
                        },
                    ],
                },
            },
        };
        if (additionalInfo?.length > 0) {
            data = {
                ...data,
                additionalInfo: {
                    additionalInfo,
                },
            };
        }
        console.log(data);
        // console.log("abcd", data);
        LoginServices.paybill(data, config)
            .then((response: any) => {
                setSpinner(false);
                if (response.data?.Status == 1) {
                    sessionStorage.setItem(
                        "success",
                        JSON.stringify(response?.data?.BillPayResponse)
                    );
                    navigate(`/dashboard/bbps/success/${type}`);
                } else if (response?.data.Status == 0) {
                    alert(response?.data?.Message);
                }
                // console.log(response);
            })
            .catch((err) => {
                // console.log(err.response);
                setSpinner(false);
                alert(err?.response?.data?.Message);
            });
    };
    return (
        <>
            {spinner && <Spinner />}

            <div className="row main-row">
                <div className="col-10 dashboard-right-back">
                    <div className="header-bbps">
                        <div className="BbpsWrapper">
                            <div className="Bbps-heading">
                                <p>BBPS</p>
                                <img src={header_Logo} alt="" />
                            </div>
                            {/* <div className="Bbps-Navbar">
                            <Navbar active="billpay" />
                        </div> */}
                            <div className="amount-wrapper">
                                <div className="amount-data">
                                    <label htmlFor="billername">
                                        Biller Name
                                    </label>
                                    <input
                                        type="text"
                                        placeholder={billername}
                                        disabled
                                    />
                                </div>
                                {billdetails?.BillDetails?.additionalInfo?.info?.map(
                                    (item: any) => {
                                        return (
                                            <div className="amount-data">
                                                <label htmlFor={item?.infoName}>
                                                    {item?.infoName}
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder={
                                                        item?.infoValue
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        );
                                    }
                                )}
                                {billdetails?.BillDetails?.inputParams?.input?.map(
                                    (item: any) => {
                                        return (
                                            <div className="amount-data">
                                                <label
                                                    htmlFor={
                                                        item?.input?.paramName
                                                    }>
                                                    {item?.paramName}
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder={
                                                        item?.paramValue
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        );
                                    }
                                )}
                                <div className="amount-data">
                                    <label htmlFor="due date">Due Date</label>
                                    <input
                                        type="text"
                                        placeholder={
                                            billdetails?.BillDetails
                                                ?.billerResponse?.dueDate
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="amount-data">
                                    <label htmlFor="billAmount">
                                        bill Amount
                                    </label>
                                    <input
                                        type="number"
                                        value={amount}
                                        onChange={amountChange}
                                    />
                                </div>

                                <button onClick={payHandler}>Pay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
