import React from "react";
import { NavLink } from "react-router-dom";
function Bbpssubmenu(props: any) {
    let active = props.active;
    return (
        <div className="Complaint-submenu">
            <NavLink
                className={
                    active == "Raise Complaint"
                        ? "Complaintactive"
                        : "Complaintinactive"
                }
                to="/dashboard/bbps/complaints">
                Raise Complaint
            </NavLink>
            <NavLink
                className={
                    active == "Check" ? "Complaintactive" : "Complaintinactive"
                }
                to="/dashboard/bbps/status">
                Check Complaint Status
            </NavLink>
            <NavLink
                className={
                    active == "search" ? "Complaintactive" : "Complaintinactive"
                }
                to="/dashboard/bbps/search-complaint">
                Search Complaint
            </NavLink>
        </div>
    );
}

export default Bbpssubmenu;
