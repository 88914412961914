import "./Bbps.css";
import Navbar from "./Bbpsnav";
import { useNavigate, useParams } from "react-router-dom";
import header_Logo from "../../assets/bbps/bharat_pay.png";
import React, { useState, useEffect } from "react";
import LoginServices from "../../services/LoginServices";
import Submenu from "./Bbpssubmenu";
import Spinner from "../spinner";
import { useForm } from "react-hook-form";
export default function Bbps() {
    const [spinner, setspinner] = useState<any>(false);
    const navigate = useNavigate();
    const [complaintStatus, setcomplaintStatus] = useState<any>();
    const status = sessionStorage.getItem("complaintStatus");
    const userId = localStorage.getItem("userid");
    useEffect(() => {
        if (status) {
            setcomplaintStatus(JSON.parse(status));
        }
    }, []);
    return (
        <div className="row main-row">
            <div className="col-10 dashboard-right-back">
                <div className="header-bbps">
                    <div className="BbpsWrapper">
                        <div className="Bbps-heading">
                            <p>Complaints</p>
                            <img src={header_Logo} alt="" />
                        </div>
                        <div className="Bbps-Navbar">
                            <Navbar active="complaints" />
                        </div>
                        <div className="Complaint-status">
                            <h1>Complaint Status </h1>
                            <div className="Complaint-status-items">
                                <div className="Complaint-status-item">
                                    <h3>Complaint Assigned</h3>
                                    <p>:</p>
                                    <h5>
                                        {complaintStatus?.complaintAssigned}
                                    </h5>
                                </div>
                                <div className="Complaint-status-item">
                                    <h3>Complainted ID</h3>
                                    <p>:</p>
                                    <h5>{complaintStatus?.complaintId}</h5>
                                </div>
                                <div className="Complaint-status-item">
                                    <h3>Complaint Status</h3>
                                    <p>:</p>
                                    <h5>{complaintStatus?.complaintStatus}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
