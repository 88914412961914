import "./Bbps.css";
import Navbar from "./Bbpsnav";
import { useNavigate, useParams } from "react-router-dom";
import header_Logo from "../../assets/bbps/bharat_pay.png";
import React, { useState, useEffect } from "react";
import Spinner from "../spinner";
import { useForm } from "react-hook-form";
import LoginServices from "../../services/LoginServices";
export default function Bbps() {
    const [spinner, setspinner] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const userId = localStorage.getItem("userid");
    const Navigate = useNavigate();
    const [submitEnabler, setSubmitEnabler] = useState<any>(false);
    const [dropdown, setDropDown] = useState<any>();
    const selectBillerHandler = (e: any) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        LoginServices.FetchBillerListByCategoryName(
            config,
            e.target.value,
            1
        ).then((response) => {
            setSubmitEnabler(true);
            setDropDown(response?.data?.BillersList);
        });
    };
    const biller = [
        // "Broadband Postpaid",
        // "Cable Tv",
        // "Clubs and Associations",
        // "Credit Card",
        // "DTH",
        "Electricity",
        // "Fastag",
        // "Gas",
        // "Insurance",
        // "Hospital and pathology",
        // "Landline Postpaid",
        // "Loan Repayment",
        // "LPG Gas",
        // "Subscription",
        // "Education Fees",
        // "Municipal Services",
        // "Municipal Taxes",
        // "Mobile Postpaid",
        // "Mobile Prepaid",
        "Water",
        // "Housing Society",
    ];
    const Submithandler = (data: any) => {
        sessionStorage.setItem("BillerCat", data.BillerCat);
        sessionStorage.setItem("Biller", data.Biller);
        Navigate("/dashboard/bbps/quick-pay/details");
    };
    return (
        <>
            {spinner && <Spinner />}
            <div className="row main-row">
                <div className="col-10 dashboard-right-back">
                    <div className="header-bbps">
                        <div className="BbpsWrapper">
                            <div className="Bbps-heading">
                                <p>Quick Pay</p>
                                <img src={header_Logo} alt="" />
                            </div>
                            <div className="Bbps-Navbar">
                                <Navbar active="quickpay" />
                            </div>
                            {/* <div className="Bbps-Navbar">
                            <Navbar active="billpay" />
                        </div> */}
                            <div className="biller-details-wrapper">
                                <form
                                    className="biller-details"
                                    id="quickpay"
                                    onSubmit={handleSubmit(Submithandler)}>
                                    <div className="biller-detail">
                                        <img
                                            src="/assets/img/billerName.svg"
                                            className="call-icon"
                                            alt=""
                                        />
                                        <select
                                            className="select"
                                            defaultValue=""
                                            // onChange={selectBillerHandler}
                                            {...register("BillerCat", {
                                                required:
                                                    "Please select Biller Category",
                                                onChange: (e: any) => {
                                                    selectBillerHandler(e);
                                                },
                                            })}>
                                            <option value="" disabled>
                                                Select Biller Category
                                            </option>
                                            {biller.map((item: any) => {
                                                return (
                                                    <option value={item}>
                                                        {item}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    {submitEnabler && (
                                        <>
                                            <div className="biller-detail ">
                                                <select
                                                    className={`select ${
                                                        errors?.Biller &&
                                                        "input-error"
                                                    }`}
                                                    defaultValue={""}
                                                    {...register("Biller", {
                                                        required:
                                                            "Please select Biller",
                                                    })}>
                                                    <option value="" disabled>
                                                        Biller Name
                                                    </option>
                                                    {dropdown?.map(
                                                        (item: any) => (
                                                            <option
                                                                value={
                                                                    item?.blr_id
                                                                }>
                                                                {" "}
                                                                {item?.blr_name}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                                {errors?.Biller && (
                                                    <p style={{ color: "red" }}>
                                                        {" "}
                                                        {errors?.Biller.message}
                                                    </p>
                                                )}
                                            </div>
                                            <button type="submit">
                                                Submit
                                            </button>
                                        </>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
