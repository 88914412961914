import React from "react";

function Banner() {
    return (
        <>
            {/* <!-- section jumbotron--> */}
            <section id="hero" className="hero d-flex align-items-center ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex flex-column justify-content-center">
                            <div className="hero-title">
                                <h1
                                    data-aos="fade-up"
                                    style={{ fontSize: "2.5rem" }}>
                                    Pay bills
                                </h1>
                                <h1
                                    data-aos="fade-up"
                                    style={{ fontSize: "2.5rem" }}>
                                    We handle
                                </h1>
                                <h1
                                    style={{ fontSize: "2.5rem" }}
                                    className="rent-title"
                                    data-aos="fade-up"
                                    data-aos-delay="400">
                                    Vendor and Utility <br /> bill payments
                                </h1>
                                {/*    <div data-aos="fade-up" data-aos-delay="600">
                                    <div className="text-left text-lg-start">
                                        <a
                                            href="#about"
                                            className="btn-applepay scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                                            <span>
                                                {" "}
                                                <a
                                                    href="https://play.google.com/store/apps/details?id=com.app.payville"
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    <img src="assets/img/google.svg" />
                                                </a>
                                            </span>
                                            <i className="bi bi-arrow-right"></i>
                                        </a>
                                        <a
                                            href="#about"
                                            className="btn-applepay scrollto d-inline-flex align-items-center justify-content-center align-self-center ml-2">
                                            <span>
                                                <a
                                                    href="https://apps.apple.com/in/app/payville-by-credit-mint/id1608770809"
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    <img src="assets/img/home/appstore.svg" />
                                                </a>
                                            </span>
                                            <i className="bi bi-arrow-right"></i>
                                        </a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div
                            className="col-lg-6 hero-img"
                            data-aos="zoom-out"
                            data-aos-delay="200">
                            <div
                                className="multiple-1"
                                data-aos="fade-right"
                                data-aos-delay="900">
                                {" "}
                                <img src="assets/img/home/circles.png" />
                            </div>
                            <div
                                className="multiple-2"
                                data-aos="fade-left"
                                data-aos-delay="1000">
                                <img src="assets/img/home/dots.png" />
                            </div>
                            <div
                                className="multiple-3"
                                data-aos="fade-right"
                                data-aos-delay="1200">
                                {" "}
                                <img src="assets/img/home/one-circle.png" />
                            </div>
                            <div
                                className="multiple-4"
                                data-aos="fade-left"
                                data-aos-delay="1500">
                                {" "}
                                <img src="assets/img/home/circles.png" />
                            </div>
                            <div
                                className="multiple-5"
                                data-aos="fade-right"
                                data-aos-delay="1700">
                                {" "}
                                <img src="assets/img/home/x.png" />
                            </div>
                        </div>
                    </div>
                    <div className="bannerimg">
                        <div className="header-overlayer"></div>
                        <img
                            src="assets/img/home/header_image.svg"
                            className="img-fluid banner-img"
                            alt=""
                            data-aos="zoom-in"
                            data-aos-delay="500"
                        />
                        <div className="multiple-6">
                            {" "}
                            <img src="assets/img/home/rectangle.png" />
                        </div>
                        <div className="multiple-7">
                            {" "}
                            <img src="assets/img/home/dots.png" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Banner;
