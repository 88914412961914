import axios from "axios";

export default axios.create({
    //  baseURL: 'http://3.108.129.95:3001/api',
    baseURL: "https://api.payville.in/api",
    // baseURL: "http://3.108.129.95:3001/api",
    // baseURL: "http://3.108.129.95:3000/api",
    // baseURL: "http://c100-183-82-104-227.ngrok.io/api",
    headers: {
        "Content-type": "application/json",
    },
});
