import React, { useState, useRef } from "react";
import "./App.css";
import { Card, Row, Container, Col, Form, Button } from "react-bootstrap";
import LoginServices from "./services/LoginServices";
import Navigation from "./website-partials/Navigation";

function Contact() {
    const initialValue = {
        name: "",
        number: null,
        message: "",
    };

    const [state, setState] = useState(initialValue);

    const [validated, setValidated] = useState(false);

    const formRef = useRef(null);

    const handleReset = () => {
        formRef.current.reset();
        setValidated(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const payload = {
            name: e.target[0].value,
            number: e.target[1].value,
            message: e.target[2].value,
        };

        LoginServices.contactUsInfo(payload)
            .then((response) => {
                if (response.data.Status == 1) {
                    setValidated(true);
                    handleReset();
                }
            })
            .catch((e) => {});
    };

    return (
        <>
            <Navigation />

            <div
                className="contactus"
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "2rem",
                    margin: "2rem",
                }}>
                <div style={{ margin: "10rem 0 0 0 ", flexGrow: 1 }}>
                    <Card>
                        <Card.Header className="text-center">
                            <h3>Address</h3>
                        </Card.Header>
                        <Card.Body>
                            <p>
                                Plot no 28,road no 3,
                                <br />
                                Santoshimatha colony,
                                <br />
                                Hasthinapur,Hyderabad-500079,
                                <br />
                                500074
                            </p>
                        </Card.Body>
                    </Card>
                </div>
                <Card
                    style={{
                        width: "900px",
                        margin: "10rem 0",
                        marginTop: "150px",
                    }}>
                    <Card.Header className="text-center">
                        <h1>Contact Us</h1>
                    </Card.Header>
                    <Card.Body>
                        <Form
                            ref={formRef}
                            validated={validated}
                            onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="name01">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Enter Name"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="number01">
                                <Form.Label>Number</Form.Label>
                                <Form.Control
                                    required
                                    type="number"
                                    placeholder="Enter Mobile Number"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="number01">
                                <Form.Label>Message</Form.Label>
                                <Form.Control
                                    required
                                    as="textarea"
                                    placeholder="Leave a message here"
                                    style={{ height: "100px" }}
                                />
                            </Form.Group>

                            <Button
                                variant="primary"
                                size="lg"
                                type="submit"
                                style={{
                                    marginLeft: "45%",
                                    backgroundColor: "#6865F0",
                                    borderRadius: "20px",
                                }}>
                                Submit
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export default Contact;
