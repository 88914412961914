import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import Razor from "./Razor";
import { Modal, Row, Col } from "react-bootstrap";
import { MdModeEditOutline } from "react-icons/md";
import ReferralModal from "./referralModal";
function ReferralUsers() {
    const navigate = useNavigate();
    const [walletBlc, setWalletBlc] = useState<any>(0);
    const [addBlc, setAddBlc] = useState<number>();
    const [walletTransactions, setWalletTransactions] = useState<any>();
    const [modal, setmodal] = useState(false);
    const [id, setid] = useState<any>();
    const [walletCommissionPercentage, setwalletCommissionPercentage] =
        useState<any>();
    const userId = localStorage.getItem("userid");
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [currentRow, setCurrentRow] = useState<any>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [userDetails, setUserDetails] = useState<any>();
    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getUserDetailsByID(parseInt(userId), config)
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        setUserDetails(response?.data?.UserDetails);
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
            LoginServices.getRefListByUserId(parseInt(userId), config)
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        setWalletTransactions(response?.data?.Users);
                        // setWalletBlc(response?.data?.walletBalance)
                    } else {
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [""]);
    const edithandler = (e: any) => {
        setmodal(true);
        setid(e.currentTarget.getAttribute("id"));
        setwalletCommissionPercentage(e.currentTarget.getAttribute("name"));
    };
    const inputchangeHandler = (e: any) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };

        LoginServices.getRefListByUserId(userId, config)
            .then((response: any) => {
                if (response.data.Status == 1) {
                    setWalletTransactions(response?.data?.Users);
                    // setWalletBlc(response?.data?.walletBalance)
                } else {
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };
    return (
        <>
            {modal && (
                <ReferralModal
                    inputchangeHandler={inputchangeHandler}
                    setmodal={setmodal}
                    id={id}
                    walletCommissionPercentage={walletCommissionPercentage}
                />
            )}
            <div className="row main-row">
                <div className="col-10 right-back">
                    <WalletBlcHeader wBlc={walletBlc}></WalletBlcHeader>
                    <p className="transaction-head">Referral Users</p>

                    <div className="total-transaction-box-main table-responsive">
                        {walletTransactions?.length != 0 && (
                            <table className="table">
                                <thead>
                                    <tr className="trans-row">
                                        <th>Name</th>
                                        <th>Contact Number</th>
                                        <th>Company Name</th>
                                        <th>Referral Code</th>
                                        <th>Commission Percentage</th>
                                    </tr>
                                </thead>
                                <tbody style={{ borderTopWidth: 0 }}>
                                    {walletTransactions?.length != 0 &&
                                        walletTransactions?.map(
                                            (item: any, index: any) => {
                                                return (
                                                    <tr
                                                        className="trans-row1"
                                                        key={item}>
                                                        <td>
                                                            {" "}
                                                            {
                                                                item?.firstName
                                                            }{" "}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {
                                                                item?.mobileNumber
                                                            }{" "}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {
                                                                item?.companyName
                                                            }{" "}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {
                                                                item?.myReferralCode
                                                            }{" "}
                                                        </td>
                                                        <td>
                                                            {userDetails?.referralUsersCommissionEditStop ==
                                                            1 ? (
                                                                <>
                                                                    {
                                                                        item?.walletCommissionPercentage
                                                                    }{" "}
                                                                    %
                                                                    <MdModeEditOutline
                                                                        id={
                                                                            item?.userId
                                                                        }
                                                                        name={
                                                                            item?.walletCommissionPercentage
                                                                        }
                                                                        onClick={
                                                                            edithandler
                                                                        }
                                                                        color="blue"
                                                                        size={
                                                                            20
                                                                        }
                                                                        style={{
                                                                            textAlign:
                                                                                "center",
                                                                            marginLeft:
                                                                                "1rem",
                                                                        }}
                                                                    />
                                                                </>
                                                            ) : (
                                                                `${item?.walletCommissionPercentage} %`
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                </tbody>
                            </table>
                        )}
                        {walletTransactions?.length == 0 && (
                            <h3>No Records Found</h3>
                        )}
                    </div>

                    <Modal
                        show={showModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        // dialogClassName="c-modal-pay-bills"
                        onHide={() => setShowModal(false)}>
                        {/* <Modal.Header closeButton>
        
      </Modal.Header> */}
                        <Modal.Body>
                            <div className="t-details-div">
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td> Name </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {
                                                    currentRow?.bankAccountName
                                                }{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Mobile Number </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {currentRow?.mobileNumber}{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Bank Account Number </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {
                                                    currentRow?.bankAccountNumber
                                                }{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Bank IFSC Code </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {currentRow?.bankIFSCCode}{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Order ID </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {
                                                    currentRow?.wallet_transactionId
                                                }{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Sub total </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                ₹{
                                                    currentRow?.subtotalAmount
                                                }{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Charges </td>
                                            <td>:</td>
                                            <td> ₹{currentRow?.charges} </td>
                                        </tr>
                                        <tr>
                                            <td> GST Amount </td>
                                            <td>:</td>
                                            <td> ₹{currentRow?.GSTAmount} </td>
                                        </tr>

                                        <tr>
                                            <td> Amount </td>
                                            <td>:</td>
                                            <td> ₹{currentRow?.amount} </td>
                                        </tr>
                                        <tr>
                                            <td> Transaction Type </td>
                                            <td>:</td>
                                            <td>
                                                {currentRow?.transactionType ==
                                                    1 && <span>Recharge</span>}
                                                {currentRow?.transactionType ==
                                                    2 && <span>Payout</span>}
                                                {currentRow?.transactionType ==
                                                    3 && (
                                                    <span>Wallet Debit</span>
                                                )}
                                                {currentRow?.transactionType ==
                                                    4 && (
                                                    <span>Wallet Credit</span>
                                                )}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td> Date </td>
                                            <td>:</td>
                                            <td>
                                                <Moment format="DD MMM YYYY hh:mm A">
                                                    {
                                                        currentRow?.transactionDateTime
                                                    }
                                                </Moment>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Transactions Status </td>
                                            <td>:</td>
                                            <td>
                                                {currentRow?.transactionStatus ==
                                                    2 && (
                                                    <span className="pending-span">
                                                        {" "}
                                                        Pending
                                                    </span>
                                                )}
                                                {currentRow?.transactionStatus ==
                                                    1 && (
                                                    <span className="completed-span">
                                                        {" "}
                                                        Processed
                                                    </span>
                                                )}
                                                {currentRow?.transactionStatus ==
                                                    3 && (
                                                    <span className="failed-span">
                                                        {" "}
                                                        Failed
                                                    </span>
                                                )}
                                                {currentRow?.transactionStatus ==
                                                    4 && (
                                                    <span className="reversed-span">
                                                        {" "}
                                                        Reversed
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Description </td>
                                            <td>:</td>
                                            <td> {currentRow?.description} </td>
                                        </tr>
                                        <tr>
                                            <td> Notes </td>
                                            <td>:</td>
                                            <td> {currentRow?.notes} </td>
                                        </tr>
                                        <tr>
                                            <td> Payout ContactId </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {
                                                    currentRow?.payout_contactId
                                                }{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Razorpay TransactionId </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {
                                                    currentRow?.razorpay_TransactionId
                                                }{" "}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </>
    );
}

export default ReferralUsers;
