import React from "react";

function HowDoesWork() {
    return (
        <>
            {/*    <!-- ======= HowDoesWork Section ======= --> */}
            <section id="how-does-work" className="how-does-work">
                <div
                    className="container"
                    data-aos="fade-up"
                    data-aos-delay="1000">
                    <header className="section-header">
                        <h1 className="heading-1">How Does It Works</h1>
                    </header>
                    <div className="row gy-4">
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="how-does-work-box"
                                data-aos="fade-up"
                                data-aos-delay="1600">
                                <img
                                    src="assets/img/home/category.svg"
                                    className="img-fluid"
                                    alt=""
                                />
                                <p>Select Category</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div
                                className="how-does-work-box"
                                data-aos="fade-up"
                                data-aos-delay="2000">
                                <img
                                    src="assets/img/home/beneficiary.svg"
                                    className="img-fluid"
                                    alt=""
                                />
                                <p>Enter Beneficiary Category</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div
                                className="how-does-work-box"
                                data-aos="fade-up"
                                data-aos-delay="2500">
                                <img
                                    src="assets/img/home/make_payment.svg"
                                    className="img-fluid"
                                    alt=""
                                />
                                <p>Make Payment</p>
                            </div>
                        </div>

                        {/* <div className="col-lg-3 col-md-6">
                            <div
                                className="how-does-work-box"
                                data-aos="fade-up"
                                data-aos-delay="3000">
                                <img
                                    src="assets/img/home/wallet.svg"
                                    className="img-fluid"
                                    alt=""
                                    style={{ width: 160, height: 160 }}
                                />
                                <p>Amount Credited Instantly</p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}

export default HowDoesWork;
