import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import Razor from "./Razor";
import { Modal, Row, Col } from "react-bootstrap";
import { css } from "@emotion/react";

import { BounceLoader } from "react-spinners";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#6865F0";
`;

function Kyc() {
    let [loading, setLoading] = useState(false);
    // const [walletTransactions, setWalletTransactions] = useState<any>();
    const userId = localStorage.getItem("userid");
    const navigate = useNavigate();
    const [walletBlc, setWalletBlc] = useState<any>(0);
    const [captchImage, setCaptchImage] = useState<any>("");
    const [captchaToken, setCaptchaToken] = useState<any>("");
    const [aadharNumber, setAadharNumber] = useState<any>("");
    const [otpCode, setverifyOtp] = useState<any>("");
    const [showOtp, setShowOtp] = useState<any>(false);
    const [aadharResponse, setAadharResponse] = useState<any>();

    const [userDetails, setUserDetails] = useState<any>();

    useEffect(() => {
        if (userId) {
            if (localStorage.getItem("isKycDone") == "1") {
                alert("KYC verification already done");
                navigate("/dashboard");
            }
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    // console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
            //     LoginServices.getWalletTransactions(parseInt(userId), config)
            //         .then((response: any) => {
            //             if (response.data.Status == 1) {
            //                 setWalletTransactions(
            //                     response?.data?.WalletTransactions
            //                 );
            //                 // setWalletBlc(response?.data?.walletBalance)
            //             } else {
            //             }
            //         })
            //         .catch((e: Error) => {
            //             console.log(e);
            //         });
            //     // resendOtp();
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [""]);
    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getUserDetailsByID(parseInt(userId), config)
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        setUserDetails(response?.data?.UserDetails);
                        localStorage.setItem(
                            "isKycDone",
                            response?.data?.UserDetails?.isKYCverified
                        );
                        if (response?.data?.userDetails?.isKYCverified == 1) {
                            alert("kyc already verified ");
                            navigate("/dashboard");
                        }
                    } else {
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    }, []);
    const sendOtp = () => {
        var re1 = new RegExp(/^\d{12}$/);
        if (!re1.test(aadharNumber)) {
            alert("Inalid Aadhar number");
            return;
        }
        const payload = {
            aadharNumber: aadharNumber,
        };
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        LoginServices.sendOtpKyc(payload, config).then((response) => {
            if (response.data.Status == 1) {
                setAadharResponse(response.data.RequestID);
                setShowOtp(true);
            } else if (response.data.Status == 0) {
                toast.error(`${"unable to verify aadhar please try again"}`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }
        });
    };
    const resendOtp = () => {
        // setLoading(true);
        const payload = {
            aadharNumber: aadharNumber,
        };
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        LoginServices.sendOtpKyc(payload, config).then((response) => {});
    };
    const verifyOtp = () => {
        const payload = {
            OTP: otpCode,
            RequestID: aadharResponse,
            // SessionId: captchaToken,
            aadharNumber: aadharNumber,
        };
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        LoginServices.sendVerifyOtp(payload, config).then((response) => {
            if (response.data.Status == 1) {
                // navigate.r
                if (window.confirm(response.data.Message)) {
                    navigate("/dashboard");
                    window.location.reload();
                }
            } else if (response.data.Status == 0) {
                alert(response.data.Message);
            }
        });
        // setLoading(true);
    };
    return (
        <>
            <div className="row main-row">
                {/* <div className="col-2 left-side">
                    <DashboardNav active={"kyc"}></DashboardNav>
                </div> */}
                <div className="col-10 right-back">
                    <WalletBlcHeader wBlc={walletBlc}></WalletBlcHeader>
                    <div>
                        <div
                            className="row main-row body"
                            style={{
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                            <div
                                style={{
                                    width: "50%",
                                    background: "#e5e5e55e",
                                    padding: "25px",
                                }}>
                                <BounceLoader
                                    color={"#6865F0"}
                                    loading={loading}
                                    css={override}
                                    size={150}
                                />
                                {!showOtp && (
                                    <div>
                                        <p className="titel">Link Aadhaar</p>
                                        <div className="ip-inr-main">
                                            <label className="label">
                                                Aadhaar Number
                                            </label>
                                            <input
                                                onChange={(e) => {
                                                    setAadharNumber(
                                                        e.target.value
                                                    );
                                                }}
                                                type="text"
                                                maxLength={12}
                                                name="amount"
                                                id="amount"
                                                autoComplete="off"
                                                className="add-blc-ip"
                                                placeholder="Enter your aadhaar number"
                                            />
                                        </div>

                                        {/* <div className="ip-inr-main">
                                            <label className="label">
                                                Captcha{" "}
                                            </label>
                                            <input
                                                onChange={(e) => {
                                                    setCaptchaIpNumber(
                                                        e.target.value
                                                    );
                                                }}
                                                type="text"
                                                name="amount"
                                                id="amount"
                                                autoComplete="off"
                                                className="add-blc-ip"
                                                placeholder="Captcha code"
                                            />
                                        </div> */}
                                        {/* <div className="captcha-img d-flex p-3">
                                            <img
                                                src={captchImage}
                                                alt=""
                                                style={{ margin: "auto" }}
                                            />
                                            <button
                                                onClick={resendOtp}
                                                className="btn btn-sm resend-btn">
                                                Refresh
                                            </button>
                                        </div> */}

                                        <div className="ip-inr-main d-flex mt-3">
                                            <button
                                                onClick={sendOtp}
                                                className="btn add-btn">
                                                Send OTP
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {showOtp && (
                                    <div>
                                        <div className="ip-inr-main">
                                            <label className="label">OTP</label>
                                            <input
                                                onChange={(e) => {
                                                    setverifyOtp(
                                                        e.target.value
                                                    );
                                                }}
                                                type="number"
                                                name="amount"
                                                id="amount"
                                                autoComplete="off"
                                                className="add-blc-ip"
                                                placeholder="Enter OTP "
                                            />
                                        </div>
                                        <div className="ip-inr-main d-flex p-3">
                                            <button
                                                onClick={verifyOtp}
                                                className="btn add-btn ">
                                                Verify OTP
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    );
}

export default Kyc;
