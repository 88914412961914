import React from "react";
import "./dashboard.css";
import Modal from "react-modal";
import cone from "../../assets/cone.png";
Modal.setAppElement("#root");
function Success(props: any) {
    /*  position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 4px;
    outline: none;
    padding: 20px; */
    const customStyles = {
        overlay: {
            // backgroundColor: 'papayawhip'
            // backgroundColor:''
            zIndex: 10000,
            overflow: "scroll",
        },
    };
    return (
        <>
            <Modal
                isOpen={props.showSucces}
                style={customStyles}
                className="custom-modal"
                shouldCloseOnOverlayClick={false}
                onRequestClose={() => {
                    props.setShowSuccess(false);
                }}>
                <div className="success-modal-custom">
                    <img src={cone} alt="success" width={"200px"} />
                    <h3>Your Transaction is successful!</h3>
                    <p>
                        You will be receiving a Confirmation email with order
                        details
                    </p>
                    <button
                        onClick={() => {
                            props.setShowSuccess(false);
                            window.location.reload();
                        }}>
                        Close
                    </button>
                </div>
            </Modal>
        </>
    );
}

export default Success;
