import React from "react";
import cone from "../../assets/failed.webp";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
function DummySuccess() {
    const [counter, setCounter] = useState(2);
    const Navigate = useNavigate();
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setCounter((prevCounter) => prevCounter - 1);
    //     }, 1000);

    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, []); // Run the effect only once when the component mounts

    // useEffect(() => {
    //     if (counter === 0) {
    //         // window.close();
    //         if (localStorage.getItem("token")) {
    //             Navigate("/dashboard/deposits");
    //         } else {
    //             let recId = localStorage.getItem("recId");
    //             if (recId) {
    //                 Navigate(`/payment/${recId}`);
    //             } else {
    //                 Navigate(`/`);
    //             }
    //         }
    //     }
    // }, [counter]);
    return (
        <div className="dummy-success">
            <div className="success-modal-custom">
                <img src={cone} alt="success" width={"200px"} />
                <h3>Payment Failed!</h3>
                <p>Please Try Again</p>
                {/* <p style={{ textDecoration: "underline" }}>
                    You will redirect in {counter}
                </p> */}
            </div>
        </div>
    );
}

export default DummySuccess;
