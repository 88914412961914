import "./Bbps.css";
import { useForm } from "react-hook-form";
import Navbar from "./Bbpsnav";
import { useNavigate, useParams } from "react-router-dom";
import header_Logo from "../../assets/bbps/bharat_pay.png";
import React, { useState, useEffect } from "react";
import Submenu from "./Bbpssubmenu";
import Spinner from "../spinner";

import LoginServices from "../../services/LoginServices";
export default function Bbps() {
    const [success, setsuccess] = useState<any>();
    const registersuccess = sessionStorage.getItem("registersuccess");
    useEffect(() => {
        if (registersuccess) {
            setsuccess(JSON.parse(registersuccess));
        }
    }, []);
    const navigate = useNavigate();
    const [spinner, setspinner] = useState<any>(false);
    const userId = localStorage.getItem("userid");
    const [status, setStatus] = useState<any>();
    const [trandate, settransdate] = useState<any>();
    const stat = sessionStorage.getItem("status");

    useEffect(() => {
        let transdate;
        if (stat) {
            setStatus(JSON.parse(stat));
            transdate = new Date(JSON.parse(stat)?.txnList?.txnDate);

            const date = `${String(transdate.getDate()).padStart(
                2,
                "0"
            )}-${String(transdate.getMonth()).padStart(
                2,
                "0"
            )}-${transdate.getFullYear()}`;
            settransdate(date);
        }
    }, []);
    return (
        <>
            {spinner && <Spinner />}
            <div className="row main-row">
                <div className="col-10 dashboard-right-back">
                    <div className="header-bbps">
                        <div className="BbpsWrapper">
                            <div className="Bbps-heading">
                                <p>Complaints</p>
                                <img src={header_Logo} alt="" />
                            </div>
                            <div className="Bbps-Navbar">
                                <Navbar active="complaints" />
                            </div>
                            {/* <div className="Complaint-formwrapper"> */}
                            {/* <Submenu active="search Complaint" /> */}
                            <div
                                className="Complaint-status"
                                style={{
                                    width: "80%",
                                    justifyItems: "left",
                                }}>
                                <h1 style={{ justifySelf: "center" }}>
                                    Transaction Details{" "}
                                </h1>
                                <div className="Complaint-status-items">
                                    <div className="Complaint-status-item">
                                        <h3>Agent ID</h3>
                                        <p>:</p>
                                        <h5>{status?.txnList?.agentId}</h5>
                                    </div>
                                    <div className="Complaint-status-item">
                                        <h3>Amount</h3>
                                        <p>:</p>
                                        <h5>
                                            {Number(status?.txnList?.amount) /
                                                100}
                                        </h5>
                                    </div>
                                    <div className="Complaint-status-item">
                                        <h3>Biller Name</h3>
                                        <p>:</p>
                                        <h5>{status?.txnList?.billerName}</h5>
                                        {/* <h5></h5> */}
                                    </div>
                                    <div className="Complaint-status-item">
                                        <h3>Txn Reference Id</h3>
                                        <p>:</p>
                                        <h5>
                                            {status?.txnList?.txnReferenceId}
                                        </h5>
                                    </div>
                                    <div className="Complaint-status-item">
                                        <h3>Txn Date</h3>
                                        <p>:</p>
                                        <h5>{trandate}</h5>
                                    </div>
                                    <div className="Complaint-status-item">
                                        <h3>Txn Status</h3>
                                        <p>:</p>
                                        <h5>{status?.txnList?.txnStatus}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
