import print from "../../assets/print.svg";
import image21 from "../../assets/bbps/bharat_pay.png";
import "./Bbps.css";
import Navbar from "./Bbpsnav";
import { useNavigate } from "react-router-dom";
export default function Bbps() {
    const Transcations = [
        {
            TransactionID: 123456,
            TransactionDate: "24 feb 2022",
            AgentID: 123456,
            BillerName: "Kolias",
            Amount: "400",
            Status: "Completed",
            print: "img",
        },
        {
            TransactionID: 123456,
            TransactionDate: "24 feb 2022",
            AgentID: 123456,
            BillerName: "Mahesh ",
            Amount: " 400",
            Status: "pending",
            print: "img",
        },
        {
            TransactionID: 123456,
            TransactionDate: "24 feb 2022",
            AgentID: 123456,
            BillerName: "Solani",
            Amount: " 400",
            Status: "Completed",
            print: "img",
        },
        {
            TransactionID: 123456,
            TransactionDate: "24 feb 2022",
            AgentID: 123456,
            BillerName: "Samiksha",
            Amount: " 400",
            Status: "Completed",
            print: "img",
        },
        {
            TransactionID: 123456,
            TransactionDate: "24 feb 2022",
            AgentID: 123456,
            BillerName: "Raj",
            Amount: " 400",
            Status: "Completed",
            print: "img",
        },
        {
            TransactionID: 123456,
            TransactionDate: "24 feb 2022",
            AgentID: 123456,
            BillerName: "Kolias",
            Amount: " 400",
            Status: "pending",
            print: "img",
        },
        {
            TransactionID: 123456,
            TransactionDate: "24 feb 2022",
            AgentID: 123456,
            BillerName: "Kolias",
            Amount: " 400",
            Status: "pending",
            print: "img",
        },
        {
            TransactionID: 123456,
            TransactionDate: "24 feb 2022",
            AgentID: 123456,
            BillerName: "Kolias",
            Amount: " 400",
            Status: "Completed",
            print: "img",
        },
        {
            TransactionID: 123456,
            TransactionDate: "24 feb 2022",
            AgentID: 123456,
            BillerName: "Raj",
            Amount: " 400",
            Status: "pending",
            print: "img",
        },
        {
            TransactionID: 123456,
            TransactionDate: "24 feb 2022",
            AgentID: 123456,
            BillerName: "mahesh",
            Amount: " 400",
            Status: "Completed",
            print: "img",
        },
    ];
    const Navigate = useNavigate();

    return (
        <div className="row main-row">
            <div className="col-9 dashboard-right-back">
                <div className="header-bbps">
                    <div className="BbpsWrapper">
                        <div className="Bbps-heading">
                            <p>Bill Payments Transctions</p>
                            <img src={image21} alt="" />
                        </div>
                        <div className="Bbps-Navbar">
                            <Navbar active="transaction" />
                        </div>
                        <div className="TranscationWrapper">
                            <div className="transcations">
                                <div className="filter">
                                    <div className="filter-control">
                                        <label htmlFor="To Date">To Date</label>
                                        <input
                                            className="input"
                                            type={"date"}
                                            name="from Date"
                                        />
                                    </div>
                                    <div className="filter-control">
                                        <label htmlFor="FromDate">
                                            From Date
                                        </label>
                                        <input
                                            className="input"
                                            type={"date"}
                                            name="fromDate"
                                        />
                                    </div>
                                    <div className="filter-control">
                                        <label htmlFor="MobileNumber">
                                            Mobile Number
                                        </label>
                                        <input
                                            className="input"
                                            type={"number"}
                                            name="MobileNumber"
                                        />
                                    </div>
                                    <div className="filter-control">
                                        <label htmlFor="TransactionID">
                                            Transaction ID
                                        </label>
                                        <input
                                            className="input"
                                            type={"number"}
                                            name="TransactionID"
                                        />
                                    </div>
                                    <button>Submit</button>
                                </div>
                                <div className="transcationTable">
                                    <table>
                                        <tr>
                                            <th>Transaction ID </th>
                                            <th>Transaction Date</th>
                                            <th>Agent ID</th>
                                            <th>Biller Name</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            <th>Print</th>
                                        </tr>
                                        {Transcations.map((element) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        {element.TransactionID}
                                                    </td>
                                                    <td>
                                                        {
                                                            element.TransactionDate
                                                        }
                                                    </td>
                                                    <td>{element.AgentID}</td>
                                                    <td>
                                                        {element.BillerName}
                                                    </td>
                                                    <td>
                                                        &#8377; {element.Amount}
                                                    </td>
                                                    <td>{element.Status}</td>
                                                    <td>
                                                        <button>
                                                            <img
                                                                src={print}
                                                                alt=""
                                                            />
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
