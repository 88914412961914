import React from "react";
import "../components/dashboard/Bbps.css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
// import { FaRegWindowClose } from "react-icons/fa";
function modal() {
    const CancelHandler = (e: any) => {
        e.stopPropagation();
    };

    const openhandler = (e: any) => {
        e.stopPropagation();
    };
    return (
        <>
            <div className="modal-spinner" onClick={CancelHandler}>
                <div className="modal-content-spinner" onClick={openhandler}>
                    <div className="modal-content-spinner">
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                            <CircularProgress />
                        </Box>
                    </div>
                </div>
            </div>
        </>
    );
}

export default modal;
