import React,{useEffect,useState} from 'react'
import {Modal,Button,Table,Container,Row,Col} from 'react-bootstrap'
import LoginServices from '../../services/LoginServices';
import Moment from 'react-moment';

export default function LastTenTransactions(props:any) {


    const [transactionsList, setTransactionsList] = useState<any>([])



    useEffect(() => {
        if (props.userId) {

            let config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            };
            LoginServices.getLastTenTransactions(parseInt(props.userId),props.payout_contactId, config)
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        // setWalletBlc(response?.data?.walletBalance)
                        setTransactionsList(response.data.PayoutTransactions)
                        
                    }
                    else {
                        alert(response?.data?.Message)
                    }

                })
                .catch((e: Error) => {
                    console.log(e);
                    // setShowSection('add-img')

                });
           
        } else {
            
        }
    }, [])


    const tableData = transactionsList.map((item:any,index:number) => {
        return (
            <tr>     
                <td>{index+1}</td>
                <td>{item.wallet_transactionId}</td>
                <td><Moment format="DD MMM YYYY hh:mm A">
                        {item?.transactionDateTime}
                    </Moment>
                </td>
                <td>{item.transactionType == 1 && <span>Recharge</span>}
                    {item.transactionType == 2 && <span>Payout</span>}
                    {item.transactionType == 3 && <span>Wallet Debit</span>}
                    {item.transactionType == 4 && <span>Wallet Credit</span>}
                </td>
                <td>{item.mobileNumber}</td>
                <td>
                    {(item?.transactionType == 2 || item?.transactionType == 3 || item?.transactionType == 6) && <span style={{ color: '#F0532D', fontWeight: 500 }}>₹{item?.amount}</span>}
                </td>
                <td>
                    {item?.transactionStatus == 1 &&
                        <span className="completed-span"> Processed</span>
                    }
                    {item?.transactionStatus == 2 &&
                        <span className="pending-span"> Pending</span>
                    }
                    {item?.transactionStatus == 3 &&
                        <span className="failed-span"> Failed</span>
                    }
                    {item?.transactionStatus == 4 &&
                        <span className="reversed-span"> Reversed</span>
                    }
                </td>
            </tr>
        )
    })



    // {
    //     "wallet_transactionId": 75,
    //     "userId": 1128890,
    //     "amount": 100,
    //     "GSTAmount": 0,
    //     "charges": 0,
    //     "subtotalAmount": 0,
    //     "transactionType": 2,
    //     "transactionDateTime": "2022-01-14T13:35:22.000Z",
    //     "transactionStatus": 2,
    //     "razorpay_TransactionId": "pout_IjY9ENunQtUpNk",
    //     "description": "1128890 Amount Rs.100 has been transferred to fa_IjBjnANIGD8L5C",
    //     "before_balance": 13827.5,
    //     "after_balance": 13727.5,
    //     "payout_contactId": 913,
    //     "notes": "test",
    //     "utr": "NA",
    //     "InvoiceFile": "NA",
    //     "createdAt": "2022-01-14T08:05:22.000Z",
    //     "updatedAt": "2022-01-14T08:05:22.000Z",
    //     "bankAccountName": "rahul",
    //     "bankIFSCCode": "ICIC0002444",
    //     "bankAccountNumber": "194201001018",
    //     "mobileNumber": "7702000342",
    //     "name": "rahul"
    // }

    return (
        <div>
            <Modal
            show={props.show}
            onHide={() => props.onHide(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                Your Last Transactions
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
           
            <Container>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th></th>
                        <th>Order ID</th>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Mobile No.</th>
                        <th>Withdrawal</th>
                        <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData}
                    </tbody>
                </Table>
                
            </Container>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
        </div>
    )
}
