import React from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import image1 from "../../assets/bbps/icons/life.svg";
import image2 from "../../assets/bbps/icons/health.svg";

import image21 from "../../assets/bbps/bharat_pay.png";
import "./Bbps.css";
import Navbar from "./Bbpsnav";
import { useNavigate } from "react-router-dom";

export default function Bbps() {
    const Navigate = useNavigate();
    const BillpayHandler = (e: any) => {
        Navigate(`/dashboard/bbps/Biller/${e.currentTarget.id}`);
    };
    return (
        <div className="row main-row">
            <div className="col-10 dashboard-right-back">
                <div className="header-bbps">
                    <div className="BbpsWrapper">
                        <div className="Bbps-heading">
                            <p>BBPS</p>
                            <img src={image21} alt="" />
                        </div>
                        <div className="Bbps-Navbar">
                            <Navbar active="billpay" />
                        </div>
                        <div className="Insurance-Wrapper">
                            <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="Life Insurance">
                                <img
                                    className="paymentType_icon"
                                    src={image1}
                                    alt=""
                                />
                                <h2>Life Insurance</h2>
                            </button>
                            <button
                                className="payment-type"
                                onClick={BillpayHandler}
                                id="Health Insurance">
                                <img
                                    className="paymentType_icon"
                                    src={image2}
                                    alt=""
                                />
                                <h2>Health Insurance</h2>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
