import React, { useState, useEffect, useRef } from "react";
import LoginServices from "../../services/LoginServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Success from "./Success";
import Modal from "react-modal";
const PayByRazorPay = (props: any) => {
    const componentMounted = useRef(true);
    const [userIpAddress, setUserIpAddress] = useState("");
    const [showSucces, setShowSuccess] = useState<boolean>(false);
    const isScriptLoaded = useRef(false);
    const [showIframe, setShowIframe] = useState(false);
    const [url, setUrl] = useState();
    const options = {
        theme: "black",
        orderToken: "",
        channelId: "WEB",
        TransactionId: "",
        paymentMode: "CREDIT_DEBIT",
        countryCode: "91",
        mobileNumber: props?.number,
        emailId: props.email,
        showSavedCardsFeature: false,

        // image: 'https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png',
        successHandler: async function (response: any) {
            setShowSuccess(true);
        },
        failedHandler: async function (response: any) {
            console.log(response);
            // alert("Something went wrong try again");
        },
    };
    useEffect(() => {
        const id = document.getElementById("pinelabs");
        // console.log(id);
        if (!id) {
            const script = document.createElement("script");
            script.src =
                "https://checkout.pluralonline.com/v2/web-sdk-checkout.js";
            script.async = true;
            script.id = "pinelabs";
            document.body.appendChild(script);
        }

        fetch("https://api.ipify.org/?format=json")
            .then((Response) => {
                return Response.json();
            })
            .then((response) => {
                if (componentMounted.current) {
                    setUserIpAddress(response.ip);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        return () => {
            // This code runs when component is unmounted
            componentMounted.current = false; // (4) set it to false when we leave the page
        };
    }, []);
    //
    const openPayModal = () => {
        if (props?.passBlc?.length == 0) {
            alert("Amount is required field");
            return;
        } else if (props?.number?.length == 0) {
            alert("number is required field");
            return;
        } else if (props?.email?.length == 0) {
            alert("email is required field");
            return;
        } else if (props?.notes?.length == 0) {
            alert("notes is required field");
            return;
        }

        if (parseFloat(props.passBlc.replace(/,/g, "")) > 500000) {
            alert("Deposits more than 5,00,000 not allowed");
            return;
        }

        const payload = {
            amount: parseFloat(props?.passBlc?.replace(/,/g, "")),
            mobileNumber: props.number,
            emailId: props.email,
            notes: props.notes,
            ipAddress: userIpAddress,
            paymentgateway: props.Gateway,
        };
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };

        LoginServices.createPineLabsOrder(payload, config)
            .then((response: any) => {
                if (response.data.Status == 1) {
                    props.setNotes("");
                    props.setEmail("");
                    props.setNumber("");
                    props.setAddBlc("");
                    setShowIframe(true);
                    setUrl(response.data.RedirectURL);
                    // localStorage.setItem("receiptId", response.data.setShowIframereceiptId);
                } else if (response.data.Status == 0) {
                    toast.error(`${response.data.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };
    const onClose = () => {
        setShowIframe(false);
        props.fetchData();
    };
    return (
        <>
            {showSucces && (
                <Success
                    showSucces={showSucces}
                    setShowSuccess={setShowSuccess}
                />
            )}
            {showIframe && (
                <div>
                    <div className="modal-backdrop" onClick={onClose}>
                        <div
                            className="modal-content-iframe"
                            onClick={(e) => e.stopPropagation()}>
                            <span
                                className="close-btn-iframe"
                                onClick={onClose}>
                                &times;
                            </span>
                            <iframe
                                title="Dynamic Iframe"
                                height={"100%"}
                                width={"100%"}
                                src={url}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div style={{ margin: "auto" }}>
                {/* {isNaN(parseFloat(props.passBlc.replace(/,/g, ""))) &&
                    props?.passBlc?.length && (
                        <p className="error-text">Only Numbers Allowed</p>
                    )} */}
                <button
                    disabled={isNaN(
                        parseFloat(props?.passBlc?.replace(/,/g, ""))
                    )}
                    onClick={openPayModal}
                    className="add-btn">
                    Add
                </button>
                <ToastContainer />
            </div>
        </>
    );
};

export default PayByRazorPay;
