import React from "react";
import { Container } from "react-bootstrap";

export default function TermsPage() {
    return (
        <div>
            <Container>
                <h1 style={{ textAlign: "center", margin: "0.5rem 0" }}>
                    Cancellation and Refund Policy
                </h1>
                <h4>Cancellation & Refund Charges:</h4>
                <p>
                    Our cancellation policy purely works on status of bill
                    payment. irrespective of payment priority, we charge 2.5%
                    flat cancellation & refund charges, when you use our
                    services as registered user.
                </p>
                <h4>Cancellation & Refund Terms:</h4>
                <p>
                    Cancellation is only possible if payment settlement from our
                    payment gateway / bank has not taken place.Once the
                    settlement has been initiated by our Bankers / Online
                    Payment system, we will not be able to cancel the
                    transaction request.
                </p>
                <h4>Terms of Refund Policy :</h4>

                {/* <h5>SECTION II - GENERAL CONDITIONS</h5> */}
                <p>
                    Rules for Refund and Claims Claims will be settled, and
                    refund will be automated within 5-7 working days of the
                    transaction cancellation. Maximum 5-7 working days is the
                    time frame for the user-initiated refund claims.In case of
                    system failure or any technical error, automated refund will
                    be initiated by system itself and amount will be credited
                    within 5-7 working day (As per bank).In worst to worst if
                    you face any problem in getting refund, please share a
                    request on support@payville.in or 9704406409. we will surely
                    attend your concerns within shortest possible time.
                </p>
                <h4>Accountability of the company</h4>
                <p>
                    In the event of a dispute/Chargeback, Credit mint Private
                    Limited records should constitute indisputable evidence of
                    the transactions carried out through the use of the user's
                    account and financial holdings.
                </p>

                <p>
                    The user is entirely responsible for any information
                    provided by him or her, including his or her bank card data
                    and any other papers he or she provides. The company cannot
                    be held liable for any disputes or erroneous payments made
                    by the user. In the event of applicable faults or any other
                    genuine disagreement, the company will consider cancellation
                    or reimbursement.
                </p>
                <p>
                    If the company has to commence any refunds in the user's
                    account. In that case, the company will notify him or her.
                    The company will initiate the refund procedure in a few
                    business days after ultimately confirming the refund method
                    and verifying the legitimacy of the reason supplied by the
                    user.
                </p>
                <p>
                    All of the procedures provided here are in full compliance
                    with Credit mint Private Limited's most recent laws and
                    regulations. They will not accept any deceptive practices
                    used by consumers to gain refunds. Users are expected to
                    adhere to the company's general terms and conditions and are
                    bound by the company's criteria.
                </p>
            </Container>
        </div>
    );
}
