import "./Bbps.css";
import Navbar from "./Bbpsnav";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import header_Logo from "../../assets/bbps/bharat_pay.png";
import assured from "../../assets/assured.svg";
export default function Bbps() {
    const { type } = useParams();
    const [successdetails, setsuccessdetails] = useState<any>();
    const success = sessionStorage.getItem("success");
    const billername = sessionStorage.getItem("billerName");
    const billerid = sessionStorage.getItem("billerid");

    const userId = localStorage.getItem("userid");
    useEffect(() => {
        if (success) {
            setsuccessdetails(JSON.parse(success));
            // console.log(JSON.parse(success));
        }
    }, []);
    return (
        <div className="row main-row">
            <div className="col-9 dashboard-right-back">
                <div className="header-bbps">
                    <div className="BbpsWrapper">
                        <div className="Bbps-heading">
                            <p>BBPS</p>
                            <img src={header_Logo} alt="" />
                        </div>
                        {/* <div className="Bbps-Navbar">
                            <Navbar active="billpay" />
                        </div> */}
                        <div className="successWrappe">
                            <div className="successWrapper">
                                <img src="assets/img/assured.svg" alt="" />
                                <p>Your Transaction is Successfull..!</p>
                                <div className="success-details">
                                    <div className="success-detail">
                                        <p>Biller Name</p>
                                        <p>:</p>
                                        <p>{billername}</p>
                                    </div>
                                    <div className="success-detail">
                                        <p>Biller Id</p>
                                        <p>:</p>
                                        <p>{billerid}</p>
                                    </div>
                                    <div className="success-detail">
                                        <p>Transaction ID</p>
                                        <p>:</p>
                                        <p>{successdetails?.txnRefId}</p>
                                    </div>
                                    <div className="success-detail">
                                        <p>Customer Name</p>
                                        <p>:</p>
                                        <p>
                                            {successdetails?.RespCustomerName}
                                        </p>
                                    </div>
                                    <div className="success-detail">
                                        <p>Mobile Number</p>
                                        <p>:</p>
                                        <p>
                                            {localStorage.getItem(
                                                "mobilenumber"
                                            )}
                                        </p>
                                    </div>
                                    <div className="success-detail">
                                        <p>Bill Date</p>
                                        <p>:</p>
                                        <p>{successdetails?.RespBillDate}</p>
                                    </div>
                                    <div className="success-detail">
                                        <p>Bill Number</p>
                                        <p>:</p>
                                        <p>{successdetails?.RespBillNumber}</p>
                                    </div>
                                    <div className="success-detail">
                                        <p>Due Date</p>
                                        <p>:</p>
                                        <p>{successdetails?.RespDueDate}</p>
                                    </div>
                                    <div className="success-detail">
                                        <p>Bill amount</p>
                                        <p>:</p>
                                        <p>
                                            ₹{" "}
                                            {Number(
                                                successdetails?.RespAmount
                                            ) / 100}
                                        </p>
                                    </div>
                                    <div className="success-detail">
                                        <p>CCF</p>
                                        <p>:</p>
                                        <p>₹ {successdetails?.CustConvFee}</p>
                                    </div>
                                    <div className="success-detail">
                                        <p>Total Amount</p>
                                        <p>:</p>
                                        <p>
                                            ₹{" "}
                                            {Number(
                                                successdetails?.RespAmount
                                            ) /
                                                100 +
                                                Number(
                                                    successdetails?.CustConvFee
                                                )}{" "}
                                        </p>
                                    </div>
                                    <div className="success-detail">
                                        <p>Transaction Date & time</p>
                                        <p>:</p>
                                        <p>
                                            {
                                                successdetails?.TransactionDateTime
                                            }
                                        </p>
                                    </div>
                                    <div className="success-detail">
                                        <p>Init Channel</p>
                                        <p>:</p>
                                        <p>AGT</p>
                                    </div>
                                    <div className="success-detail">
                                        <p>Payment Mode</p>
                                        <p>:</p>
                                        <p>WALLET</p>
                                    </div>
                                    <div className="success-detail">
                                        <p>Transaction Status</p>
                                        <p>:</p>
                                        <p>Successful</p>
                                    </div>
                                    <div className="success-detail">
                                        <p>Approval</p>
                                        <p>:</p>
                                        <p>
                                            {successdetails?.approvalRefNumber}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
