import React from 'react';

function Services() {
  return (
  <>
 {/* <!-- ======= Services Section ======= --> */}

 <div className="container" className="services-container">
 <section id="services" className="services">

<div className="container" data-aos="fade-up">

  <header className="section-header">
  <h1 className="heading-1">Services We Offer</h1>
  </header>

  <div className="row gy-4">

    <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="1000">
      <div className="service-box">
      <img src="assets/img/home/services2.png" className="img-fluid" alt="" />
        <h3>Rent Payment</h3>
        <p>Pay Ville is India's first Utility Bills Mobile Payment Application, which offers Rent Payments from any mode of Payments like Credit Card, Debit Card, Net Banking, etc.</p>
        <a href="#" className="read-more"><img src="assets/img/home/arrow_left.png" className="img-fluid" alt="" /></a>
      </div>
    </div>

    <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="0000">
      <div className="service-box">
      <img src="assets/img/home/services1.png" className="img-fluid" alt="" />
        <h3>Request Payment</h3>
        <p>Merchants get paid immediately by sharing payment link via SMS to customers.</p>
        <a href="#" className="read-more"><img src="assets/img/home/arrow_left.png" className="img-fluid" alt="" /></a>
      </div>
    </div>

   
   

    

  </div>

</div>

</section>

 </div>
 
  </>
  );
}

export default Services;