import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import Razor from "./Razor";
import { Modal, Row, Col } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
let abortController = new AbortController();
function Transactions() {
    const navigate = useNavigate();
    const [walletBlc, setWalletBlc] = useState<any>(0);
    const [addBlc, setAddBlc] = useState<number>();
    const [walletTransactions, setWalletTransactions] = useState<any>();
    const [walletTransactionsfilter, setWalletTransactionsfilter] =
        useState<any>();
    const userId = localStorage.getItem("userid");
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [currentRow, setCurrentRow] = useState<any>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [payOutFilter, setPayoutFilter] = useState<boolean>(false);
    const [depositFilter, setDepositFilter] = useState<boolean>(false);
    const [walletFilter, setWalletFilter] = useState<boolean>(false);
    const [mobile, setmobile] = useState<any>("");
    const [payloaddata, setpayloaddata] = useState<any>();
    const [creditTotal, setCreditTotal] = useState<any>();
    const [debitTotal, setDebitTotal] = useState<any>();
    const getwalletTransactions = () => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };

            LoginServices.getWalletTransactions(
                parseInt(userId),
                config,
                "transactions"
            )
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        setWalletTransactions(
                            response?.data?.WalletTransactions
                        );
                        setWalletTransactionsfilter(
                            response?.data?.WalletTransactions
                        );
                        // setWalletBlc(response?.data?.walletBalance)
                    } else {
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    };
    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
            getwalletTransactions();
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
        return () => {
            abortController.abort();
        };
    }, [""]);
    const getwalletTransactionByDate = (userId: any) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        LoginServices.getWalletTransactionsByDate(
            parseInt(userId),
            payloaddata,
            config,
            "transactions"
        )
            .then((response: any) => {
                if (response.data.Status == 1) {
                    setWalletTransactions(response?.data?.WalletTransactions);
                    let data = response?.data?.WalletTransactions;
                    if (walletFilter) {
                        if (data.length > 0) {
                            data = data?.filter(
                                (el: any) =>
                                    el.transactionType == 3 ||
                                    el.transactionType == 4 ||
                                    el.transactionType == 2
                            );
                        }
                    }
                    if (depositFilter) {
                        if (walletTransactions?.length > 0) {
                            data = data?.filter(
                                (el: any) =>
                                    el.transactionType == 1 ||
                                    el.transactionType == 2
                            );
                        }
                    }
                    if (payOutFilter) {
                        if (walletTransactions?.length > 0) {
                            data = data?.filter(
                                (el: any) => el.transactionType == 2
                            );
                        }
                    }
                    setWalletTransactionsfilter(data);
                    // setpayloaddata("");
                } else if (response.data.Status == 0) {
                    alert(response.data.Message);
                }
            })

            .catch((e: any) => {
                console.log(e);
                // if (mobile.length != 10) {
                alert("some thing went wrong please try again");
            });
    };
    const filterByDate = () => {
        if (new Date(startDate) > new Date(endDate)) {
            alert("Start date is not more than end date");
            return;
        }
        // const payload = {
        //     fromDate: startDate,
        //     toDate: endDate,
        //     searchString: mobile,
        // };

        if (userId) {
            getwalletTransactionByDate(userId);
        }
    };
    const getTDetails = (id: any) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        setCurrentRow({});
        if (userId) {
            LoginServices.getTransactionsDetailsByID(
                parseInt(userId),
                id,
                config
            )
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        setCurrentRow(response?.data?.walletTransaction);
                    } else {
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    };
    const payoutchange = (e: any) => {
        if (e.target.checked == true) {
            setPayoutFilter(true);
            if (walletFilter && depositFilter) {
                setWalletTransactionsfilter(walletTransactions);
            } else if (walletFilter) {
                if (walletTransactions?.length > 0) {
                    const filter = walletTransactions?.filter(
                        (el: any) =>
                            el.transactionType == 3 ||
                            el.transactionType == 4 ||
                            el.transactionType == 2
                    );
                    setWalletTransactionsfilter(filter);
                }
            } else if (depositFilter) {
                if (walletTransactions?.length > 0) {
                    const filter = walletTransactions?.filter(
                        (el: any) =>
                            el.transactionType == 1 || el.transactionType == 2
                        //  ||
                        // el.transactionType == 5 ||
                        // el.transactionType == 4
                    );
                    setWalletTransactionsfilter(filter);
                }
            } else if (e.target.checked == true) {
                if (walletTransactions?.length > 0) {
                    const filter = walletTransactions?.filter(
                        (el: any) => el.transactionType == 2
                    );
                    setWalletTransactionsfilter(filter);
                }
            }
        } else if (e.target.checked == false) {
            setPayoutFilter(false);
            if (walletFilter && depositFilter) {
                if (walletTransactions?.length > 0) {
                    const filter = walletTransactions?.filter(
                        (el: any) =>
                            el.transactionType == 1 ||
                            el.transactionType == 3 ||
                            el.transactionType == 4
                        // ||
                        // el.transactionType == 5
                    );
                    setWalletTransactionsfilter(filter);
                }
            } else if (walletFilter) {
                if (walletTransactions?.length > 0) {
                    const filter = walletTransactions?.filter(
                        (el: any) =>
                            el.transactionType == 3 || el.transactionType == 4
                    );
                    setWalletTransactionsfilter(filter);
                }
            } else if (depositFilter) {
                if (walletTransactions?.length > 0) {
                    const filter = walletTransactions?.filter(
                        (el: any) => el.transactionType == 1
                        //  ||
                        // el.transactionType == 4 ||
                        // el.transactionType == 5
                    );
                    setWalletTransactionsfilter(filter);
                }
            } else if (!depositFilter && !walletFilter) {
                setWalletTransactionsfilter(walletTransactions);
            }
        }
    };
    const depositchange = (e: any) => {
        if (e.target.checked == true) {
            setDepositFilter(true);
            if (walletFilter && payOutFilter) {
                setWalletTransactionsfilter(walletTransactions);
            } else if (payOutFilter) {
                if (walletTransactions?.length > 0) {
                    const filter = walletTransactions?.filter(
                        (el: any) =>
                            el.transactionType == 1 || el.transactionType == 2
                        // ||
                        // el.transactionType == 4 ||
                        // el.transactionType == 5
                    );
                    setWalletTransactionsfilter(filter);
                }
            } else if (walletFilter) {
                if (walletTransactions?.length > 0) {
                    const filter = walletTransactions?.filter(
                        (el: any) =>
                            el.transactionType == 3 ||
                            el.transactionType == 1 ||
                            el.transactionType == 4
                    );
                    setWalletTransactionsfilter(filter);
                }
            } else if (e.target.checked == true) {
                if (walletTransactions?.length > 0) {
                    const filter = walletTransactions?.filter(
                        (el: any) => el.transactionType == 1
                        //  ||
                        // el.transactionType == 4 ||
                        // el.transactionType == 5
                    );
                    setWalletTransactionsfilter(filter);
                }
            }
        } else if (e.target.checked == false) {
            setDepositFilter(false);
            if (walletFilter && payOutFilter) {
                if (walletTransactions?.length > 0) {
                    const filter = walletTransactions?.filter(
                        (el: any) =>
                            el.transactionType == 3 ||
                            el.transactionType == 2 ||
                            el.transactionType == 4
                    );
                    setWalletTransactionsfilter(filter);
                }
            } else if (walletFilter) {
                if (walletTransactions?.length > 0) {
                    const filter = walletTransactions?.filter(
                        (el: any) =>
                            el.transactionType == 3 || el.transactionType == 4
                    );
                    setWalletTransactionsfilter(filter);
                }
            } else if (payOutFilter) {
                if (walletTransactions?.length > 0) {
                    const filter = walletTransactions?.filter(
                        (el: any) => el.transactionType == 2
                    );
                    setWalletTransactionsfilter(filter);
                }
            } else if (!payOutFilter && !walletFilter) {
                setWalletTransactionsfilter(walletTransactions);
            }
        }
    };
    const walletchange = (e: any) => {
        if (e.target.checked == true) {
            setWalletFilter(true);
            if (payOutFilter && depositFilter) {
                setWalletTransactionsfilter(walletTransactions);
            } else if (payOutFilter) {
                if (walletTransactions?.length > 0) {
                    const filter = walletTransactions?.filter(
                        (el: any) =>
                            el.transactionType == 3 ||
                            el.transactionType == 4 ||
                            el.transactionType == 2
                    );
                    setWalletTransactionsfilter(filter);
                }
            } else if (depositFilter) {
                if (walletTransactions?.length > 0) {
                    const filter = walletTransactions?.filter(
                        (el: any) =>
                            el.transactionType == 1 ||
                            el.transactionType == 3 ||
                            el.transactionType == 4
                        // ||
                        // el.transactionType == 5
                    );
                    setWalletTransactionsfilter(filter);
                }
            } else if (e.target.checked == true) {
                if (walletTransactions?.length > 0) {
                    const filter = walletTransactions?.filter(
                        (el: any) =>
                            el.transactionType == 3 || el.transactionType == 4
                    );
                    setWalletTransactionsfilter(filter);
                }
            }
        } else if (e.target.checked == false) {
            setWalletFilter(false);
            if (payOutFilter && depositFilter) {
                if (walletTransactions?.length > 0) {
                    const filter = walletTransactions?.filter(
                        (el: any) =>
                            el.transactionType == 1 || el.transactionType == 2
                        // ||
                        // el.transactionType == 5
                    );
                    setWalletTransactionsfilter(filter);
                }
            } else if (payOutFilter) {
                if (walletTransactions?.length > 0) {
                    const filter = walletTransactions?.filter(
                        (el: any) => el.transactionType == 2
                    );
                    setWalletTransactionsfilter(filter);
                }
            } else if (depositFilter) {
                if (walletTransactions?.length > 0) {
                    const filter = walletTransactions?.filter(
                        (el: any) => el.transactionType == 1
                        //  ||
                        // el.transactionType == 4 ||
                        // el.transactionType == 5
                    );
                    setWalletTransactionsfilter(filter);
                }
            } else if (!payOutFilter && !depositFilter) {
                setWalletTransactionsfilter(walletTransactions);
            }
        }
    };
    const mobilefilter = (e: any) => {
        setmobile(e.target.value);
        if (e.target.value.length > 0) {
            setpayloaddata((prev: any) => {
                return {
                    ...prev,
                    searchString: e.target.value,
                };
            });
        } else if (e.target.value.length == 0) {
            setpayloaddata((prev: any) => {
                delete prev.searchString;
                return {
                    ...prev,
                };
            });
        }
    };
    const exportdata = () => {
        const id = localStorage.getItem("userid");
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        console.log(payloaddata);
        LoginServices.exportWalletTransactionByDate(
            id,
            payloaddata,
            config,
            "transactions"
        ).then((Response: any) => {
            console.log(Response);
            if (Response.data.Status == 1) {
                // return;
                axios({
                    url: Response.data.filename,
                    method: "GET",
                    responseType: "blob",
                }).then((response) => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "transaction.csv");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
            } else {
                toast(`${Response.data.Message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        });
    };
    useEffect(() => {
        if (walletTransactionsfilter?.length > 0) {
            const creditedTransactions = walletTransactionsfilter
                ?.filter(
                    (el: any) =>
                        el.transactionType == 1 ||
                        el.transactionType == 4 ||
                        el.transactionType == 5
                )
                .map((element: any) => element.amount);

            const creditedTransactionTotal = creditedTransactions?.reduce(
                (total: any, current: any) => total + current,
                0
            );
            setCreditTotal(creditedTransactionTotal);

            const debitedTransactions = walletTransactionsfilter
                ?.filter(
                    (el: any) =>
                        el.transactionType == 2 || el.transactionType == 3
                )
                .map((element: any) => element.amount);
            const debitedTransactionTotal = debitedTransactions?.reduce(
                (total: any, current: any) => total + current,
                0
            );
            setDebitTotal(debitedTransactionTotal);
        }
    }, [walletTransactionsfilter]);
    const refreshHandler = () => {
        if (startDate && endDate) {
            if (userId) {
                getwalletTransactionByDate(userId);
            }
        } else {
            getwalletTransactions();
        }
    };
    return (
        <>
            <div className="row main-row">
                <div className="col-10 right-back">
                    <WalletBlcHeader wBlc={walletBlc}></WalletBlcHeader>
                    <p className="transaction-head">Transactions</p>
                    <div className="d-flex date-export-divs">
                        <div className="dates-ips">
                            <input
                                onChange={(e) => {
                                    setStartDate(e.target.value);

                                    if (e.target.value.length > 0) {
                                        setpayloaddata((prev: any) => {
                                            return {
                                                ...prev,
                                                fromDate: e.target.value,
                                            };
                                        });
                                    } else if (e.target.value.length == 0) {
                                        setpayloaddata((prev: any) => {
                                            delete prev.fromDate;
                                            return {
                                                ...prev,
                                            };
                                        });
                                    }
                                }}
                                className="date-ips"
                                type="Date"
                            />
                            <input
                                onChange={(e) => {
                                    setEndDate(e.target.value);
                                    if (e.target.value.length > 0) {
                                        setpayloaddata((prev: any) => {
                                            return {
                                                ...prev,
                                                toDate: e.target.value,
                                            };
                                        });
                                    } else if (e.target.value.length == 0) {
                                        setpayloaddata((prev: any) => {
                                            delete prev.toDate;
                                            return {
                                                ...prev,
                                            };
                                        });
                                    }
                                }}
                                className="date-ips"
                                type="Date"
                            />
                            <div className="searchwrapper">
                                <div className="searchicons">
                                    <BsSearch size={20} color="#6865F0" />
                                </div>
                                <input
                                    className="search"
                                    type="Search"
                                    onChange={mobilefilter}
                                    placeholder="Search with mobile number or account number"
                                />
                            </div>
                            <button onClick={filterByDate} className="go-btn">
                                Get the Results
                            </button>
                            <button onClick={refreshHandler} className="go-btn">
                                Refresh
                            </button>
                        </div>
                        <div className="export-div" onClick={exportdata}>
                            Export
                        </div>
                    </div>
                    {/* <div className="filter-checkboxs">
                        <h3>Show Only</h3>
                        <div className="filter-checkbox">
                            <input type={"checkbox"} onChange={payoutchange} />
                            <label htmlFor="Payouts">Payouts</label>
                        </div>
                        <div className="filter-checkbox">
                            <input type={"checkbox"} onChange={depositchange} />
                            <label htmlFor="Deposits">Deposits</label>
                        </div>
                        <div className="filter-checkbox">
                            <input type={"checkbox"} onChange={walletchange} />
                            <label htmlFor="Wallet to wallet transactions">
                                Wallet to wallet transactions
                            </label>
                        </div>
                    </div> */}
                    <div className="total-transaction-box-main table-responsive">
                        {walletTransactionsfilter?.length != 0 && (
                            <table className="table">
                                <thead>
                                    <tr style={{ height: "2rem" }}>
                                        <th
                                            id="total"
                                            colSpan={5}
                                            style={{
                                                textAlign: "right",
                                                fontSize: "18px",
                                            }}>
                                            Total :
                                        </th>
                                        <td
                                            style={{
                                                color: "rgb(240, 83, 45)",
                                                fontWeight: 700,
                                            }}>
                                            ₹ {debitTotal}
                                        </td>
                                        <td
                                            colSpan={2}
                                            style={{
                                                color: "rgb(13, 177, 75)",
                                                fontWeight: 700,
                                                textAlign: "left",
                                            }}>
                                            ₹ {creditTotal}
                                        </td>
                                    </tr>
                                    <tr className="trans-row">
                                        <th>Date</th>
                                        <th>Order ID</th>
                                        <th>Type</th>
                                        <th>Mobile No</th>
                                        <th>Beneficiary A/C Details</th>
                                        <th>Withdrawal</th>
                                        <th>Card Type</th>
                                        <th>Deposits</th>
                                        {/* <th>RazorPay ID</th> */}
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody style={{ borderTopWidth: 0 }}>
                                    {walletTransactionsfilter?.length != 0 &&
                                        walletTransactionsfilter?.map(
                                            (item: any, index: any) => {
                                                return (
                                                    <tr
                                                        onClick={() => {
                                                            setCurrentRow(item);
                                                            getTDetails(
                                                                item.wallet_transactionId
                                                            );
                                                            setShowModal(true);
                                                        }}
                                                        className="trans-row1"
                                                        key={index}>
                                                        <td>
                                                            <Moment
                                                                format="DD MMM YYYY hh:mm A"
                                                                subtract={{
                                                                    hours: 5,
                                                                    minutes: 30,
                                                                }}>
                                                                {
                                                                    item?.transactionDateTime
                                                                }
                                                            </Moment>
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {
                                                                item?.wallet_transactionId
                                                            }{" "}
                                                        </td>
                                                        <td>
                                                            {item?.transactionType ==
                                                                1 && (
                                                                <span>
                                                                    Recharge
                                                                </span>
                                                            )}
                                                            {item?.transactionType ==
                                                                2 && (
                                                                <span>
                                                                    Bill pay
                                                                </span>
                                                            )}
                                                            {item?.transactionType ==
                                                                3 && (
                                                                <span>
                                                                    Wallet Debit
                                                                </span>
                                                            )}
                                                            {item?.transactionType ==
                                                                4 && (
                                                                <span>
                                                                    Wallet
                                                                    Credit
                                                                </span>
                                                            )}
                                                            {item?.transactionType ==
                                                                5 && (
                                                                <span>
                                                                    Reversal
                                                                </span>
                                                            )}
                                                            {item?.transactionType ==
                                                                6 && (
                                                                <span>
                                                                    PayOut Add
                                                                    Charge{" "}
                                                                </span>
                                                            )}
                                                            {item?.transactionType ==
                                                                7 && (
                                                                <span>
                                                                    Referral
                                                                    Commission{" "}
                                                                </span>
                                                            )}
                                                            {item?.transactionType ==
                                                                8 && (
                                                                <span>
                                                                    BBPS
                                                                    Transaction
                                                                </span>
                                                            )}
                                                            {item?.transactionType ==
                                                                9 && (
                                                                <span>
                                                                    Admin Debit
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {item?.mobileNumber}{" "}
                                                        </td>
                                                        <td>
                                                            {
                                                                item?.bankAccountName
                                                            }
                                                            <br />
                                                            {
                                                                item?.bankAccountNumber
                                                            }
                                                        </td>
                                                        <td>
                                                            {(item?.transactionType ==
                                                                2 ||
                                                                item?.transactionType ==
                                                                    3 ||
                                                                item?.transactionType ==
                                                                    6 ||
                                                                item?.transactionType ==
                                                                    9) && (
                                                                <span
                                                                    style={{
                                                                        color: "#F0532D",
                                                                        fontWeight: 500,
                                                                    }}>
                                                                    ₹
                                                                    {
                                                                        item?.amount
                                                                    }
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>{item?.type}</td>
                                                        <td>
                                                            {(item?.transactionType ==
                                                                1 ||
                                                                item?.transactionType ==
                                                                    4 ||
                                                                item?.transactionType ==
                                                                    5) && (
                                                                <span
                                                                    style={{
                                                                        color: "#0DB14B",
                                                                        fontWeight: 500,
                                                                    }}>
                                                                    ₹
                                                                    {
                                                                        item?.amount
                                                                    }
                                                                </span>
                                                            )}
                                                        </td>
                                                        {/* <td> {item?.razorpay_TransactionId} </td> */}
                                                        <td>
                                                            {item?.transactionStatus ==
                                                                2 && (
                                                                <span className="pending-span">
                                                                    {" "}
                                                                    Pending
                                                                </span>
                                                            )}
                                                            {item?.transactionStatus ==
                                                                1 && (
                                                                <span className="completed-span">
                                                                    {" "}
                                                                    Processed
                                                                </span>
                                                            )}
                                                            {item?.transactionStatus ==
                                                                3 && (
                                                                <span className="failed-span">
                                                                    {" "}
                                                                    Failed
                                                                </span>
                                                            )}
                                                            {item?.transactionStatus ==
                                                                4 && (
                                                                <span className="reversed-span">
                                                                    {" "}
                                                                    Reversed
                                                                </span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                </tbody>
                            </table>
                        )}
                        {walletTransactionsfilter?.length == 0 && (
                            <h3>No Records Found</h3>
                        )}
                    </div>

                    <Modal
                        show={showModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        // dialogClassName="c-modal-pay-bills"
                        onHide={() => setShowModal(false)}>
                        {/* <Modal.Header closeButton>
        
      </Modal.Header> */}
                        <Modal.Body>
                            <div className="t-details-div">
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td> Name </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {
                                                    currentRow?.bankAccountName
                                                }{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Mobile Number </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {currentRow?.mobileNumber}{" "}
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td> Bank Account Number </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {
                                                    currentRow?.bankAccountNumber
                                                }{" "}
                                            </td>
                                        </tr>
                                        */}
                                        <tr>
                                            <td> Order ID </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {
                                                    currentRow?.wallet_transactionId
                                                }{" "}
                                            </td>
                                        </tr>
                                        {}
                                        <tr>
                                            <td> Card Type </td>
                                            <td>:</td>
                                            <td> {currentRow?.type} </td>
                                        </tr>
                                        <tr>
                                            <td>Payment Gateway</td>
                                            <td>:</td>
                                            <td>{currentRow?.issuer}</td>
                                        </tr>
                                        <tr>
                                            <td> Sub total </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                ₹{
                                                    currentRow?.subtotalAmount
                                                }{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Charges </td>
                                            <td>:</td>
                                            <td> ₹{currentRow?.charges} </td>
                                        </tr>
                                        <tr>
                                            <td> Interest </td>
                                            <td>:</td>
                                            <td> {currentRow?.percentage}% </td>
                                        </tr>
                                        <tr>
                                            <td> GST Amount </td>
                                            <td>:</td>
                                            <td> ₹{currentRow?.GSTAmount} </td>
                                        </tr>

                                        <tr>
                                            <td> Amount </td>
                                            <td>:</td>
                                            <td> ₹{currentRow?.amount} </td>
                                        </tr>
                                        <tr>
                                            <td> Transaction Type </td>
                                            <td>:</td>
                                            <td>
                                                {currentRow?.transactionType ==
                                                    1 && <span>Recharge</span>}
                                                {currentRow?.transactionType ==
                                                    2 && <span>Bill pay</span>}
                                                {currentRow?.transactionType ==
                                                    3 && (
                                                    <span>Wallet Debit</span>
                                                )}
                                                {currentRow?.transactionType ==
                                                    4 && (
                                                    <span>Wallet Credit</span>
                                                )}
                                                {currentRow?.transactionType ==
                                                    5 && <span>Reversal</span>}
                                                {currentRow?.transactionType ==
                                                    6 && (
                                                    <span>
                                                        PayOut Add Charge{" "}
                                                    </span>
                                                )}
                                                {currentRow?.transactionType ==
                                                    7 && (
                                                    <span>
                                                        Referral Commission{" "}
                                                    </span>
                                                )}
                                                {currentRow?.transactionType ==
                                                    8 && (
                                                    <span>
                                                        BBPS Transaction
                                                    </span>
                                                )}
                                                {currentRow?.transactionType ==
                                                    9 && (
                                                    <span>Admin Debit</span>
                                                )}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td> Date </td>
                                            <td>:</td>
                                            <td>
                                                <Moment format="DD MMM YYYY hh:mm A">
                                                    {currentRow?.createdAt}
                                                </Moment>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td> Transactions Status </td>
                                            <td>:</td>
                                            <td>
                                                {currentRow?.transactionStatus ==
                                                    2 && (
                                                    <span className="pending-span">
                                                        {" "}
                                                        Pending
                                                    </span>
                                                )}
                                                {currentRow?.transactionStatus ==
                                                    1 && (
                                                    <span className="completed-span">
                                                        {" "}
                                                        Processed
                                                    </span>
                                                )}
                                                {currentRow?.transactionStatus ==
                                                    3 && (
                                                    <span className="failed-span">
                                                        {" "}
                                                        Failed
                                                    </span>
                                                )}
                                                {currentRow?.transactionStatus ==
                                                    4 && (
                                                    <span className="reversed-span">
                                                        {" "}
                                                        Reversed
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Description </td>
                                            <td>:</td>
                                            <td> {currentRow?.description} </td>
                                        </tr>
                                        <tr>
                                            <td> Notes </td>
                                            <td>:</td>
                                            <td> {currentRow?.notes} </td>
                                        </tr>
                                        {/* <tr>
                                            <td> Payout ContactId </td>
                                            <td>:</td>
                                            <td> {currentRow?.payout_contactId} </td>
                                        </tr> */}
                                        <tr>
                                            <td> Razorpay TransactionId </td>
                                            <td>:</td>
                                            <td>
                                                {" "}
                                                {
                                                    currentRow?.razorpay_TransactionId
                                                }{" "}
                                            </td>
                                        </tr>

                                        {currentRow?.transactionType === 1 &&
                                            currentRow?.InvoiceFile != "NA" && (
                                                <tr>
                                                    <td>INVOICE</td>
                                                    <td>:</td>
                                                    <td>
                                                        <button
                                                            style={{
                                                                backgroundColor:
                                                                    "green",
                                                                borderRadius:
                                                                    "20px",
                                                            }}>
                                                            <a
                                                                href={`https://d5gafzpzirwrb.cloudfront.net/${currentRow?.InvoiceFile}`}
                                                                target="_blank">
                                                                {
                                                                    currentRow?.InvoiceFile
                                                                }
                                                            </a>
                                                        </button>{" "}
                                                    </td>
                                                </tr>
                                            )}

                                        {currentRow?.transactionType === 2 &&
                                            currentRow?.InvoiceFile != "NA" && (
                                                <tr>
                                                    <td>INVOICE</td>
                                                    <td>:</td>
                                                    <td>
                                                        <button
                                                            style={{
                                                                backgroundColor:
                                                                    "green",
                                                                borderRadius:
                                                                    "20px",
                                                            }}>
                                                            <a
                                                                href={`https://d5gafzpzirwrb.cloudfront.net/${currentRow?.InvoiceFile}`}
                                                                target="_blank">
                                                                {
                                                                    currentRow?.InvoiceFile
                                                                }
                                                            </a>
                                                        </button>{" "}
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </>
    );
}

export default Transactions;
