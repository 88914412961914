import React, { useEffect } from "react";
import "./App.css";
import Navigation from "./website-partials/Navigation";
import Banner from "./website-partials/Banner";
import AboutUs from "./website-partials/AboutUs";
import Values from "./website-partials/Values";
import HowDoesWork from "./website-partials/HowDoesWork";
import Testimonial from "./website-partials/Testimonial";
import Services from "./website-partials/Services";
import Footer from "./website-partials/Footer";
import Contact from "./Contact";

function Home() {
    useEffect(() => {
        var Tawk_API = Tawk_API || {},
            Tawk_LoadStart = new Date();
        (function () {
            var s1 = document.createElement("script"),
                s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = "https://embed.tawk.to/6218b4aba34c2456412825d3/1fso83gji";
            s1.charset = "UTF-8";
            s1.setAttribute("crossorigin", "*");
            s0.parentNode.insertBefore(s1, s0);
        })();
    }, []);
    return (
        <>
            <Navigation />
            <Banner />
            <main id="main">
                {/* <AboutUs /> */}
                <Values />
                {/* <Services/>  */}
                <HowDoesWork />
                <Testimonial />
                <Footer />
                {/* <Features/>
<Services/> 
<Pricing />
<FAQ />
<Portfolio />
<Testimonials />
<Teams />
<Clients /> 
<Footer />*/}
            </main>
        </>
    );
}

export default Home;
