import React, { useState, useEffect, useRef } from "react";
import LoginServices from "../../services/LoginServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Success from "./Success";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { css } from "@emotion/react";

const PayByRazorPay = (props: any) => {
    const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#6865F0";
`;
    const [loading, setLoading] = useState(false);
    const [OrderId, setOrderid] = useState(null);

    const [status, setStatus] = useState("pending");
    const Navigate = useNavigate();
    const componentMounted = useRef(true);
    const [userIpAddress, setUserIpAddress] = useState("");
    const [showSucces, setShowSuccess] = useState<boolean>(false);
    let userdetails: any = localStorage.getItem("UserDetails");
    const isScriptLoaded = useRef(false);
    const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        grant_type: process.env.REACT_APP_GRANT_TYPE,
        mode: "test",
        amount: 0, //  = INR 1
        name: "PayVille",
        description: "Add to wallet",
        order_id: "",
        prefill: {
            contact: "9999999999",
            email: "testing@11.com",
        },
        hidden: {
            contact: true,
            email: true,
        },
        readonly: {
            contact: true,
            email: true,
        },
        // image: 'https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png',
        handler: function (response: any) {
            const payload = {
                receiptId: localStorage.getItem("receiptId"),
                description: props.notes,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                amount: parseFloat(props?.passBlc?.replace(/,/g, "")),
                emailId: props.email,
                mobileNumber: props?.number,
            };
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.createRazorpayPayment(payload, config)
                .then((item: any) => {
                    if (item.data.Status == 1) {
                        // alert(item.data.Message);
                        setShowSuccess(true);
                        Navigate("/");
                    } else {
                        alert(item?.data?.Message);
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
            // alert(response.razorpay_payment_id);
        },

        // prefill: {
        //     name: 'Gaurav',
        //     contact: '9999999999',
        //     email: 'demo@demo.com'
        // },
        notes: {
            address: "some address",
        },

        theme: {
            color: "#6865F0",
            hide_topbar: false,
        },
    };
    useEffect(() => {
        const id = document.getElementById("razorpayscript");
        console.log(id);
        if (!id) {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.async = true;
            script.id = "razorpayscript";
            document.body.appendChild(script);
        }

        fetch("https://api.ipify.org/?format=json")
            .then((Response) => {
                return Response.json();
            })
            .then((response) => {
                if (componentMounted.current) {
                    setUserIpAddress(response.ip);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        return () => {
            // This code runs when component is unmounted
            componentMounted.current = false; // (4) set it to false when we leave the page
        };
    }, []);
    //
    const checkPaymentStatus = async (orderId: any) => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        console.log("sadasdasd");
        LoginServices.OrderIdStatus(orderId, config)
            .then((response: any) => {
                if (response.data.Status == 1) {
                    console.log(response);

                    if (response?.data?.OrderStatus == "SUCCESS") {
                        setShowSuccess(true);

                        setLoading(false);
                        setStatus(response?.data?.OrderStatus);
                    } else {
                        setTimeout(() => {
                            checkPaymentStatus(orderId);
                        }, 5000);
                    }
                } else if (response.data.Status == 0) {
                    setLoading(false);
                    toast.error(`${response.data.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((e: Error) => {
                setLoading(false);
                console.log(e);
            });
    };
    const startPaymentStatusCheck = async (orderId: any) => {
        setLoading(true);
        // while (status == "pending") {
        let data = await checkPaymentStatus(orderId);

        // }
    };
    function isMultipleOf1000(number: any) {
        return number % 1000 === 0;
    }
    const openPayModal = () => {
        if (props?.passBlc?.length == 0) {
            alert("Amount is required field");
            return;
        } else if (props?.notes?.length == 0) {
            /* else if (props?.number?.length == 0) {
            alert("number is required field");
            return;
        }
         else if (props?.email?.length == 0) {
            alert("email is required field");
            return;
        } */
            alert("notes is required field");
            return;
        }
        // if(addBlc)
        console.log(
            isMultipleOf1000(parseFloat(props.passBlc.replace(/,/g, "")))
        );
        if (isMultipleOf1000(parseFloat(props.passBlc.replace(/,/g, "")))) {
            alert("Deposits Amount Cannot be mutliples of 1000");
            props.setAddBlc("");

            return;
        }
        if (parseFloat(props.passBlc.replace(/,/g, "")) > 500000) {
            alert("Deposits more than 5,00,000 not allowed");
            return;
        }

        const payload = {
            amount: parseFloat(props?.passBlc?.replace(/,/g, "")),
            mobileNumber: props.number,
            emailId: props.email,
            notes: props.notes,
            ipAddress: userIpAddress,
            paymentgateway: props.Gateway,
        };
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };

        LoginServices.createRazorpayOrder(payload, config)
            .then((response: any) => {
                if (response.data.Status == 1) {
                    props?.setEmail("");
                    props?.setNumber("");
                    props?.setNotes("");
                    props?.setAddBlc("");
                    setLoading(true);
                    startPaymentStatusCheck(response?.data?.orderId);
                    window.open(response?.data?.PaymentLink, "_blank");
                    // startPaymentStatusCheck('');
                    // localStorage.setItem("receiptId", response.data.receiptId);
                    //orderId
                    // setOrderId1(response.data.orderId);
                    /*    if (props.Gateway == "pg1") {
                        options.key = process.env.REACT_APP_RAZORPAY_KEY;
                    } else if (props.Gateway == "pg2") {
                        options.key = process.env.REACT_APP_RAZORPAY_KEY_TWO;
                    }
                    options.amount =
                        parseFloat(props?.passBlc?.replace(/,/g, "")) * 100;
                    options.order_id = response.data.orderId;

                    if (userdetails) {
                        userdetails = JSON.parse(userdetails);
                        options.prefill = {
                            contact:
                                "+91" + parseInt(userdetails?.mobileNumber),
                            email: `${userdetails?.emailId}`,
                        };
                    }

                    var rzp1 = new (window as any).Razorpay(options);
                    rzp1.open(); */
                } else if (response.data.Status == 0) {
                    toast.error(`${response.data.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    return (
        <>
            {showSucces && (
                <Success
                    showSucces={showSucces}
                    setShowSuccess={setShowSuccess}
                />
            )}
            <div style={{ margin: "auto" }}>
                {/* {isNaN(parseFloat(props.passBlc.replace(/,/g, ""))) &&
                    props?.passBlc?.length && (
                        <p className="error-text">Only Numbers Allowed</p>
                    )} */}
                {loading ? (
                    <BounceLoader
                        color={"#6865F0"}
                        loading={loading}
                        css={override}
                        size={150}
                    />
                ) : (
                    <button
                        disabled={isNaN(
                            parseFloat(props.passBlc?.replace(/,/g, ""))
                        )}
                        onClick={openPayModal}
                        className="add-btn">
                        Add
                    </button>
                )}
                <ToastContainer />
            </div>
        </>
    );
};

export default PayByRazorPay;
