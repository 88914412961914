import "./Bbps.css";
import { useForm } from "react-hook-form";
import Navbar from "./Bbpsnav";
import { useNavigate, useParams } from "react-router-dom";
import header_Logo from "../../assets/bbps/bharat_pay.png";
import React, { useState } from "react";
import Submenu from "./Bbpssubmenu";
import Spinner from "../spinner";

import LoginServices from "../../services/LoginServices";
export default function Bbps() {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate();
    const [spinner, setspinner] = useState<any>(false);
    const userId = localStorage.getItem("userid");

    const onSubmit = (data: any) => {
        setspinner(true);
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        let body = {
            ...data,
            complaintType: "Transaction",
            mobileNumber: localStorage.getItem("mobilenumber"),
        };
        // console.log(data);

        LoginServices.ComplaintRegister(body, config)
            .then((res) => {
                setspinner(false);
                if (res?.data?.Status == 1) {
                    sessionStorage.setItem(
                        "registersuccess",
                        JSON.stringify(res?.data?.RegisterComplaintResponse)
                    );
                    navigate("/dashboard/bbps/complaints/registersuccess");
                } else if (res?.data?.Status == 0) {
                    alert(res?.data?.Message);
                }
                // console.log(res);
            })
            .catch((err) => {
                alert("something went wrong try later");
                setspinner(false);
            });
    };
    return (
        <>
            {spinner && <Spinner />}
            <div className="row main-row">
                <div className="col-10 dashboard-right-back">
                    <div className="header-bbps">
                        <div className="BbpsWrapper">
                            <div className="Bbps-heading">
                                <p>Complaints</p>
                                <img src={header_Logo} alt="" />
                            </div>
                            <div className="Bbps-Navbar">
                                <Navbar active="complaints" />
                            </div>
                            <div className="Complaint-formwrapper">
                                <Submenu active="Raise Complaint" />
                                <h1>BBPS Raise Complaint</h1>

                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="TypeOfComplaint-wrapper">
                                        <label htmlFor="TypeOfComplaint">
                                            Type of Transaction
                                        </label>
                                        <div className="complaint-type">
                                            <input
                                                type="radio"
                                                defaultChecked
                                            />
                                            <p>Transaction</p>
                                        </div>
                                    </div>
                                    <div className="Complaint-form-control">
                                        <label htmlFor="Transaction Ref ID">
                                            Transaction Ref ID
                                        </label>
                                        <input
                                            className={`input ${
                                                errors.transactionReferenceId &&
                                                "inputerror"
                                            }`}
                                            type="text"
                                            {...register(
                                                "transactionReferenceId",
                                                {
                                                    required:
                                                        "transactionReferenceId is required",
                                                }
                                            )}
                                        />
                                        {errors?.transactionReferenceId && (
                                            <p className="error">
                                                {
                                                    errors
                                                        ?.transactionReferenceId
                                                        .message
                                                }
                                            </p>
                                        )}
                                    </div>
                                    <div className="Complaint-form-control">
                                        <label htmlFor="Complaint Disposition ">
                                            Complaint Disposition
                                        </label>
                                        <select
                                            className={`select ${
                                                errors?.complaintDisposition &&
                                                "inputerror"
                                            }`}
                                            {...register(
                                                "complaintDisposition",
                                                {
                                                    required:
                                                        "complaintDisposition is required",
                                                }
                                            )}>
                                            <option
                                                value="Transaction Successful, account not updated
">
                                                Transaction Successful, account
                                                not updated
                                            </option>
                                            <option value="Amount deducted, biller account credited but transaction ID not received">
                                                Amount deducted, biller account
                                                credited but transaction ID not
                                                received
                                            </option>
                                            <option
                                                value="Amount deducted, biller account not credited & transaction ID not receivedAmount deducted multiple times
">
                                                Amount deducted, biller account
                                                not credited & transaction ID
                                                not received
                                            </option>
                                            <option
                                                value="Amount deducted multiple times
">
                                                Amount deducted multiple times
                                            </option>
                                            <option value="Double payment updated">
                                                Double payment updated
                                            </option>
                                            <option
                                                value="Erroneously paid in wrong account
">
                                                Erroneously paid in wrong
                                                account
                                            </option>
                                            <option value="Others, provide details in description">
                                                Others, provide details in
                                                description
                                            </option>
                                        </select>
                                        {errors?.complaintDisposition && (
                                            <p className="error">
                                                {
                                                    errors?.complaintDisposition
                                                        .message
                                                }
                                            </p>
                                        )}
                                    </div>

                                    <div className="Complaint-form-control">
                                        <label htmlFor="Description">
                                            Description
                                        </label>
                                        <textarea
                                            className={`textarea ${
                                                errors?.complaintDescription &&
                                                "inputerror"
                                            }`}
                                            {...register(
                                                "complaintDescription",
                                                {
                                                    required:
                                                        "complaintDescription is required",
                                                }
                                            )}></textarea>
                                        {errors?.complaintDescription && (
                                            <p className="error">
                                                {
                                                    errors?.complaintDescription
                                                        .message
                                                }
                                            </p>
                                        )}
                                    </div>
                                    <div className="button">
                                        <button>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
