import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import "./payWithoutSignin.css";
import { Modal, Row, Col, Container } from "react-bootstrap";
import { css } from "@emotion/react";

import { PulseLoader } from "react-spinners";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginServices from "./services/LoginServices";
import backgroundImg from "./light_desktop.svg";
import paymentMethodImg from "./pay_methods_branding.png";
import PinelabswithoutSignin from "./components/dashboard/PinelabsWithoutSignup";
import swal from "sweetalert";
const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#010080";
`;

function PayWithoutSignin() {
    let { recId } = useParams();
    let [loading, setLoading] = useState(false);
    const userId = localStorage.getItem("userid");
    const [showIframe, setShowIframe] = useState(false);
    const [url, setUrl] = useState();
    const navigate = useNavigate();
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm();
    const options: any = {
        key: "",
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        // key: 'rzp_live_l9KjQnd3vcvYrK',
        // client_id: 'rzp_live_l9KjQnd3vcvYrK',
        // client_secret: '123456XXXXXXX',
        grant_type: process.env.REACT_APP_GRANT_TYPE,
        mode: "test",
        amount: 0, //  = INR 1
        name: "PayVille",
        description: "Pay",
        order_id: "",
        handler: function (response: any) {
            // console.log(response);
            const payload = {
                receiptId: localStorage.getItem("receiptId"),
                description: "",
                paymentPageId: recId,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                paymentgateway: gatewayType,
                // amount: 500
            };
            setLoading(true);

            console.log(payload);
            LoginServices.createRazorpayPaymentGlobal(payload)
                .then((item: any) => {
                    console.log(item);
                    if (item.data.Status == 1) {
                        setLoading(false);
                        reset();
                        console.log(item.data.Message);
                        // alert(item.data.Message);
                        // toast.success(`${item.data.Message}`, {
                        //     position: "top-center",
                        //     autoClose: 5000,
                        //     hideProgressBar: true,
                        //     closeOnClick: true,
                        //     pauseOnHover: true,
                        //     draggable: false,
                        //     progress: undefined,
                        // });
                        // window.location.reload();
                        swal({
                            title: "Payment",
                            text: `${item.data.Message}`,
                            icon: "success",
                        });
                    } else {
                        setLoading(false);
                        alert(item?.data?.Message);
                    }
                })
                .catch((e: Error) => {
                    setLoading(false);
                    console.log(e);
                });
            // alert(response.razorpay_payment_id);
        },
        hidden: {
            contact: true,
            email: true,
        },
        readonly: {
            contact: true,
            email: true,
        },
        // prefill: {
        //     name: 'Gaurav',
        //     contact: '9999999999',
        //     email: 'demo@demo.com'
        // },
        notes: {
            address: "some address",
        },
        theme: {
            color: "#6865F0",
            hide_topbar: false,
        },
    };
    const [bDetails, setBDetails] = useState<any>({});
    const [commission, setCommission] = useState(0);
    const [pg2commission, setpg2commission] = useState<any>(0);
    const [gatewaytwoStatus, setgatewaytwoStatus] = useState<any>(1);
    const [gatewayoneStatus, setgatewayoneStatus] = useState<any>(1);
    const [rEmail, setREmail] = useState<any>("");
    const [rMobile, setRMobile] = useState<any>("");
    const [tempDetails, setTempDetails] = useState<any>({});
    const [gatewayType, setgateWayType] = useState<any>("pg1");
    const gatewaychange = (e: any) => {
        let gateway = e.currentTarget.getAttribute("id");
        setgateWayType(gateway);
    };
    useEffect(() => {
        if (recId?.length == 0) {
            alert("PaymentPageId is not available");
            navigate("/");
            return;
        }

        // LoginServices.walletCommissionwithpageId(recId)
        //     .then((Response: any) => {
        //         console.log(Response, "221");
        //         if (Response?.data?.Status == 1) {
        //             setCommission(Response?.data?.WalletCommissionPercentage);
        //         } else {
        //         }
        //     })
        //     .catch((e: Error) => {
        //         console.log(e);
        //     });
        if (recId) {
            localStorage.setItem("recId", recId);
        }
        LoginServices.paymentstatuswithoutlogin(recId)
            .then((Response: any) => {
                console.log(Response, "2121");
                if (Response?.data?.Status == 1) {
                    setgatewaytwoStatus(Response?.data.isPG2enabled);
                    setgatewayoneStatus(Response?.data.isPG1enabled);
                    if (Response?.data.isPG1enabled === 1) {
                        setgateWayType("pg1");
                    } else {
                        setgateWayType("pg2");
                    }
                    if (Response?.data?.userType == "USER") {
                        setpg2commission(
                            Response?.data.pg2walletCommissionUserPercentage
                        );
                    } else if (Response?.data?.userType == "BUSINESS") {
                        setpg2commission(
                            Response?.data.pg2walletCommissionBusinessPercentage
                        );
                    }
                } else {
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
        LoginServices.getBusinessDetailsByPageId(recId)
            .then((response: any) => {
                // console.log(response);
                if (response.data.Status == 1) {
                    setBDetails(response?.data?.UserDetails);
                } else {
                    alert(response?.Message);
                }

                // console.log(response.data);
            })
            .catch((e: Error) => {
                console.log(e);
            });

        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
    }, [""]);

    const onSubmit = (data: any) => {
        // console.log(data);
        // console.log("data");
        setREmail(data.emailId);
        setRMobile(data.mobileNumber);

        const payload = {
            amount: data.amount,
            paymentPageId: recId,
            name: data.name,
            mobileNumber: data.mobileNumber,
            emailId: data.emailId,
            notes: data.notes,
            paymentgateway: gatewayType,
        };
        console.log(payload);
        setLoading(true);
        LoginServices.sendPaymentPageID(payload)
            .then((response: any) => {
                console.log(response, "sendpaymnet");
                if (response.data.Status == 1) {
                    setLoading(false);
                    reset();
                    if (gatewayType == "pg1") {
                        localStorage.setItem(
                            "receiptId",
                            response.data.receiptId
                        );
                        setLoading(false);
                        // options.amount = 500 * 100;
                        options.order_id = response.data.orderId;
                        options.prefill = {
                            contact: "+91" + parseInt(payload.mobileNumber),
                            email: payload.emailId,
                        };
                        var rzp1 = new (window as any).Razorpay(options);
                        rzp1.open();
                    } else {
                        setUrl(response.data.RedirectURL);
                        setShowIframe(true);
                    }
                } else {
                    setLoading(false);
                    alert(response.data.Message);
                    console.log(response.data.Message);
                }
                console.log(response.data);
            })
            .catch((e: Error) => {
                setLoading(false);
                console.log(e);
            });
    };
    // console.log(tempDetails);

    return (
        <div className="payment-section">
            {showIframe && (
                <PinelabswithoutSignin
                    url={url}
                    setUrl={setUrl}
                    setShowIframe={setShowIframe}
                    showIframe={showIframe}
                />
            )}
            <div className="section1-pay">
                <Container>
                    <Row className="justify-content-md-center">
                        <Col xs lg="6">
                            <div>
                                <img
                                    className="pay-mark-logo"
                                    src="assets/img/logo.png"
                                    alt=""
                                />
                            </div>

                            <div>
                                <h4 className="kyc-head">
                                    {bDetails?.firstName}{" "}
                                </h4>
                            </div>

                            <div className="bottom-section">
                                <div className="contact-info">
                                    <h6>Contact Us</h6>

                                    <p>
                                        {/* slncmeeseva@gmail.com */}
                                        {bDetails?.emailId}
                                    </p>
                                    <p>
                                        {/* 8074149158 */}
                                        {bDetails?.mobileNumber}
                                    </p>
                                </div>
                                <div className="termsandconditons">
                                    <h6>Terms and conditions:</h6>
                                    <p>
                                        You agree to share information entered
                                        on this page with Payville (owner of
                                        this page) and Razorpay. adhering to
                                        applicable laws.
                                    </p>
                                </div>
                            </div>

                            <div className="card-new">
                                <h6 className="card-new-heading">
                                    Payment Details
                                </h6>

                                <form
                                    className="pre-form"
                                    onSubmit={handleSubmit(onSubmit)}>
                                    <Container>
                                        <div>
                                            {/* <p className="titel"></p> */}
                                            <div className="pre-form-section">
                                                <label>Name</label>
                                                <input
                                                    {...register("name", {
                                                        required: true,
                                                    })}
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    autoComplete="off"
                                                    placeholder="Enter Name"
                                                />
                                            </div>
                                            <label className="pre-form-error-text">
                                                {errors?.name?.type ==
                                                    "required" &&
                                                    "Name required "}{" "}
                                            </label>

                                            <div className="pre-form-section">
                                                <label>Amount</label>
                                                <input
                                                    {...register("amount", {
                                                        required: true,
                                                        pattern: /^([0-9])+$/i,
                                                    })}
                                                    type="text"
                                                    name="amount"
                                                    id="amount"
                                                    autoComplete="off"
                                                    placeholder="Enter Amount"
                                                />
                                            </div>
                                            <label className="pre-form-error-text">
                                                {errors?.amount?.type ==
                                                    "required" &&
                                                    "Amount is required "}{" "}
                                                {errors?.amount?.type ==
                                                    "pattern" &&
                                                    "Amount Invalid"}{" "}
                                            </label>
                                            <div className="pre-form-section">
                                                <label>Email</label>
                                                <input
                                                    {...register("emailId", {
                                                        required: true,
                                                        pattern: /\S+@\S+\.\S+/,
                                                    })}
                                                    type="email"
                                                    name="emailId"
                                                    id="emailId"
                                                    autoComplete="off"
                                                    placeholder="Enter EmailID"
                                                />
                                            </div>
                                            <label className="pre-form-error-text">
                                                {errors?.emailId?.type ==
                                                    "required" &&
                                                    "Email required "}{" "}
                                                {errors?.emailId?.type ==
                                                    "pattern" &&
                                                    "Email Invalid"}{" "}
                                            </label>
                                            <div className="pre-form-section">
                                                <label>Mobile Number</label>
                                                <input
                                                    maxLength={10}
                                                    {...register(
                                                        "mobileNumber",
                                                        {
                                                            required: true,
                                                            pattern:
                                                                /^([0-9]{10})+$/i,
                                                        }
                                                    )}
                                                    type="text"
                                                    name="mobileNumber"
                                                    id="mobileNumber"
                                                    autoComplete="off"
                                                    placeholder="Enter Contact Number"
                                                />
                                            </div>
                                            <label className="pre-form-error-text">
                                                {errors?.mobileNumber?.type ==
                                                    "required" &&
                                                    "Contact number is required "}{" "}
                                                {errors?.mobileNumber?.type ==
                                                    "pattern" &&
                                                    "Mobile Number Invalid"}{" "}
                                            </label>
                                            <div className="pre-form-section">
                                                <label>Deposit for</label>
                                                <input
                                                    {...register("notes")}
                                                    type="text"
                                                    name="notes"
                                                    id="notes"
                                                    autoComplete="off"
                                                    placeholder="Deposit for"
                                                />
                                            </div>
                                            <div className="payment-btns">
                                                {gatewayoneStatus == 1 && (
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            id="pg1"
                                                            name="Payment"
                                                            value={"one"}
                                                            checked={
                                                                gatewayType ==
                                                                "pg1"
                                                            }
                                                            onChange={
                                                                gatewaychange
                                                            }
                                                        />
                                                        <label htmlFor="pg1">
                                                            {" "}
                                                            PG-1
                                                            {/* ({commission}%) */}
                                                            <br />
                                                        </label>
                                                    </div>
                                                )}
                                                {gatewaytwoStatus == 1 && (
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            id="pg2"
                                                            checked={
                                                                gatewayType ==
                                                                "pg2"
                                                            }
                                                            name="Payment"
                                                            value={"two"}
                                                            onChange={
                                                                gatewaychange
                                                            }
                                                        />
                                                        <label htmlFor="pg2">
                                                            {" "}
                                                            PG-2
                                                            {/* ({pg2commission}
                                                            %) */}
                                                            <br />
                                                        </label>
                                                    </div>
                                                )}
                                            </div>
                                            {/* <label className="error-text1">{errors?.notes?.type == 'required' && "Notes required "}  </label> */}

                                            <PulseLoader
                                                color={"#010080"}
                                                loading={loading}
                                                css={override}
                                                size={20}
                                            />
                                        </div>
                                    </Container>

                                    <div className="pay-button-section">
                                        <Row>
                                            <Col sm={7}>
                                                <div className="pay-button-section1">
                                                    <img
                                                        src={paymentMethodImg}
                                                        className="section2-img2"
                                                        alt=""
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm={5}>
                                                <button
                                                    type="submit"
                                                    className="pay-button"
                                                    style={{
                                                        margin: "1px auto",
                                                        color: "white",
                                                    }}>
                                                    PAY
                                                </button>
                                                <ToastContainer />
                                            </Col>
                                        </Row>
                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="section2">
                <img src={backgroundImg} className="section2-img" alt="" />
            </div>

            {/* <Container style={{width:'80vw',height:'100vh', border:"1px solid black"}}>
           
        

            </Container> */}
        </div>
    );
}

export default PayWithoutSignin;
