import React, { useState, useEffect, useRef } from "react";
import LoginServices from "../../services/LoginServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Success from "./Success";
import Modal from "react-modal";
const PayByRazorPay = (props: any) => {
    const componentMounted = useRef(true);
    const [userIpAddress, setUserIpAddress] = useState("");
    const [showSucces, setShowSuccess] = useState<boolean>(false);

    const onClose = () => {
        props.setShowIframe(false);
    };
    return (
        <>
            {showSucces && (
                <Success
                    showSucces={showSucces}
                    setShowSuccess={setShowSuccess}
                />
            )}
            {props.showIframe && (
                <div>
                    <div className="modal-backdrop" onClick={onClose}>
                        <div
                            className="modal-content-iframe"
                            onClick={(e) => e.stopPropagation()}>
                            <span
                                className="close-btn-iframe"
                                onClick={onClose}>
                                &times;
                            </span>
                            <iframe
                                title="Dynamic Iframe"
                                height={"100%"}
                                width={"100%"}
                                src={props.url}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PayByRazorPay;
