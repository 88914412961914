import React, { useState, ChangeEvent, useEffect } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import "./Signup.css";
import { isCompositeComponent } from "react-dom/test-utils";
import SignupServices from "../services/SignupServices";
import {
    SignupTypes,
    SignupResponse,
    SignupVerifyOTPRequest,
    SignupUser,
} from "../types/Signuptypes";
import { useNavigate } from "react-router-dom";

const Signup: React.FC = (props: any) => {
    const initialSignupState = {
        mobileNumber: "",
        userType: "",
    };

    const initialSignupverifyOTPRequest = {
        mobileNumber: "",
        OTP: 0,
        userType: "USER",
    };

    const initialSignupUser = {
        mobileNumber: "",
        userType: "USER",
        emailId: "",
        password: "",
        firstName: "",
        companyName: "",
    };

    const [signupData, setSignup] = useState<SignupTypes>(initialSignupState);
    const [signupVerifyOTP, setVerifyOTP] = useState<SignupVerifyOTPRequest>(
        initialSignupverifyOTPRequest
    );
    const [signupUser, setSignupUser] = useState<SignupUser>(initialSignupUser);
    const [errorMsg, setErrorMsg] = useState<String>("");
    const [successMsg, setSuccessMsg] = useState<String>("");
    const [signupStatus, setSignupStatus] = useState<any>(1);
    const [refCode, setRefCode] = useState<any>("");

    const [token, setToken] = useState<any>("");
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setSignup({ ...signupData, [name]: value });
    };

    const handleOTPChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setVerifyOTP({ ...signupVerifyOTP, [name]: value });
    };

    const handleSignUpFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        console.log(name, value);

        setSignupUser({ ...signupUser, [name]: value });
    };
    const [userActive, setUserActive] = useState(true);
    const [haveReferralCode, sethaveReferralCode] = useState(false);
    const [getOTP, setgetOTP] = useState(false);
    const [verifiedOTP, setverifiedOTP] = useState(false);
    const [otp, setOTP] = useState(0);

    const navigate = useNavigate();
    useEffect(() => {
        SignupServices.signupStatus().then((response: any) => {
            console.log(response);
            setSignupStatus(response.data.isUserSignupEnabled);
            if (response.data.isUserSignupEnabled == 0) {
                setUserActive(false);
            }
        });
    }, []);
    const doSignup = () => {
        console.log(signupUser);
        const paLo: any = signupUser;

        if (localStorage.getItem("rCode")?.length) {
            paLo.referredByCode = refCode;
        }

        SignupServices.RegisterUser(paLo)
            .then((response: any) => {
                if (response.data.Status == 1) {
                    // setToken(response.data.Token);
                    // localStorage.setItem(
                    //     "userid",
                    //     response?.data?.userDetails?.userId
                    // );
                    // localStorage.setItem("token", response?.data?.Token);
                    // localStorage.setItem(
                    //     "firstName",
                    //     response?.data?.userDetails?.firstName
                    // );
                    // localStorage.setItem(
                    //     "city",
                    //     response?.data?.userDetails?.city
                    // );

                    // navigate("/dashboard");
                    //alert(response.data.Message);
                    props.onLoginRedirect();
                    setSuccessMsg(response.data.Message);
                    setErrorMsg("");
                } else {
                    setErrorMsg(
                        "Something went wrong. Please try again." +
                            response.data.Message
                    );
                    setSuccessMsg("");

                    //alert("Something went wrong. Please try again.");
                    //alert(response.data.Message);
                }

                console.log(response.data);
            })
            .catch((e: any) => {
                console.log(e);
                //alert("Something went wrong. Please try again.");
                setErrorMsg(e?.response?.data?.Message);
                setSuccessMsg("");
            });
    };

    const verifyOTP = () => {
        console.log(signupUser);

        SignupServices.verifyOTP(signupVerifyOTP)
            .then((response: any) => {
                if (response.data.Status == 1) {
                    //setOTP(response.data.OTP);
                    setverifiedOTP(true);
                    //alert(response.data.Message);
                    setSuccessMsg(response.data.Message);
                    setErrorMsg("");
                    setSignupUser({
                        ...signupUser,
                        mobileNumber: signupVerifyOTP.mobileNumber,
                        userType: signupVerifyOTP.userType,
                    });
                } else {
                    setverifiedOTP(false);
                    //alert("Something went wrong. Please try again.");
                    //alert(response.data.Message);
                    setErrorMsg(
                        "Something went wrong. Please try again." +
                            response.data.Message
                    );
                    setSuccessMsg("");
                }

                console.log(response.data);
            })
            .catch((error: any) => {
                console.log(error);
                //alert("Something went wrong. Please try again.");
                setErrorMsg(error?.response?.Message);
                setSuccessMsg("");
                setverifiedOTP(false);
            });
    };
    const resendOTP = () => {
        // alert('j')
        createOTP();
    };

    //on submit signup
    const createOTP = () => {
        //console.log(userActive);
        //var userType1=userActive?'USER':'BUSINESS';
        //console.log(userType1);

        //console.log(signupData);

        //setSignup({ ...signupData, userType:userActive?'USER':'BUSINESS'  });

        /* setTimeout(()=>{
      console.log("after 2secs")
      console.log(signupData);
    },2000) */

        //setSignup({ ...signupData, userType:  });
        /*  setSignup(prevState => {
      return {
        ...prevState,
        'userType': userActive?'USER':'BUSINESS'
      };
    }); */

        //console.log(signupData);

        var data = {
            mobileNumber: signupData.mobileNumber,
            userType: userActive ? "USER" : "BUSINESS",
        };
        const paL: any = data;
        paL.companyName = signupUser.companyName;
        if (paL.userType == "BUSINESS") {
            if (!paL?.companyName?.length) {
                setErrorMsg("Company name required ");
                return;
            }
        }

        if (refCode.length) {
            paL.referredByCode = refCode;
            localStorage.setItem("rCode", refCode);
        } else {
            localStorage.removeItem("rCode");
        }

        console.log(paL);

        SignupServices.create(paL)
            .then((response: any) => {
                if (response.data.Status == 1) {
                    setOTP(response.data.OTP);
                    setgetOTP(true);
                    setErrorMsg("");
                    setVerifyOTP({
                        ...signupVerifyOTP,
                        mobileNumber: data.mobileNumber,
                        userType: data.userType,
                        OTP: response.data.OTP,
                    });
                } else {
                    setgetOTP(false);
                    //alert("Something went wrong. Please try again.");
                    setErrorMsg(
                        "Something went wrong. Please try again." +
                            response.data.Message
                    );
                    setSuccessMsg("");
                }

                console.log(response.data);
            })
            .catch((error: any) => {
                console.log(error.response);
                // alert("Something went wrong. Please try again.");
                setErrorMsg(error?.response?.data?.Message);
                setSuccessMsg("");
            });
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="login-modal1"
            centered
            backdrop={false}>
            {/* <Modal.Header closeButton>
        
      </Modal.Header> */}
            <Modal.Body>
                <div className="closeBtnDiv">
                    <button
                        onClick={() => {
                            console.log("r");

                            props.onHide();
                            window.location.reload();
                        }}
                        className="btn btn-danger">
                        Close
                    </button>
                </div>
                <Row className="signup_container">
                    <Col xs={12} md={5} className="signup_left">
                        <h1 className="signup_title">Login Now</h1>
                        <p>
                            If you have already an account, please login here
                            with registered mobile number
                        </p>
                        <button
                            onClick={props.onLoginRedirect}
                            className="loginButton">
                            Log in
                        </button>
                    </Col>
                    <Col xs={12} md={7} className="signup_right">
                        <span className="text-danger">{errorMsg}</span>
                        <span className="text-success">{successMsg}</span>
                        <h1>Create Account</h1>
                        {verifiedOTP == false && (
                            <>
                                {getOTP == false && (
                                    <div className="wrapper_buttons">
                                        {signupStatus == 1 && (
                                            <button
                                                className={
                                                    userActive == true
                                                        ? "active_button"
                                                        : ""
                                                }
                                                onClick={() => {
                                                    setUserActive(true);
                                                }}>
                                                User
                                            </button>
                                        )}

                                        <button
                                            className={
                                                userActive == false
                                                    ? "active_button"
                                                    : ""
                                            }
                                            onClick={() => {
                                                setUserActive(false);
                                            }}>
                                            Business
                                        </button>
                                    </div>
                                )}

                                <div className="inputWrapper">
                                    <img
                                        src="assets/img/signup/mobile.png"
                                        alt="mobile"
                                    />
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="mobileNumber"
                                        placeholder="Enter mobile number"
                                        value={signupData.mobileNumber}
                                        onChange={handleInputChange}
                                    />{" "}
                                    {
                                        <input
                                            className="form-control"
                                            name="userType"
                                            placeholder="USER TYPE"
                                            type="hidden"
                                            value={
                                                userActive ? "USER" : "BUSINESS"
                                            }
                                        />
                                    }
                                </div>

                                {userActive == false && getOTP == false && (
                                    <>
                                        <div className="inputWrapper">
                                            <img
                                                src="assets/img/signup/company.png"
                                                alt="company"
                                            />
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Enter Company Name"
                                                name="companyName"
                                                onChange={
                                                    handleSignUpFieldChange
                                                }
                                            />
                                        </div>
                                    </>
                                )}

                                {haveReferralCode == true &&
                                    getOTP == false && (
                                        <>
                                            <div className="inputWrapper">
                                                <img
                                                    src="assets/img/signup/promo.png"
                                                    alt="referral"
                                                />
                                                <input
                                                    onChange={(e) =>
                                                        setRefCode(
                                                            e.target.value
                                                        )
                                                    }
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Enter Referral Code"
                                                    name="referral"
                                                />
                                            </div>
                                        </>
                                    )}

                                {getOTP == true && (
                                    <div className="inputWrapper">
                                        <img
                                            src="assets/img/signup/otp.png"
                                            alt="OTP"
                                        />
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Enter OTP"
                                            name="OTP"
                                            onChange={handleOTPChange}
                                        />
                                    </div>
                                )}

                                {getOTP == true && (
                                    <div>
                                        <button
                                            className="getOTP"
                                            onClick={verifyOTP}>
                                            Verify
                                        </button>
                                        <button
                                            className="getOTP"
                                            onClick={resendOTP}>
                                            Resend Otp{" "}
                                        </button>
                                    </div>
                                )}

                                {getOTP == false && (
                                    <>
                                        <div className="referral">
                                            <span
                                                onClick={() =>
                                                    sethaveReferralCode(
                                                        !haveReferralCode
                                                    )
                                                }
                                                style={{ cursor: "pointer" }}>
                                                Have a Referral code?
                                            </span>
                                        </div>
                                        <button
                                            className="getOTP"
                                            onClick={createOTP}>
                                            Get OTP
                                        </button>
                                        {/* <button className="getOTP" onClick={() => setgetOTP(!getOTP)}>Get OTP</button> */}
                                    </>
                                )}
                            </>
                        )}

                        {verifiedOTP == true && (
                            <>
                                <div className="inputWrapper">
                                    <img
                                        src="assets/img/signup/profile.png"
                                        alt="profile"
                                    />
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter your Name"
                                        name="firstName"
                                        onChange={handleSignUpFieldChange}
                                    />
                                </div>

                                <div className="inputWrapper">
                                    <img
                                        src="assets/img/signup/message.png"
                                        alt="email"
                                    />
                                    <input
                                        className="form-control"
                                        type="email"
                                        placeholder="Enter your Email ID"
                                        name="emailId"
                                        onChange={handleSignUpFieldChange}
                                    />
                                </div>

                                <div className="inputWrapper">
                                    <img
                                        src="assets/img/signup/lock.png"
                                        alt="password"
                                    />
                                    <input
                                        className="form-control"
                                        type="password"
                                        placeholder="Enter Your Password"
                                        name="password"
                                        onChange={handleSignUpFieldChange}
                                    />
                                </div>

                                <div className="inputWrapper">
                                    <img
                                        src="assets/img/signup/lock.png"
                                        alt="password"
                                    />
                                    <input
                                        className="form-control"
                                        type="password"
                                        placeholder="Confirm Password"
                                    />
                                </div>

                                <div className="terms">
                                    <span>
                                        <input
                                            type="checkbox"
                                            style={{
                                                border: "1px solid #6865F0",
                                            }}
                                        />
                                    </span>
                                    &nbsp;&nbsp;
                                    <span>
                                        I Agree to PayVille Terms & Conditions
                                    </span>
                                </div>

                                <button className="getOTP" onClick={doSignup}>
                                    Sign Up
                                </button>
                            </>
                        )}
                    </Col>
                </Row>
            </Modal.Body>
            {/*  <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
        </Modal>
    );
};

export default Signup;
